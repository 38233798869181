
import { API_HOST, ALL_LIST_SKILLS, API_SAVE_SKILLS } from "../../Api";
import axiosServices from "../../services/Axios/axiosServices";
import { GET_ALL_SKILLS, SAVE_SKILLS, DUMP_SKILLS } from '../constants/Cosmonaute';
import { SHOW_TOAST } from '../constants/Toast';
/* Get list skills by type */
export const getListSkills = (dispatch) => () => {
    // URL get list Missions for Admin
    const URL = API_HOST + ALL_LIST_SKILLS;
    // Call API list Missions for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: GET_ALL_SKILLS, payload: res.data, toastContent: null });
        },
       
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération liste des compétences" }
            });
        }
    );
}
/* Save skills with Ratings */
export const saveSkillsCandidte = (dispatch) => (data,id) => {
    // URL save Skills
    const URL = API_HOST + API_SAVE_SKILLS + id;
    // Call API save Skills
    axiosServices.post(URL, data).then(
        (res) => {
            dispatch({
                type: SAVE_SKILLS,
                payload: res.data,
                toastContent: { status: 'success', body: "Succès d'enregistrement des compétences" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'enregistrement des compétences" }
            });
        }
    );
}
/* dump skills */
export const dumpSkillsCandidte = (dispatch) => () => {
    dispatch({ type: DUMP_SKILLS, payload: null, toastContent: null });
}