import AxiosService from "../../services/Axios/axiosServices";
import {
  RETRIEVE_COSMONAUTE,
  UPDATE_COSMONAUTE,
  UPDATE_COSMONAUTE_DESCRIPTION,
  COSMONAUTE_RESUME,
  GET_RESUME,
  POURCENTAGE_CONDIDAT,
} from "../constants/Cosmonaute";
import { SHOW_TOAST } from "../constants/Toast";
import {
  API_HOST,
  API_PROFIL_DETAILS,
  API_UPDATE_PROFILHEADER,
  API_UPDATE_PROFIL,
  API_UPLOAD_RESUME,
  API_GET_RESUME,
  API_POURCENTAGE_CONDIDAT,
  API_PRINT_CV
} from "../../Api";
import { getUserId } from "../../services/Controller/CurrentUserServices";

/* get detail profil candidate */
export const ProfilCosmonaute = (dispatch) => (id) => {
  const urlCosmonaute = API_HOST + API_PROFIL_DETAILS + (id ? id : getUserId());
  AxiosService.get(urlCosmonaute).then(
    (profil) => {
      dispatch({
        type: RETRIEVE_COSMONAUTE,
        payload: profil.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du détail profil",
        },
      });
    }
  );
};

/* update detail profil candidate */
export const UpdateCosmonaute = (dispatch) => async (body) => {
  var urlCosmonaute = ""
  if (body.id === getUserId()) {
    urlCosmonaute = API_HOST + API_UPDATE_PROFILHEADER + getUserId()
  } else {
    urlCosmonaute = API_HOST + API_UPDATE_PROFILHEADER + body.id
  }
  AxiosService.put(urlCosmonaute, body).then(
    (response) => {
      dispatch({
        type: UPDATE_COSMONAUTE,
        payload: response.data.profil,
        toastContent: {
          status: "success",
          body: "Résumé est modifé avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: { status: "error", body: "Echec de modification résumé" },
      });
    }
  );
};

/* ypdate detail profil candidate */
export const UpdateCosmonauteDecription = (dispatch) => async (body, id) => {
  const urlCosmonaute = API_HOST + API_UPDATE_PROFIL + (id ? id : getUserId());
  AxiosService.put(urlCosmonaute, body).then(
    (response) => {
      dispatch({
        type: UPDATE_COSMONAUTE_DESCRIPTION,
        payload: response.data.profil,
        toastContent: {
          status: "success",
          body: "Profil candidat est modifié avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de modification profil candidat",
        },
      });
    }
  );
};

/* upload CV */
export const ResumeUpload = (dispatch) => (formData) => {
  const URL = API_HOST + API_UPLOAD_RESUME + formData.get("type");
  // Call API upload logo
  AxiosService.post(URL, formData).then(
    (profil) => {
      dispatch({
        type: COSMONAUTE_RESUME,
        payload: profil.data,
        toastContent: { status: "success", type: "Success_CV", body: "CV importé avec succès" },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", type: "Error_CV", body: "Erreur lors de l'importation du cv" },
      });
    }
  );
};

/* get CV */
export const GetResume = (dispatch) => async (id) => {
  const urlanguage = API_HOST + API_GET_RESUME + "cv/" + (id ? id : getUserId());
  AxiosService.get(urlanguage).then(
    (resume) => {
      dispatch({
        type: GET_RESUME,
        payload: resume.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec de récupération du CV" },
      });
    }
  );
};

/* get percent candidate */
export const pourcentageCondidat = (dispatch) => async (id) => {
  const urlanguage = API_HOST + API_POURCENTAGE_CONDIDAT + (id ? id : getUserId());
  AxiosService.get(urlanguage).then(
    (resume) => {
      dispatch({
        type: POURCENTAGE_CONDIDAT,
        payload: resume.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec de récupération du CV" },
      });
    }
  );
};

/* print cv */
export const printResume = (dispatch) => (profil, anonyme) => {
  const urlApi = API_HOST + API_PRINT_CV + profil.id_user;
  AxiosService.get(urlApi, { params: { anonyme: anonyme ? true : false } });
  fetch(urlApi + "?anonyme=" + (anonyme ? true : false)).then(
    (res) => {
      return res.blob();
    },
    (error) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", type: "Error_CV", body: "Echec de téléchargement du CV" },
      });
      return;
    })
    .then((blob) => {
      if (blob) {
        const fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = "CV_" + (anonyme ? (profil.name.charAt(0) + profil.lastname.charAt(0)) : (profil.name + "_" + profil.lastname)) + ".pdf"
        fileLink.click();
        window.URL.revokeObjectURL(fileURL);
      }
    });
}