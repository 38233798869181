import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import Header from "../../components/Header/Header";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import cosmonaute from "../../assets/img/inscription/recrute-cosmonaute.png";
import fusil from "../../assets/img/inscription/recrute-fusil.png";
import "./Recrute.scss";
import overlay from "../../assets/img/inscription/recrute-overlay4.png";
import cercle from "../../assets/img/inscription/recrute-overlay5.png";

export default function Recrute() {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (history.location.hash) {
      document.getElementById(history.location.hash).scrollIntoView()
    }
  }, [history.location.hash]);

  const routehunter = () => {
    let path = `/recruter`;
    history.push(path);
    window.scroll(0, 0)
  };
  const routeinspirnaute = () => {
    let path = `/inscription`;
    history.push(path);
    window.scroll(0, 0)
  };
  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      {/* Section 1 how do we recrute */}
      <section className="recrute-section1">
        <Header />
        <ScrollAnimation animateIn='flipInY'>
          <div className="text-center text-transparent text-uppercase roboto-black title-recrute1 fontSize-90 text-spacing-2">
            {t("recrute.section1.title")}
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='flipInY'>
          <div className="text-center text-uppercase yellow-color roboto-black description-recrute2 fontSize-120">
            {t("recrute.section1.description")}
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="tada">
          <div className="scroll-downs mt-5 padding-button">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </ScrollAnimation>
        <div className="recrute-overlay1" id='r-mobile'></div>
        <div className="recrute-stars11"></div>
      </section>
      {/* Section 2 possible mission */}
      <section className="recrute-section2">
        <div className="text-transparent text-uppercase roboto-condensed-bold title-recrute2 fontSize-90">
          {t("recrute.section2.title")}
        </div>
        <div className="roboto-condensed-bold text-uppercase description-recrute2 fontSize-120">
          <div className="text2 text-spacing-4 padding-mobile">
            {t("recrute.section2.description1")}
          </div>
          <div className="text2 text-spacing-4">
            {t("recrute.section2.description2")}
          </div>
          <div className="text2">{t("recrute.section2.description3")}</div>
        </div>
        <div className="roboto-bold text-uppercase text-recrute2 m-2 fontSize-40">
          <div className="text2">{t("recrute.section2.text1")}</div>
          <div>{t("recrute.section2.text2")}</div>
          <div>{t("recrute.section2.text3")}</div>
          <div>{t("recrute.section2.text4")}</div>
        </div>
        <div>
          <img src={fusil} alt='fusil' className="position-absolute recrute-fusil animate-3"></img>
          <img src={cosmonaute} alt='cosmonaute' className="recrute-cosmonaute  animate-3"></img>
        </div>
        <div className="recrute-overlay2"></div>
      </section>
      {/* Section 3 */}
      <section className="recrute-section3">
        <div className="recrute-cd"></div>
        <div className="text-uppercase roboto-condensed-bold title-recrute3 fontSize-125">
          {t("recrute.section3.title")}
        </div>
        <div className="roboto-bold text-uppercase description-recrute3 fontSize-40 margin-top-recrute">
          <div>{t("recrute.section3.description1")}</div>
          <div>{t("recrute.section3.description2")}</div>
          <div>{t("recrute.section3.description3")}</div>
          <div>{t("recrute.section3.description4")}</div>
          <div>{t("recrute.section3.description5")}</div>
        </div>
        <div className="recrute-diamant1"></div>
        <div className="recrute-diamant2"></div>
      </section>
      {/* section 4 */}
      <section className="recrute-section4">
        <div className="recrute-screen1"></div>
        <img src={overlay} alt='overlay' className="position-absolute recrute-overlay4 animate-3"></img>
        <div className="text-uppercase roboto-condensed-bold recrute-title fontSize-125">
          {t("recrute.section4.title")}
        </div>
        <div className="roboto-bold text-uppercase recrute-description fontSize-40">
          <div>{t("recrute.section4.description1")}</div>
          <div>{t("recrute.section4.description2")}</div>
          <div>{t("recrute.section4.description3")}</div>
          <div>{t("recrute.section4.description4")}</div>
        </div>
      </section>
      {/* Section 5 */}
      <section className="recrute-section5">
        <div className="recrute-image2"></div>
        <div className="text-uppercase roboto-condensed-bold recrute-title fontSize-125">
          {t("recrute.section5.title")}
        </div>
        <div className="roboto-bold text-uppercase recrute-description fontSize-40">
          <div>{t("recrute.section5.description1")}</div>
          <div>{t("recrute.section5.description2")}</div>
          <div>{t("recrute.section5.description3")}</div>
        </div>
        <img src={cercle} alt="cercle" className="recrute-overlay5"></img>
      </section>
      <section className="recrute-section6">
        <div className="recrute-screen2"></div>
        <div className="recrute-screen3"></div>
        <div id="redirect-section" className="text-center text-uppercase roboto-condensed-bold recrute-title1 fontSize-125 row align-items-center ">
          <div>{t("recrute.section6.title")}</div>
          <div className="text-uppercase roboto-condensed-bold color-white fontSize-60 mt-5">
            {t("recrute.section6.description1")}
          </div> 
          <div className="div1" id="#redirect-section">
            <Button className="box1" onClick={() => {  routehunter(); }}>{t("recrute.section6.button1")}</Button>
            <div className="text-recrute">
              {t("recrute.section6.description2")}
            </div>
            <Button className="box2" onClick={() => { routeinspirnaute(); }}>{t("recrute.section6.button2")}</Button>
          </div>
        </div>
        <div className="recrute-overlay6"></div>
      </section>
      <section className="recrute-section7">
        <div className="etoile-propos"></div>
        <div className="overlay-sections6"></div>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-178 yellow-color float-left text-spacing-7">
                {t("home.univers")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold ml-big text-uppercase fontSize-178 yellow-color text-spacing-3 infini">
                {t("home.infini")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                {t("home.contact")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <div>
              <p className="roboto-condensed-bold text-center fontSize-40 margin-infinity">
                {t("home.adress")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 margin-infinity">
                {t("home.phone")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 ">
                {t("home.mail")}
              </p>
              <p className="roboto-condensed text-center fontSize-25 notice text-underline">
                <Link to="/notice">{t("home.mention")}</Link>
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}
