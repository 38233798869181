import { API_HOST, API_FOLLOWUP_MISSIONS_ADMINISTRATOR, API_FOLLOWUP_INTERVIEWS_ADMINISTRATOR} from "../../Api";
import { SHOW_TOAST } from '../constants/Toast';
import { FOLLOWUP_MISSIONS_ADMINISTRATOR, FOLLOWUP_INTERVIEWS_ADMINISTRATOR } from '../constants/Administrator';
import axiosServices from "../../services/Axios/axiosServices";

/* Get list Missions Follow UP for Admin */
const getAllMissionsFollowUP = (dispatch) => () => {
    // URL get list Missions for Admin
    const URL = API_HOST + API_FOLLOWUP_MISSIONS_ADMINISTRATOR;
    // Call API list Missions for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: FOLLOWUP_MISSIONS_ADMINISTRATOR, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste follow up missions" }
            });
        }
    );
}

/* Get list Interview Follow UP for Admin */
const getAllInterviewsFollowUP = (dispatch) => (id) => {
    // URL get list Interview for Admin
    const URL = API_HOST + API_FOLLOWUP_INTERVIEWS_ADMINISTRATOR;
    // Call API list Interview for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: FOLLOWUP_INTERVIEWS_ADMINISTRATOR, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de recupération de la liste follow up interviews" }
            });
        }
    );
}


/* Export Action FollowUP Missions/Interviews for Administrator */
export { getAllMissionsFollowUP, getAllInterviewsFollowUP };