import React from 'react';
import { Card, Row } from 'react-bootstrap';
import './NothingToShow.scss'

export default function NothingToShow({ type }) {
    return (
        <>
            {
                type === "array"
                    ? <div className="align-items-center text-nothing text-center p-3 border-bottom">Pas d'informations à afficher</div>
                    : <Card className="card-content">
                        <Card.Body>
                            <Row className="align-items-center">
                                <div className="text-nothing text-center p-5">
                                    Pas d'informations à afficher
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
            }
        </>
    );
};

