/* Focus input */
function onFocusPhoneNumber(formGroup, formControl) {
    if (formGroup.values[formControl] === "") {
        formGroup.setFieldValue(formControl, "+33");
    }
}

/* Blur input */
function onBlurPhoneNumber(formGroup, formControl) {
    if (formGroup.values[formControl] === "+33") {
        formGroup.setFieldValue(formControl, "");
    }
}


export { onFocusPhoneNumber, onBlurPhoneNumber }