import React, { useState } from 'react';
import axios from 'axios';
import { Image } from 'react-bootstrap';
import spinner from '../../assets/gif/spinner.gif';
import './Loader.scss'

export default function Loader() {
    /* Sates */
    const [isLoading, setIsLoading] = useState(false);
    let requests = [];

    /* Show Loader */
    axios.interceptors.request.use(
        function (config) {
            requests.push(config);
            setIsLoading(true);
            return config;
        },
        function (error) {
            closeLoader(error);
            return Promise.reject(error);
        });

    /* Hide Loader */
    axios.interceptors.response.use(
        function (response) {
            closeLoader(response);
            return response;
        },
        function (error) {
            closeLoader(error);
            return Promise.reject(error);
        });

    /* Close Loader */
    function closeLoader(response) {
        let index = requests.findIndex((el) => el.url === response.url);
        requests.splice(index, 1);
        if (!requests.length) setIsLoading(false);
    }

    return (
        <>
            {
                isLoading
                    ? 
                    <div id="inspire-loader" className="overlay-loader">
                        <div className="img-fluid spinner">
                            <Image src={spinner} />
                        </div>
                    </div>
                    : null
            }
        </>
    );
};

