import React, { useReducer } from 'react';
import {
    getProfilRecruiter, getDomainRecruiter,
    editProfilRecruiter, editProfilDetailRecruiter, dumpProfilRecruiter,
    logoUpload, getLogoRecruiter, dumpLogoRecruiter
} from '../actions/ProfilRecruiter';
import {
    getFirstThreeProposedMissions,
    getFirstThreeUpcommingInterview,
    getFirstThreeOngoingMission,
    getDashboardRecruiter,
    pourcentageRecruiter
} from '../actions/DashboardRecruiter';
import { getAllInterviews, proposeInterviewRecruiter, changeInterviewRecruiter, declineInterviewRecruiter, dumpInterview } from '../actions/InterviewsRecruiter';
import { getAllSkills } from '../actions/SkillsAdministrator';
import {
    getAllMissionsRecruiter, getOneMissionRecruiter, getProposedProfilesMission, removeProfileFromMission,
    addMission, editMission, deleteMission, interruptMission, dumpMission,
    rejectCandidate, recruteCandidate
} from '../actions/MissionRecriter';
import { hideToast } from '../actions/Toast';
import {
    SHOW_RCRUITER_PROFILE,
    EDIT_RCRUITER_PROFILE,
    DOMAIN_RECRUITER,
    RECRUITER_LOGO,
    GET_RECRUITER_LOGO,
    DUMP_RECRUITER,
    DUMP_LOGO_RECRUITER,
    THREE_FIRST_PROPOSED_MISSIONS,
    THREE_FIRST_UPCOMMING_INTERVIEWS,
    THREE_FIRST_ONGOING_MISSIONS,
    DASHBOARD_RECRUITER,
    ALL_MISSIONS_RECRUITER,
    ONE_MISSION_RECRUITER,
    PROPOSED_PROFILES_MISSION_RECRUITER,
    REMOVE_PROFIL_MISSION_RECRUITER,
    ADD_NEW_MISSION,
    EDIT_MISSION,
    DELETE_MISSION,
    INTERRUPT_MISSION,
    DUMP_MISSION,
    REJECT_CANDIDATE,
    RECRUTE_CANDIDATE,
    ALL_INTERVIEWS_RECRUITER,
    PROPOSE_INTERVIEW_RECRUITER,
    CHANGE_INTERVIEW_RECRUITER,
    DECLINE_INTERVIEW_RECRUITER,
    DUMP_INTERVIEW,
    POURCENTAGE_RECRUITER
} from '../constants/Recruiter';
import { ALL_SKILLS_ADMIN } from '../constants/Administrator';
import { SHOW_TOAST, HIDE_TOAST } from '../constants/Toast';
import {CONTACT_ADMIN} from "../constants/ContactAdmin";
import {contactAdmin} from "../actions/ContactAdmn";
import { getListRegions } from "../actions/Regions";

/* Recruiter Reducer */
const RecruiterReducer = (state, action) => {
    switch (action.type) {
        /* Domain Recruiters */
        case DOMAIN_RECRUITER:
            return { ...state, toastContent: null, recruiterDomain: action.payload };
        /* Profile */
        case SHOW_RCRUITER_PROFILE:
            return { ...state, toastContent: null, recruiterProfil: action.payload };
        case EDIT_RCRUITER_PROFILE:
            return { ...state, toastContent: action.toastContent, profile: action.payload };
        case DUMP_RECRUITER:
            return { ...state, toastContent: null, profile: null };
        /* Logo Recruiter */
        case GET_RECRUITER_LOGO:
            return { ...state, toastContent: null, recruiterLogo: action.payload };
        case RECRUITER_LOGO:
            return { ...state, toastContent: action.toastContent, logo: action.payload };
        case DUMP_LOGO_RECRUITER:
            return { ...state, toastContent: null, logo: null };
        /* Dashboard */
        case DASHBOARD_RECRUITER:
            return { ...state, toastContent: action.toastContent, infoDashboard: action.payload };
        case THREE_FIRST_PROPOSED_MISSIONS:
            return { ...state, toastContent: null, firstThreeProposedMissions: action.payload };
        case THREE_FIRST_UPCOMMING_INTERVIEWS:
            return { ...state, toastContent: action.toastContent, firstThreeUpcommingInterviews: action.payload };
        case THREE_FIRST_ONGOING_MISSIONS:
            return { ...state, toastContent: action.toastContent, firstThreeOngoingMissions: action.payload };
        /* Mission */
        case ALL_MISSIONS_RECRUITER:
            return { ...state, allMissionRecruiter: action.payload, toastContent: null };
        case ONE_MISSION_RECRUITER:
            return { ...state, oneMission: action.payload, toastContent: null };
        case PROPOSED_PROFILES_MISSION_RECRUITER:
            return { ...state, allProposedProfils: action.payload, toastContent: null };
        case REMOVE_PROFIL_MISSION_RECRUITER:
            return { ...state, mission: action.payload, toastContent: null };
        case ADD_NEW_MISSION:
            return { ...state, mission: action.payload, toastContent: action.toastContent }
        case EDIT_MISSION:
            return { ...state, mission: action.payload, toastContent: action.toastContent }
        case DELETE_MISSION:
            return { ...state, mission: action.payload, toastContent: action.toastContent };
        case INTERRUPT_MISSION:
            return { ...state, mission: action.payload, toastContent: action.toastContent };
        case REJECT_CANDIDATE:
            return { ...state, mission: action.payload, toastContent: action.toastContent };
        case RECRUTE_CANDIDATE:
            return { ...state, mission: action.payload, toastContent: action.toastContent };
        case DUMP_MISSION:
            return { ...state, mission: null, toastContent: null };
        /* Interview */
        case ALL_INTERVIEWS_RECRUITER:
            return { ...state, allInterviews: action.payload, toastContent: action.toastContent };
        case PROPOSE_INTERVIEW_RECRUITER:
            return { ...state, interview: action.payload, toastContent: action.toastContent };
        case CHANGE_INTERVIEW_RECRUITER:
            return { ...state, interview: action.payload, toastContent: action.toastContent };
        case DECLINE_INTERVIEW_RECRUITER:
            return { ...state, interview: action.payload, toastContent: action.toastContent };
        case CONTACT_ADMIN:
            return { ...state,  toastContent: action.toastContent };
        case DUMP_INTERVIEW:
            return { ...state, interview: null, toastContent: null };
        /* Skills */
        case ALL_SKILLS_ADMIN:
            return { ...state, allSkills: action.payload, toastContent: action.toastContent };
        case POURCENTAGE_RECRUITER:
            return { ...state, pourcentage: action.payload, toastContent: action.toastContent };
        /* Show | Hide Toast */
        case SHOW_TOAST:
            return { ...state, toastContent: action.toastContent };
        case HIDE_TOAST:
            return { ...state, toastContent: null };
        default:
            return state;
    }
};
/* Recruiter State */
const RecruiterState = (reducer, actions, defaultValue) => {
    const Context = React.createContext();
    const Provider = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, defaultValue);

        const boundActions = {};
        for (let key in actions) {
            boundActions[key] = actions[key](dispatch);
        }

        return (
            <Context.Provider value={{ state, ...boundActions }}>
                {children}
            </Context.Provider>
        );
    };
    return { Context, Provider };
};
/* Recruiter Provider Context */
export const { Provider, Context } = RecruiterState(
    RecruiterReducer,
    {
        getProfilRecruiter, getDomainRecruiter,
        logoUpload, getLogoRecruiter, dumpLogoRecruiter,
        editProfilRecruiter, editProfilDetailRecruiter, dumpProfilRecruiter,
        getFirstThreeProposedMissions, getFirstThreeUpcommingInterview, getFirstThreeOngoingMission, getDashboardRecruiter,
        getAllMissionsRecruiter, getOneMissionRecruiter, getProposedProfilesMission, removeProfileFromMission,
        addMission, editMission, deleteMission, interruptMission, dumpMission,
        rejectCandidate, recruteCandidate,
        getAllInterviews, proposeInterviewRecruiter, changeInterviewRecruiter, declineInterviewRecruiter, dumpInterview,
        getAllSkills,
        hideToast,
        contactAdmin,
        pourcentageRecruiter,
        getListRegions
    },
    {
        toastContent: null,
        recruiterProfil: null, recruiterLogo: null, recruiterDomain: null, profile: null,
        allInterviews: null, interview: null,
        allMissionRecruiter: null, oneMission: null, mission: null, allProposedProfils: null,
        firstThreeOngoingMissions: null, firstThreeUpcommingInterviews: null, firstThreeProposedMissions: null, infoDashboard: null,
        allSkills: null,pourcentage:null
    }
);
