import './MissionsFilter.scss';
import { Row, Col, Form } from 'react-bootstrap';
import { FaSearch, FaMapMarkerAlt } from 'react-icons/fa';
import { formatDate } from '../../services/Controller/DateFormatter';
import { useFormik } from 'formik';
import { useEffect } from 'react';

export default function MissionsFilter({ resetForm, setResetForm, defaultTabValue, allMissions, setDisplayedData, namePage }) {
    /* Form Filter */
    const formFilter = useFormik({
        initialValues: {
            title: "",
            startDate: "",
            endDate: "",
            place: "",
            name: ""
        },
        onSubmit: values => {
            if (namePage === "follow-candidats" || namePage === "follow-intrviews") {
                const filterData = allMissions ? allMissions : [];
                const list = filterData.filter((el) => filterCondition(values, el));
                setDisplayedData(list);
            } else {
                const filterData = allMissions[defaultTabValue] ? allMissions[defaultTabValue] : [];
                const list = filterData.filter((el) => filterCondition(values, el));
                setDisplayedData(list);
            }

        }
    })
    /* reset Form */
    useEffect(() => {
        if (resetForm) {
            formFilter.resetForm();
            setResetForm(false);
        }
    }, [resetForm, setResetForm, formFilter]);
    /* All Conditions Filter */
    function filterCondition(FormValues, mission) {
        if (namePage === "follow-candidats") {
            return (
                (FormValues.title === "" || (FormValues.title !== "" && mission.missionTitle?.toLowerCase()?.indexOf(FormValues.title) !== -1))
                && (FormValues.name === "" || (FormValues.name !== "" && mission.name?.toLowerCase()?.indexOf(FormValues.name) !== -1))
                && (FormValues.startDate === "" || (FormValues.startDate !== "" && formatDate(FormValues.startDate) <= formatDate(mission.dateStart)))
                && (FormValues.endDate === "" || (FormValues.endDate !== "" && formatDate(FormValues.endDate) >= formatDate(mission.dateEnd)))
            )
        } else if (namePage === "follow-intrviews") {
            return (
                (FormValues.title === "" || (FormValues.title !== "" && mission.missionTitle?.toLowerCase()?.indexOf(FormValues.title) !== -1))
                && (FormValues.name === "" || (FormValues.name !== "" && mission.name?.toLowerCase()?.indexOf(FormValues.name) !== -1))
                && (FormValues.startDate === "" || (FormValues.startDate !== "" && formatDate(FormValues.startDate) <= formatDate(mission.firstDate)))
                && (FormValues.endDate === "" || (FormValues.endDate !== "" && formatDate(FormValues.endDate) >= formatDate(mission.secondDate)))
            )
        } else {
            return (
                (FormValues.title === "" || (FormValues.title !== "" && mission.title?.toLowerCase()?.indexOf(FormValues.title) !== -1))
                && (FormValues.place === "" || (FormValues.place !== "" && mission.place?.toLowerCase()?.indexOf(FormValues.place) !== -1))
                && (FormValues.startDate === "" || (FormValues.startDate !== "" && formatDate(FormValues.startDate) <= formatDate(mission.dateStart)))
                && (FormValues.endDate === "" || (FormValues.endDate !== "" && formatDate(FormValues.endDate) >= formatDate(mission.dateEnd)))
            )
        }
    }
    return (
        <>
            <Form onChange={formFilter.handleSubmit}>
                <Row>
                    <Col lg={3} md={6} xs={12}>
                        <Form.Group className="position-relative">
                            <FaSearch className="position-absolute searsh-Input" />
                            <Form.Control className='input-filter' placeholder="Rechercher par titre"
                                name="title" id="title" type="text"
                                onChange={formFilter.handleChange}
                                value={formFilter.values.title.toLowerCase()}
                                onBlur={formFilter.handleBlur} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} md={6} xs={12}>
                        <Form.Group>
                            <Row>
                                <Col lg={3} md={2} xs={2}>
                                    <Form.Label className="label-Filter align-items-center">De</Form.Label>
                                </Col>
                                <Col lg={9} md={10} xs={10}>
                                    <Form.Control className='input-filter' type="date" name="startDate" id="startDate"
                                        onChange={formFilter.handleChange}
                                        value={formFilter.values.startDate}
                                        onBlur={formFilter.handleBlur} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col lg={3} md={6} xs={12}>
                        <Form.Group>
                            <Row>
                                <Col lg={3} md={2} xs={2}>
                                    <Form.Label className="label-Filter align-items-center">À</Form.Label>
                                </Col>
                                <Col lg={9} md={10} xs={10}>
                                    <Form.Control className='input-filter' type="date" name="endDate" id="endDate"
                                        onChange={formFilter.handleChange}
                                        value={formFilter.values.endDate}
                                        onBlur={formFilter.handleBlur} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    {namePage === "follow-candidats" || namePage === "follow-intrviews"
                        ? <Col lg={3} md={6} xs={12}>
                            <Form.Group className="position-relative">
                                <FaSearch className="position-absolute searsh-Input" />
                                <Form.Control className='input-filter' placeholder="Rechercher par nom"
                                    type="text" name="name" id="name"
                                    onChange={formFilter.handleChange}
                                    value={formFilter.values.name.toLowerCase()}
                                    onBlur={formFilter.handleBlur} />
                            </Form.Group>
                        </Col>
                        : <Col lg={3} md={6} xs={12}>
                            <Form.Group className="position-relative">
                                <FaMapMarkerAlt className="position-absolute searsh-Input" />
                                <Form.Control className='input-filter' placeholder="Rechercher par lieu"
                                    type="text" name="place" id="place"
                                    onChange={formFilter.handleChange}
                                    value={formFilter.values.place.toLowerCase()}
                                    onBlur={formFilter.handleBlur} />
                            </Form.Group>
                        </Col>}

                </Row>
            </Form>

        </>
    );
};

