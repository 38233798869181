import axios from "axios";
import { API_AUTH_PATH, API_HOST } from "../../Api";
import { CURRENT_USER, DUMP_CURRENT_USER } from '../constants/Login';
import { setToken, setRefreshToken } from "../../services/Controller/AuthService";
import { decodeToken } from "../../services/Controller/CurrentUserServices";


const signin = (dispatch) => async (data, history) => {
    const URL = API_HOST + API_AUTH_PATH;
    axios.post(URL, data).then(
        (response) => {
            const currentUser = decodeToken(response.data.token);
            dispatch({ type: CURRENT_USER, payload: { ...currentUser, status: "success" } });
            if (currentUser.isActive) {
                setToken(response.data.token);
                setRefreshToken(response.data.refresh_token);
                /* Admin */
                if (currentUser.roles[0] === 'ROLE_ADMIN') {
                    setTimeout(() => {
                        history.push('/admin/dashboard');
                    }, 1000);
                }
                /* recruiter */
                else if (currentUser.roles[0] === 'ROLE_RECR') {
                    if (currentUser.recruteur <= 40) {
                        setTimeout(() => {
                            history.push({ pathname: '/recruiter/profil', state: "completeProfile" });
                        }, 1000);
                    }
                    else {
                        setTimeout(() => {
                            history.push('/recruiter/dashboard');
                        }, 1000);
                    }
                }
                /* candidate */
                else if (currentUser.roles[0] === 'ROLE_USER') {
                    if (currentUser.profil <= 40) {
                        setTimeout(() => {
                            history.push({ pathname: '/user/profil', state: "completeProfile" });
                        }, 1000);
                    }
                    else {
                        setTimeout(() => {
                            history.push('/user/dashboard');
                        }, 1000);
                    }
                }
                /* Error */
                else history.push('/home');
            }
        },
        (err) => {
            dispatch({ type: CURRENT_USER, payload: { status: 'error' } });
        }
    )
}

/* dump warning */
const dumpUser = (dispatch) => () => {
    dispatch({ type: DUMP_CURRENT_USER, payload: null });
}

export { signin, dumpUser };

