import React from "react";
import { Modal, Row, Col } from 'react-bootstrap';
import Rating from "@mui/material/Rating";
import './ModalDetail.scss';


export default function ModalDetail({ modalShow, setModalShow, DataToShow }) {

    return (
        <>
            {
                DataToShow
                    ?
                    <Modal show={modalShow} onHide={() => { setModalShow(false) }} size="lg" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter"> {DataToShow.Title} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                DataToShow.data?.map((el, index) => {
                                    return (
                                        <>
                                        {el.value !== '- - -' ? 
                                        <Row className="mb-1" key={"K" + index}>
                                            <Col lg={4} md={4} xs={4} className="modal-Detail-Title"> {el.value?.length ? el.title +" :": null} </Col>
                                            {
                                                el.title !== "Compétences" && el.title !== "Langues"
                                                    ? <Col lg={8} md={8} xs={8} className="modal-Detail-Value"> {el.value} </Col>
                                                    :
                                                    el.value && el.value.length
                                                        ?
                                                        <Col lg={8} md={8} xs={8} className="modal-Detail-Value">
                                                            <Row>
                                                                {
                                                                    el.value.map((item, index) => {
                                                                        return (
                                                                            <Col className="d-flex" lg={6} md={12} xs={12} key={"skill" + index}>
                                                                                <label className='mr-20'>
                                                                                    {item && item.name ? item.name : '- - -'}
                                                                                </label>
                                                                                <Rating
                                                                                    value={item.rating ? item.rating : 0}
                                                                                    className="rating-style"
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                        </Col>
                                                        : null
                                            } 
                                        </Row>
                                        : null}
                                        </>
                                    )
                                })
                            }
                        </Modal.Body>
                    </Modal>
                    : null
            }
        </>
    );
};

