import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Card, Form, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import './LoginForm.scss'
import { Context as AuthContext } from '../../context/reducers/AuthReducer'
import { useHistory } from "react-router-dom";
import { ErrorFeild, ErrorClass } from "../../services/Controller/ValidationForm";
import { emailPattern } from "../../services/Controller/PattenService";

/* Validation form */
const validationSchema = Yup.object({
    email: Yup.string()
        .required('Email obligatoire')
        .email('Email invalide')
        .matches(emailPattern, "Email invalide"),
    password: Yup.string()
        .required('Mot de passe obligatoire'),
});

/* login Form */
function LoginForm() {
    /* call Context */
    const { signin, state, dumpUser } = useContext(AuthContext);

    /* use History */
    const history = useHistory();

    /* use translation */
    const { t } = useTranslation();

    /* Alert Sates */
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertColor, setShowAlertColor] = useState("");
    const [showAlertContent, setShowAlertContent] = useState("")

    /* show alert */
    useEffect(() => {
        if (state.currentUser) {
            setShowAlert(true);
            if (state.currentUser.status === "success") {
                if (state.currentUser.isActive) {
                    setShowAlertColor("green");
                    setShowAlertContent("Connexion établie avec succès");
                }
                else {
                    setShowAlertColor("secondary");
                    setShowAlertContent("Votre candidature n'est pas encore acceptée par l'administrateur de la plateforme");
                }
            }
            else {
                setShowAlertColor("danger");
                setShowAlertContent("Email ou mot de passe incorrect");
            }
            dumpUser();
        }
    }, [state.currentUser, dumpUser]);

    /* Form Login */
    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: ""
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            setShowAlert(false);
            setShowAlertColor("");
            setShowAlertContent("");
            signin(loginForm.values, history);
        }
    })

    return (


        <>
            <Card className="text-black  LoginForm " bg='default'>
                <div className="formSection ">
                    <Card.Title className="text-primary  "><p className="text-justify Roboto-blod title">CONNEXION</p>
                    </Card.Title>
                    <Card.Body className="p-5">
                        {/* show alert login */}
                        {
                            showAlert
                                ?
                                <Alert variant={showAlertColor} onClose={() => setShowAlert(false)} dismissible>
                                    {showAlertContent}
                                </Alert>
                                : null

                        }
                        <Form onSubmit={loginForm.handleSubmit}>
                            <Form.Group className=" formGroup">
                                <Form.Label className="text-navy required">Email</Form.Label>
                                <Form.Control type="email" name="email" id="email"
                                    className={"loginInput " + ErrorClass(loginForm, "password")}
                                    placeholder="Entrer votre email"
                                    onChange={loginForm.handleChange}
                                    value={loginForm.values.email}
                                />
                                {ErrorFeild(loginForm, "email")}

                            </Form.Group>
                            <Form.Group className="mb-3 formGroup ">
                                <Form.Label className="text-navy required mt-2">{t('login.password')}</Form.Label>
                                <Form.Control type="password" name="password" id="password"
                                    className={"loginInput " + ErrorClass(loginForm, "password")}
                                    placeholder="Entrer votre mot de passe"
                                    onChange={loginForm.handleChange}
                                    value={loginForm.values.password}
                                />
                                {ErrorFeild(loginForm, "password")}

                            </Form.Group>
                            <div className=" mb-3">
                                <a className="text-navy forget-pass" href="/forgetpassword">Mot de passe oublié ?</a>
                            </div>
                            <div className="d-flex flex-column  align-items-center col-12 ">
                                <Button className="LoginSubmitBtn " variant="primary" type="submit">  Lancer </Button>
                            </div>

                        </Form>
                    </Card.Body>
                </div>
                <Card.Footer className="LoginFormFooter align-items-center" bg='primary'>
                    <p className="text-center text-navy mt-2 ">
                        {t('login.registerTitle')} <a href="/recrute#redirect-section">
                            <span className="text-bold">{t('login.register')}</span>
                        </a>
                    </p>
                </Card.Footer>
            </Card>
        </>
    );
};

export default LoginForm;
