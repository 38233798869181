import { API_HOST, API_SKILLS_ADMINISTRATOR } from "../../Api";
import { SHOW_TOAST } from '../constants/Toast';
import { ALL_SKILLS_ADMIN, ADD_SKILL_ADMIN, EDIT_SKILL_ADMIN, DELETE_SKILL_ADMIN, DUMP_SKILL } from '../constants/Administrator';
import axiosServices from "../../services/Axios/axiosServices";

/* Get list Skills */
const getAllSkills = (dispatch) => () => {
    // URL get list Skills
    const URL = API_HOST + API_SKILLS_ADMINISTRATOR;
    // Call API list Skills
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ALL_SKILLS_ADMIN, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste Skills" }
            });
        }
    );
}

/* Add Skill */
const addSkill = (dispatch) => (body) => {
    // URL add Skill
    const URL = API_HOST + API_SKILLS_ADMINISTRATOR;
    // Call API add skill
    axiosServices.post(URL, body)
        .then((res) => {
            dispatch({
                type: ADD_SKILL_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Skill est ajouté avec succès" }
            });
        })
        .catch((err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: {
                    status: err.response && err.response.status === 409 ? 'warning' : 'error',
                    body: err.response && err.response.status === 409 ? "Skill déjà existe" : "Echec d'ajout Skill"
                }
            });
        });
}

/* Edit Skill */
const editSkill = (dispatch) => (body, id) => {
    // URL add Skill
    const URL = API_HOST + API_SKILLS_ADMINISTRATOR + '/' + id;
    // Call API add skill
    axiosServices.post(URL, body).then(
        (res) => {
            dispatch({
                type: EDIT_SKILL_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Skill est modifié avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: {
                    status: err.response && err.response.status === 409 ? 'warning' : 'error',
                    body: err.response && err.response.status === 409 ? "Skill déjà existe" : "Echec de modification Skill"
                }
            });
        }
    );
}

/* Delete Skill */
const deleteSkill = (dispatch) => (id) => {
    // URL delete Skill
    const URL = API_HOST + API_SKILLS_ADMINISTRATOR + '/' + id;
    // Call API delete skill
    axiosServices.delete(URL).then(
        (res) => {
            dispatch({
                type: DELETE_SKILL_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Skill est supprimé avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de suppression Skill" }
            });
        }
    );
}

/* Dump Skill */
const dumpSkill = (dispatch) => () => {
    dispatch({ type: DUMP_SKILL });
}

/* Export Action Skills for Administrator */
export { getAllSkills, addSkill, editSkill, deleteSkill, dumpSkill };