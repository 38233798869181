import {Button, Card, Form, Modal} from "react-bootstrap";
import {FaMapMarkerAlt, FaRegCalendarMinus} from "react-icons/fa";
import {formatDate} from "../../services/Controller/DateFormatter";
import {ErrorClass, ErrorFeild} from "../../services/Controller/ValidationForm";
import {useFormik} from "formik";
import * as Yup from "yup";
import {fr} from "date-fns/locale";

/* Validation Form Group Decline Inerview */
const validationdeclineForm = Yup.object({
    reason: Yup.string().required('raison de refus est obligatoire')
});

  export const  ModalMissionCausmonaute =({mission,type,setModalMission,modalMission,title,declineMission})=>{
      /* Form Group Decline Interview */
      const declineMissionForm = useFormik({
          initialValues: {
              reason: "",
          },
          validationSchema: validationdeclineForm,
          onSubmit: values => {
              declineMission(values,mission.missionProfilId)
              declineMissionForm.resetForm();
              setModalMission(false)
          }
      })


      function DetailMission() {
        return (
            <Card className="card-modal">
                <Card.Body>
                    <label className="text-header-modal">{mission && mission.title ? mission.title : '- - -'}</label>
                    <p className="text-subheader-modal">{mission&& mission.companyName ? mission.companyName : '- - -'}</p>
                    <div className="text-detail d-flex mb-1">
                        < FaRegCalendarMinus />
                        <span className="ml-10 mr-20">
                            Du {mission && mission.dateStart ? formatDate(mission.dateStart,fr) : '- - -'}
                            {" "}Au {mission && mission.dateEnd ? formatDate(mission.dateEnd,fr) : '- - -'}
                        </span>
                    </div>
                    <div className="text-detail d-flex mb-2">
                        < FaMapMarkerAlt />
                        <span className="ml-10 mr-20"> {mission && mission.place ? mission.place : '- - -'}  </span>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    /* Footer Decline */
    function DeclineMissionFooter() {
        return (
            <>
                {type === 'decline' ? (
                        <>
                            <Button className="save-button red shadow-unset mr-64" type="submit">
                                Confirmer
                            </Button>
                            <Button className="save-button blue shadow-unset" type="button"
                                    onClick={() => { setModalMission(false); declineMissionForm.resetForm(); }}>
                                Annuler
                            </Button>
                        </>

                    ) : null

                }
            </>
        )
    }


    /* Form Cosmonaute */
    function FormDeclineMission() {
        return (
            <Form onSubmit={declineMissionForm.handleSubmit}>
                <Modal.Body>
                    {/* Card of Interview information */}
                    <DetailMission />
                    {/* Form Decline */}
                    <Form.Group className="mt-5 mb-2">
                        <Form.Label className="text-Label-modal required">Raison du refus</Form.Label>
                        <Form.Control className={ErrorClass(declineMissionForm, 'reason')} as="textarea" rows={6}
                                      name="reason"
                                      id="reason"
                                      placeholder="Saisir votre commentaire ..."
                                      onChange={declineMissionForm.handleChange}
                                      onBlur={declineMissionForm.handleBlur}
                                      value={declineMissionForm.values.reason} />
                        {ErrorFeild(declineMissionForm, 'reason')}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <DeclineMissionFooter />
                </Modal.Footer>
            </Form>
        )
    }


    return(
        <>
            <Modal show={modalMission} size="lg" centered scrollable
                   onHide={() => {
                       setModalMission(false);
                       declineMissionForm.resetForm();

                   }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="modal-title"> {title} </Modal.Title>
                </Modal.Header>
                {/* Modal Content */}
                { type ==='decline' ?
                    FormDeclineMission()
                    :
                    null
                }
            </Modal>
        </>

    )
}

