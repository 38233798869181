import React, { useContext, useEffect, useState } from "react";
import "./MissionsAdmin.scss";
import { Row, Col, Tabs, Tab, Table } from "react-bootstrap";
import { FaCut, FaTrashAlt, FaEye } from "react-icons/fa";
import { Context as AdministratorContext } from "../../../context/reducers/AdminReducer";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import { formatDate } from "../../../services/Controller/DateFormatter";
import { getStatusMission } from "../../../services/Controller/MissionServices";
import AvatarGroup from "../../../components/avatar/avatarGroup";
import MissionsFilter from "../../../components/MissionsFilter/MissionsFilter";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Paginate from "../../../components/Paginate/Paginate";

/* Recruiters Missions Page */
function MissionsAdmin() {
  /* use Translation */
  const { t } = useTranslation();

  /* use History */
  const history = useHistory();

  /* Context API */
  const {
    getAllMissionsAdmin,
    deleteMission,
    interruptMission,
    dumpMission,
    hideToast,
    state,
  } = useContext(AdministratorContext);

  /* eslint-disable */
  useEffect(() => {
    getAllMissionsAdmin();
  }, []);
  /* eslint-enable */

  /* lists */
  const [allMissions, setAllMissions] = useState({
    All: [],
    proposed: [],
    ongoing: [],
    finished: [],
    interrupted: [],
  });

  useEffect(() => {
    if (state.allMissions && state.allMissions.All) {
      let list = state.allMissions.All;
      list.map((el) => (el.missionTag = getStatusMission(el)));
      setAllMissions({
        All: list,
        proposed: list.filter((el) => el.missionTag?.label === "Proposée"),
        ongoing: list.filter((el) => el.missionTag?.label === "En Cours"),
        finished: list.filter((el) => el.missionTag?.label === "Terminée"),
        interrupted: list.filter(
          (el) => el.missionTag?.label === "Interrompue"
        ),
      });
      setDisplayedData(list);
    }
  }, [state]);
  /* refresh Missions */
  useEffect(() => {
    if (state.mission) {
      getAllMissionsAdmin();
      dumpMission();
    }
  }, [dumpMission, getAllMissionsAdmin, state.mission]);

  /* Tab Active Value */
  const [defaultTabValue, setDefaultTabValue] = useState("All");

  /* Confirm Modal */
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);

  /* Filter Mission */
  const [resetForm, setResetForm] = useState(false);

  /* Table Content */
  const displayedColumns = [
    "Titre",
    "Entreprise",
    "Date du dépôt",
    "Date début et fin",
    "Lieu",
    "État",
    "Candidats proposés",
    "",
  ];
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  /* Content Missions List */
  function showMissionsContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length ? getListMissions() : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* list Missions Mapping */
  function getListMissions() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Mission" + index}>
          <td className="w-200">
            <div>
              <div className="title-mission link mb-2" onClick={() => { onSelectItem("Edit", el); }}>
                {el.title ? el.title : "- - -"}
              </div>
              <div id="tag" className="text-tag blue">
                <span className="padding-proposed-profile">
                  {el && el.profils ? el.profils.length : 0} profils proposés
                </span>
              </div>
            </div>
          </td>
          <td className="w-150">
            <p>{el.entreprise ? el.entreprise : "- - -"}</p>
          </td>
          <td className="w-150 create-date">
            <p>{el.createdAt ? formatDate(new Date(el.createdAt)) : "- - -"}</p>
          </td>
          <td className="w-200 from-to">
            <p>
              Du { el.dateStart ? formatDate(new Date(el.dateStart)) + " " : "- - -"}
              Au {el.dateEnd ? formatDate(new Date(el.dateEnd)) : "- - -"}
            </p>
          </td>
          <td className="w-90">
            <p>{el.place ? el.place : "- - -"}</p>
          </td>
          <td className="w-150">
            <label className={"text-tag " + el.missionTag?.class}>
              {el.missionTag?.label}
            </label>
          </td>
          <td className="w-250">
            {el && el.profils && el.profils.length
              ? <AvatarGroup size="md" profils={el.profils} />
              : null
            }
          </td>
          <td>
            <div className="float-right pointer d-flex">
              {/* Tooltip */}
              <ReactTooltip place="bottom" />
              {/* Interrupt Mission */}
              {el.missionTag?.label === "En Cours" ? (
                <span className="btn-detail red" data-tip="Interrompre" onClick={() => { onSelectItem("Interrupt", el); }}>
                  <FaCut className="mr-20" />
                </span>
              ) : null}
              {/* Delete Mission */}
              {el.missionTag?.label === "Proposée" ? (
                <span className="btn-detail red" data-tip="Supprimer" onClick={() => { onSelectItem("Delete", el); }}>
                  <FaTrashAlt className="mr-20" />
                </span>
              ) : null}
              {/* Detail Mission */}
              <span className="btn-detail blue" data-tip="Consulter" onClick={() => { onSelectItem("Edit", el); }}>
                <FaEye />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }

  /* Détail Mission */
  function redirectToMission(id) {
    history.push(t("role.administrator") + t("sidebar.missions.path") + "/" + id);
  }

  /* Select item */
  function onSelectItem(type, mission) {
    setMode(type);
    if (type === "Edit") redirectToMission(mission.id);
    else {
      setSelectedItem(mission.id);
      setShow(true);
    }
  }

  /* Delete | Interrupt Mission */
  function confirmAction() {
    if (mode === "Interrupt") interruptMission(selectedItem);
    else if (mode === "Delete") deleteMission(selectedItem);
  }

  /* Tabulation onChange */
  function changeTabulation(tab) {
    setResetForm(true);
    if (allMissions[tab] && allMissions[tab].length)
      setDisplayedData(allMissions[tab]);
    else setDisplayedData([]);
    setDefaultTabValue(tab);
  }
  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />

      {/* Confirm Modal */}
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={"Vous êtes sûr de vouloir " + (mode === "Interrupt" ? "interrompre" : "supprimer") + " cette mission ?"}
        setSelectedItem={setSelectedItem}
        Action={confirmAction}
      />
      {/* Mission List */}
      <div id="main-without-header" className="main-without-header">
        <Row className="align-md">
          <Col lg={3} md={12} xs={12} className="w-md-100">
            <label className="title-page">Missions</label>
          </Col>
          <Col lg={9} md={12} xs={12} className="w-md-100 filter-interview-page">
            <MissionsFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allMissions}
              setDisplayedData={setDisplayedData}
            />
          </Col>
        </Row>
        <Tabs
          activeKey={defaultTabValue}
          onSelect={(k) => changeTabulation(k)}
          className="mt-3 mb-3 w-100"
        >
          <Tab
            eventKey="All"
            title={
              defaultTabValue === "All"
                ? "Tous (" + (displayedData && displayedData ? displayedData.length : 0) + ")"
                : "Tous (" + (allMissions && allMissions.All ? allMissions.All.length : 0) + ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="proposed"
            title={
              defaultTabValue === "proposed"
                ? "Proposées (" +
                (displayedData ? displayedData.length : 0) + ")" : "Proposées (" +
                (allMissions && allMissions.proposed ? allMissions.proposed.length : 0) + ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="ongoing"
            title={
              defaultTabValue === "ongoing"
                ? "En cours (" + (displayedData ? displayedData.length : 0) + ")"
                : "En cours (" +
                (allMissions && allMissions.ongoing ? allMissions.ongoing.length : 0) + ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="finished"
            title={
              defaultTabValue === "finished"
                ? "Terminées (" + (displayedData ? displayedData.length : 0) + ")"
                : "Terminées (" + (allMissions && allMissions.finished ? allMissions.finished.length : 0) + ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="interrupted"
            title={
              defaultTabValue === "interrupted"
                ? "Interrompues (" + (displayedData ? displayedData.length : 0) + ")"
                : "Interrompues (" +
                (allMissions && allMissions.interrupted ? allMissions.interrupted.length : 0) + ")"
            }
          >
            {showMissionsContent()}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

/* Export Missions component */
export default MissionsAdmin;
