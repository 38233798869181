import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import Rating from "@mui/material/Rating";
import { useFormik, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { Context as InscriptionContext } from "../../context/reducers/InscriptionReducer";
import { ErrorFeild, ErrorClass } from "../../services/Controller/ValidationForm";
import { emailPattern, phonePattern, numberPattern, passwordPattern } from "../../services/Controller/PattenService";
import { onFocusPhoneNumber, onBlurPhoneNumber } from "../../services/Controller/PhoneService";
import AlertModal from '../../components/alert/alert.js';
import "./InscriptionForm.scss";

/* Validation Form Step 2 */
const validationProfile = Yup.object({
  numberyear: Yup.string()
    .required("Nb années d'expériences obligatoire")
    .matches(numberPattern, "Nb années d'expériences invalide")
    .max(2, "Nb années d'expériences ne doit pas dépasser 2 chiffres"),
  rate: Yup.string().matches(numberPattern, "Évaluation invalide"),
  terms: Yup.boolean().oneOf([true], "Accepter les termes et les conditions"),
  ref: Yup.string()
    .email("Email référence invalide")
    .matches(emailPattern, "Email référence invalide")
});

/* validation Form step 1 */
const validationCompte = Yup.object({
  email: Yup.string()
    .required("Email obligatoire")
    .email("Email invalide")
    .matches(emailPattern, "Email invalide"),
  password: Yup.string()
    .min(8, "Mot de passe doit contenir au moins 8 caractères")
    .max(15, "Mot de passe doit être entre 8 et 15 caractères")
    .matches(passwordPattern, "Utilisez au moins 8 caractères avec des lettres, des chiffres et des symbôles")
    .required("Mot de passe obligatoire"),
  passwordconfim: Yup.string()
    .required("Confirmation mot de passe obligatoire")
    .oneOf([Yup.ref("password"), null], "Mot de passe n'est pas identique"),
  firstname: Yup.string().required("Nom obligatoire"),
  lastname: Yup.string().required("Prénom obligatoire"),
  phone: Yup.string()
    .matches(phonePattern, "Numéro de téléphone doit commencer par +33 et ne doit contenir que des chiffres")
    .min(11, "Numéro de téléphone doit contenir au moins 11 caractères")
    .max(15, "Numéro de téléphone doit contenir au plus 15 chiffres")
});

/* */
export default function InscriptionForm() {
  /* Context Api */
  const { inscriptionInspirnaute, getListSkills, getListRegions, checkEmail, state } = useContext(InscriptionContext);

  /* States */
  const [step, setStep] = useState(1);
  const [initialSkills, setInitialSkills] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [errorEmail, setErrorEmail] = useState("");
  const [show, setShow] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    getListSkills();
    getListRegions().then((res) => { setAllRegions(res.data) });
  }, []);
  /* eslint-enable */

  /* Prepare skills display */
  useEffect(() => {
    if (state.allSkills) {
      const list = state.allSkills;
      let initialValues = [];
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        initialValues.push({
          typeSkill: element.name,
          options: element.skills
            ? element.skills.map((item) => {
              return { value: item.id, label: item.name };
            })
            : [],
          selectedSkills: [],
        });
      }
      setInitialSkills(initialValues);
    }
  }, [state, state.allSkills]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) {
      if (state.toastContent.status === "success") setShow(true);
      else setShow(false);
    }
  }, [state.toastContent]);

  /* Form experience */
  const ExperinceForm = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordconfim: "",
      firstname: "",
      lastname: "",
      phone: "",
    },
    validationSchema: validationCompte,
    onSubmit: (values) => {
      const data = { email: values.email };
      checkEmail(data).then(
        (res) => {
          if (res.exist === "true") setErrorEmail("Email existant");
          else {
            setErrorEmail("");
            setStep(2);
          }
        },
        (err) => {
          setErrorEmail("Email existant");
        })
    },
  });


  /* Form Profil */
  const FormProfil = useFormik({
    initialValues: {
      degree: "",
      numberyear: "",
      position: "",
      place: "",
      rate: "",
      reference: "",
      ref: "",
      terms: false,
    },
    validationSchema: validationProfile,
    onSubmit: (values) => {
      setStep(3);
    },
  });

  /* Form skills */
  const FormSkills = useFormik({
    enableReinitialize: true,
    initialValues: {
      skills: initialSkills ? initialSkills : [],
    },
    onSubmit: (values) => {
      const data = {
        email: ExperinceForm.values.email,
        name: ExperinceForm.values.firstname,
        lastname: ExperinceForm.values.lastname,
        password: ExperinceForm.values.password,
        phone: ExperinceForm.values.phone,
        degree: FormProfil.values.degree,
        nbrAnneeExp: FormProfil.values.numberyear,
        lastReference: FormProfil.values.reference,
        lastReferenceContact: FormProfil.values.ref,
        adresse: FormProfil.values.place,
        poste: FormProfil.values.position,
        tarif: FormProfil.values.rate,
        skills: prepareDateSkills(),
      };
      ExperinceForm.resetForm();
      FormProfil.resetForm();
      FormSkills.resetForm();
      setStep(1);
      inscriptionInspirnaute(data);
      window.scrollTo(0, 0);
    },
  });

  /* Prepare Format Skills List */
  function prepareDateSkills() {
    let allSelectedSkills = [];
    for (let index = 0; index < FormSkills.values.skills.length; index++) {
      const element = FormSkills.values.skills[index];
      if (element.selectedSkills) {
        element.selectedSkills.forEach((selected) => {
          allSelectedSkills.push({
            id: selected.value,
            rating: selected.rating,
          });
        });
      }
    }
    return allSelectedSkills;
  }

  /* Steps actions */
  const Progress = () => {
    return (
      <React.Fragment>
        <ul id="progressbar">
          <li className={step >= 1 ? "active" : null} value={1} onClick={(e) => { updateSteps(e); }}>
            Compte
          </li>
          <li className={step >= 2 ? "active" : null} value={2} onClick={(e) => { updateSteps(e); }}>
            Ton profil
          </li>
          <li className={step === 3 ? "active" : null} value={3} onClick={(e) => { updateSteps(e); }} >
            Compétences
          </li>
        </ul>
      </React.Fragment>
    );
  };

  /* update step */
  const updateSteps = (e) => {
    if (e.target.value === 1) setStep(1);
    else if (e.target.value === 2) ExperinceForm.handleSubmit();
    else if (e.target.value === 3 && step !== 1) FormProfil.handleSubmit();
  };

  /* forst step */
  function StepOne() {
    return (
      <Form onSubmit={ExperinceForm.handleSubmit}>
        <Form.Group>
          <Form.Label className="required title-inscri">Email</Form.Label>
          <Form.Control id="email" type="text"
            className={ErrorClass(ExperinceForm, "email") || (errorEmail !== "" ? "error" : "")}
            placeholder="Saisir ton email"
            onChange={ExperinceForm.handleChange}
            value={ExperinceForm.values.email}
            onBlur={ExperinceForm.handleBlur}
            onFocus={() => { setErrorEmail("") }}
          />
          {ErrorFeild(ExperinceForm, "email")}
          {errorEmail !== "" ? <p className="error_feild mt-1">{errorEmail}</p> : null}
        </Form.Group>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className="required title-inscri height-label">
                Mot de passe
              </Form.Label>
              <Form.Control
                type="password" name="password" id="password"
                placeholder="Saisir ton mot de passe"
                className={ErrorClass(ExperinceForm, "password")}
                onChange={ExperinceForm.handleChange}
                value={ExperinceForm.values.password}
                onBlur={ExperinceForm.handleBlur}
              />
              {ErrorFeild(ExperinceForm, "password")}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="required title-inscri">
                Confirmez le mot de passe
              </Form.Label>
              <Form.Control
                type="password"
                name="passwordconfim"
                id="passwordconfim"
                placeholder="Confirmer ton mot de passe"
                className={ErrorClass(ExperinceForm, "passwordconfim")}
                onChange={ExperinceForm.handleChange}
                value={ExperinceForm.values.passwordconfim}
                onBlur={ExperinceForm.handleBlur}
              />
              {ErrorFeild(ExperinceForm, "passwordconfim")}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className="required title-inscri">Nom</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Saisir ton nom"
                className={ErrorClass(ExperinceForm, "firstname")}
                onChange={ExperinceForm.handleChange}
                value={ExperinceForm.values.firstname}
                onBlur={ExperinceForm.handleBlur}
              />
              {ErrorFeild(ExperinceForm, "firstname")}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="required title-inscri"> Prénom</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Saisir ton prénom"
                className={ErrorClass(ExperinceForm, "lastname")}
                onChange={ExperinceForm.handleChange}
                value={ExperinceForm.values.lastname}
                onBlur={ExperinceForm.handleBlur}
              />
              {ErrorFeild(ExperinceForm, "lastname")}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label className="title-inscri">
            Numéro de téléphone
          </Form.Label>
          <Form.Control
            type="text" id="phone" name="phone"
            placeholder="Saisir ton numéro de téléphone"
            className={ErrorClass(ExperinceForm, "phone")}
            onChange={ExperinceForm.handleChange}
            onBlur={() => { onBlurPhoneNumber(ExperinceForm, "phone") }}
            onFocus={() => { onFocusPhoneNumber(ExperinceForm, "phone") }}
            value={ExperinceForm.values.phone}
          />
          {ErrorFeild(ExperinceForm, "phone")}
        </Form.Group>
        <Button variant="primary" type="submit" className="float-right button-next"> Suivant</Button>
      </Form>
    );
  }

  /* second step */
  function StepTwo() {
    return (
      <Form onSubmit={FormProfil.handleSubmit}>
        <Form.Group>
          <Form.Label className="title-inscri">Dernier diplôme obtenu</Form.Label>
          <Form.Select name="degree" id="degree" className={ErrorClass(FormProfil, "degree")}
            onChange={FormProfil.handleChange}
            onBlur={FormProfil.handleBlur}
            value={FormProfil.values.degree}>
            <option className="text-bold" disabled defaultValue value=""> Séléctionnez un diplôme </option>
            <option value="Baccalauréat">Baccalauréat</option>
            <option value="BTS">BTS</option>
            <option value="Licence (Bac+3)">Licence (Bac+3)</option>
            <option value="Licence professionnelle">Licence professionnelle</option>
            <option value="Diplôme National de Technologie Spécialisé">Diplôme National de Technologie Spécialisé</option>
            <option value="Master">Master</option>
            <option value="Master 1 (Bac+4)">Master 1 (Bac+4)</option>
            <option value="Master 2 professionnel (Bac+5)">Master 2 professionnel (Bac+5)</option>
            <option value="Master 2 recherche (Bac+5)">Master 2 recherche (Bac+5)</option>
            <option value="Master 2 enseignement (Bac+5)">Master 2 enseignement (Bac+5)</option>
            <option value="Diplôme d'ingénieur (Bac+5)">Diplôme d'ingénieur (Bac+5)</option>
            <option value="Doctorat (recherche uniquement) (Bac+8)">Doctorat (recherche uniquement) (Bac+8)</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label className="required title-inscri">
            Nombre d'années d'expériences
          </Form.Label>
          <Form.Control
            className={ErrorClass(FormProfil, "numberyear")}
            type="text" name="numberyear" id="numberyear"
            placeholder="Saisir ton nombre d'années d'expérience"
            onChange={FormProfil.handleChange}
            value={FormProfil.values.numberyear}
            onBlur={FormProfil.handleBlur}
          />
          {ErrorFeild(FormProfil, "numberyear")}
        </Form.Group>
        <Form.Group>
          <Form.Label className="title-inscri"> Poste actuel </Form.Label>
          <Form.Control type="text" id="position" placeholder="Saisir ton poste actuel" name="position"
            onChange={FormProfil.handleChange}
            value={FormProfil.values.position}
            onBlur={FormProfil.handleBlur}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="title-inscri">Région</Form.Label>
          <Form.Select name="place" id="place"
            onChange={FormProfil.handleChange}
            onBlur={FormProfil.handleBlur}
            value={FormProfil.values.place}>
            <option className="text-bold text-black" value=""> Séléctionnez une région </option>
            {
              allRegions && allRegions.length
                ? allRegions.map((el, index) => {
                  return (
                    <option className="text-black" key={"option" + index} value={el.nom}>
                      {el.nom}
                    </option>
                  )
                })
                : null
            }
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label className="title-inscri">Tarif journalier</Form.Label>
          <Form.Control className={ErrorClass(FormProfil, "rate")}
            type="text" id="rate" placeholder="Saisir ton tarif" name="rate"
            onChange={FormProfil.handleChange}
            value={FormProfil.values.rate}
            onBlur={FormProfil.handleBlur}
          />
          {ErrorFeild(FormProfil, "rate")}
        </Form.Group>
        <Form.Group>
          <Form.Label className="title-inscri"> La dernière référence </Form.Label>
          <Form.Control
            type="text" id="reference"
            placeholder="Saisir le nom de ta dernière référence"
            name="reference"
            onChange={FormProfil.handleChange}
            value={FormProfil.values.reference}
            onBlur={FormProfil.handleBlur}
          />
          {ErrorFeild(FormProfil, "reference")}
        </Form.Group>
        <Form.Group>
          <Form.Label className="title-inscri"> Email dernière référence </Form.Label>
          <Form.Control type="email" id="ref" placeholder="Saisir l'email de ta dernière référence" name="ref"
            className={ErrorClass(FormProfil, "ref")}
            onChange={FormProfil.handleChange}
            value={FormProfil.values.ref}
            onBlur={FormProfil.handleBlur}
          />
          {ErrorFeild(FormProfil, "ref")}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          {FormProfil.values.terms}
          <div className="d-flex justify-content-start align-items-center">
            <Form.Check name="terms" type="checkbox"
              className={"title-Inscr" || ErrorClass(FormProfil, "terms")}
              onChange={FormProfil.handleChange}
              value={FormProfil.values.terms}
              checked={FormProfil.values.terms}
              onBlur={FormProfil.handleBlur}
            />
            <Form.Label className="title-inscri">
              <span>Tu dois accepter les </span>
              <span onClick={() => window.open("/terms", "_blank")} className="conditions-text">
                termes et conditions
              </span>
            </Form.Label>
          </div>
          {ErrorFeild(FormProfil, "terms")}
        </Form.Group>
        <Button variant="primary" type="submit" className="float-right button-next">
          Suivant
        </Button>
      </Form>
    );
  }

  /* last step */
  function StepThree() {
    return (
      <>
        <Formik>
          <Form onSubmit={FormSkills.handleSubmit}>
            <FieldArray name="skills"
              render={(arrayHelpers) =>
                FormSkills &&
                FormSkills.values.skills.length &&
                FormSkills.values.skills.map((skill, index) => {
                  return <ShowSkillsByType skill={skill} index={index} arrayHelpers={arrayHelpers} key={"ShowSkillsByType" + index} />
                })
              }
            />
            <Button variant="primary" type="submit" className="float-right button-next" >
              S'inscrire
            </Button>
          </Form>
        </Formik>
      </>
    );
  }

  /* Skills By Types */
  function ShowSkillsByType({ skill, index }) {
    return (
      <>
        <Form.Group className="mb-2 formGroup" key={"TypeSkill" + index}>
          {skill.typeSkill ?
            <Form.Label className="text-black">
              {skill.typeSkill}
            </Form.Label> : null}
          <Select isMulti isSearchable placeholder="Saisir une ou des compétence(s)"
            name={`skills[${index}].selectedSkills`}
            options={skill.options}
            closeMenuOnSelect={true}
            value={skill.selectedSkills}
            onChange={(event) => {
              const value = event.map((el) => {
                return { value: el.value, label: el.label, rating: (el.rating ? el.rating : 0) };
              });
              FormSkills.setFieldValue(`skills[${index}].selectedSkills`, value);
            }}
          />
        </Form.Group>
        <FlexboxPage skill={skill} index={index} />
      </>
    );
  }

  /* Rating By Element */
  function FlexboxPage({ skill, index }) {
    return (
      <Row className="mt-3 mb-3">
        {skill.selectedSkills.map((el, index1) => {
          return (
            <Col className="mb-2" lg={6} md={12} xs={12} key={"skillRate" + index1}>
              <div className="d-flex dropdown-skills p-1 mb-2">
                <div className="flex-grow-1 text-skills align-self-center">
                  {el.label}
                </div>
                <div className="align-self-center">
                  <Rating
                    name={`skills[${index}].selectedSkills[${index1}].rating`}
                    value={el.rating}
                    defaultValue={0}
                    precision={0.5}
                    className="rating-style"
                    onChange={(event, value) => {
                      FormSkills.setFieldValue(`skills[${index}].selectedSkills[${index1}].rating`, value);
                    }}
                  />
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }

  /* steps display */
  return (
    <>
      {/* Alert */}
      <AlertModal show={show} setShow={setShow} content="Merci pour ta demande, ton compte est en attente d'activation de la part de l'administrateur de la plateforme" />
      <Progress />
      <div className="stepform">
        {step === 1 ? StepOne() : step === 2 ? StepTwo() : step === 3 ? StepThree() : null}
      </div>
    </>
  )
}