import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaRegCalendarMinus, FaMapMarkerAlt } from "react-icons/fa";
import { Context as UserContext } from "../../../context/reducers/CosmonauteReducer";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import { formatDate, getPeriode } from "../../../services/Controller/DateFormatter";
import "../../Recruiter/DetailMission/DetailMissionRecruiter.scss";
import { Rating } from "@mui/material";
/* Detail Mission Page */
function DetailMissionUser() {
  /* use History */
  const history = useHistory();

  /* use Location */
  const location = useLocation();

  /* Context API */
  const { getOneMissionUser, hideToast, state } = useContext(UserContext);

  /* Mission Detail */
  const [oneMission, setOneMission] = useState(null);

  /* eslint-disable */
  useEffect(() => {
    const id = location.pathname.split("/")[3];
    getOneMissionUser(id);
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (state.oneMission) setOneMission(state.oneMission);
  }, [state]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* return back to list missions */
  function returnBack() {
    history.goBack();
  }
  /* innher HTML */
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <>
      <div id="header-Content" className="header-Content"></div>
      {/* Toast */}
      <ToastService data={state.toastContent} />

      {/* Mission */}
      <div id="main-without-header" className="main-without-header">
        {/* Mission Information */}
        <div className="arrow-back-mission pointer mb-3" onClick={returnBack}>
          <i className="icon-back-arrow"></i>
        </div>
        <div className="periode mb-1">
          Publié il y a{" "}
          {oneMission && oneMission.created_at
            ? getPeriode(oneMission?.created_at).periode +
            " " +
            getPeriode(oneMission?.created_at).type
            : "- - -"}
        </div>
        <div className="title-page">
          <span className="mr-3">{oneMission && oneMission.title ? oneMission.title : "- - -"}</span>
          {
            oneMission && oneMission.renewable
              ? <span className="text-detail ml-5">({oneMission.renewable})</span>
              : null
          }
        </div>
        <div className="sub-title-page mb-1">
          {oneMission && oneMission.nbr_years_experience
            ? oneMission.nbr_years_experience
            : 0}{" "}
          ans d'expriences
        </div>
        <div className="text-detail d-flex mb-2">
          <FaRegCalendarMinus />
          <span className="ml-10 mr-20">
            Du{" "}
            {oneMission && oneMission.date_start
              ? formatDate(oneMission.date_start)
              : "- - -"}
            {" "} Au {" "}
            {oneMission && oneMission.date_end
              ? formatDate(oneMission.date_end)
              : "- - -"}
          </span>
        </div>
        <div className="text-detail d-flex">
          <FaMapMarkerAlt />
          <span className="ml-10 mr-20">
            {" "}
            {oneMission && oneMission.place ? oneMission.place : "- - -"}{" "}
          </span>
        </div>

        {/* Mission Details */}
        <div className="border-top mt-5 mb-3"></div>
        <div className="title-detail mb-2">Description du poste</div>
        {oneMission && oneMission.description ? (
          <div
            className="color-black"
            dangerouslySetInnerHTML={createMarkup(oneMission.description)}
          />
        ) : (
          <NothingToShow />
        )}
        <strong className="color-black mb-2">Compétences obligatoires</strong>
        {oneMission?.skills && oneMission.skills?.length ? (
          <>
            {oneMission.skills
              .filter((item) => item.priority === 1)
              .map((typeSkill, key) => {
                return (
                  <div key={key} className="margin-bottom-skills">
                    <div className="d-flex">
                      <label className="text-skill-label mr-10">
                        {typeSkill.name ? typeSkill.name : "- - -"}
                      </label>
                      <Rating
                        value={typeSkill.rating ? typeSkill.rating : 0}
                        className="rating-style"
                        disabled
                      />
                    </div>
                    )
                  </div>
                );
              })}
          </>
        ) : (
          <p className="text-skill-label">
            Pas de résultat à afficher{" "}
          </p>
        )}
        <strong className="color-black mb-2">
          Compétences supplémentaires
        </strong>
        {oneMission?.skills && oneMission.skills?.length ? (
          <>
            {oneMission.skills
              .filter((item) => item.priority === 0)
              .map((typeSkill, key) => {
                return (
                  <div className="margin-bottom-skills" key={key}>
                    <div className="d-flex">
                      <label className="text-skill-label mr-10">
                        {typeSkill.name ? typeSkill.name : "- - -"}
                      </label>
                      <Rating
                        value={typeSkill.rating ? typeSkill.rating : 0}
                        className="rating-style"
                        disabled
                      />
                    </div>
                    )
                  </div>
                );
              })}
          </>
        ) : (
          <p className="text-skill-label">
            Pas de résultat à afficher{" "}
          </p>
        )}
      </div>
    </>
  );
}

/* Export Detail Mission component */
export default DetailMissionUser;
