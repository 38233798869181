import React, {useContext, useEffect, useState} from 'react';
import './AnnexeAdmin.scss';
import {Tabs, Tab, Table, Modal, Form, Button, Row, Col} from "react-bootstrap";
import {FaEdit, FaTrashAlt, FaSearch} from 'react-icons/fa';
import {Context as AdministratorContext} from '../../../context/reducers/AdminReducer';
import ToastService from '../../../components/Toast/ToastService';
import NothingToShow from '../../../components/NothingToShow/NothingToShow';
import ModalConfirm from '../../../components/ModalConfirm/ModalConfirm';
import {ErrorFeild, ErrorClass} from '../../../services/Controller/ValidationForm';
import * as Yup from "yup";
import {useFormik} from 'formik';
import ReactTooltip from 'react-tooltip';
import Paginate from '../../../components/Paginate/Paginate';

/* Validation Form Group */
const validationFormGroup = Yup.object({
    name: Yup.string().required('Champ obligatoire'),
});

/* Annexe Page */
function AnnexeAdmin() {
    /* Context Api */
    const {
        getAllSkills, addSkill, editSkill, deleteSkill, dumpSkill,
        getAllTypeSkills, addTypeSkill, editTypeSkill, deleteTypeSkill, dumpTypeSkill,
        getAllDomainsRecruiter, addDomainsRecruiter, editDomainsRecruiter, deleteDomainsRecruiter, dumpDomainRecruiter,
        hideToast, state
    } = useContext(AdministratorContext);

    /* Condidatures lists */
    const [allSkills, setAllSkills] = useState([]);
    const [allTypeSkills, setAllTypeSkills] = useState([]);
    const [allDomainsRecruiters, setAllDomainsRecruiters] = useState([]);

    /* Modals */
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    /* Tab Active Value */
    const [defaultTabValue, setDefaultTabValue] = useState("Skills");

    /* filter Input */
    const [filterValue, setFilterValue] = useState("");

    /* Table Content */
    const [displayedColumns, setDisplayedColumns] = useState(["Libellé", "Type", ""]);
    const [displayedData, setDisplayedData] = useState([]);
    const [currentItems, setCurrentItems] = useState([]);

    /* eslint-disable */
    useEffect(() => {
        getAllSkills();
        getAllTypeSkills();
        getAllDomainsRecruiter();
    }, []);
    /* eslint-enable */

    useEffect(() => {
        if (state.allSkills) {
            setAllSkills(state.allSkills);
            if (defaultTabValue === "Skills") setDisplayedData(state.allSkills);
        }
        if (state.allTypeSkills) {
            setAllTypeSkills(state.allTypeSkills);
            if (defaultTabValue === "TypeSkills") setDisplayedData(state.allTypeSkills);
        }
        if (state.allDomainsRecruiters) {
            setAllDomainsRecruiters(state.allDomainsRecruiters);
            if (defaultTabValue === "DomainRecruiter") setDisplayedData(state.allDomainsRecruiters);
        }
    }, [state, defaultTabValue]);


    /* Refresh list Skills After Add | Edit | Delete */
    useEffect(() => {
        if (state.skill) {
            setFilterValue("");
            dumpSkill();
            getAllSkills();
        }
    }, [dumpSkill, getAllSkills, state.skill]);

    /* Refresh list Types Skills After Add | Edit | Delete */
    useEffect(() => {
        if (state.typeSkill) {
            setFilterValue("");
            dumpTypeSkill();
            getAllTypeSkills();
        }
    }, [dumpTypeSkill, getAllTypeSkills, state.typeSkill]);

    /* Refresh list Domain Recruiter After Add | Edit | Delete */
    useEffect(() => {
        if (state.domainrecruiter) {
            setFilterValue("");
            dumpDomainRecruiter();
            getAllDomainsRecruiter();
        }
    }, [dumpDomainRecruiter, getAllDomainsRecruiter, state.domainrecruiter]);
    /* Hide Alert */
    useEffect(() => {
        if (state.toastContent) hideToast();
    }, [hideToast, state.toastContent]);

    /* Content Annexe List */
    function showAnnexeContent() {
        return (
            <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
                <Table responsive="sm md" className="w-100">
                    <thead>
                    <tr className="table-Header">
                        {
                            displayedColumns.map((el, index) => {
                                return (<th key={"Column" + index}>{el}</th>)
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {displayedData && displayedData.length ? getList() : null}
                    </tbody>
                </Table>
                {
                    !displayedData || !displayedData.length
                        ? <NothingToShow type="array"/>
                        : <Paginate items={displayedData} setCurrentItems={setCurrentItems} itemsPerPage={10}/>
                }
            </div>
        )
    }

    /* listings */
    function getList() {
        return (
            currentItems.map((el, index) => {
                return (
                    <tr className="table-content table-padding" key={defaultTabValue + index}>
                        <td className="w-250">
                            <p>{el.name ? el.name : "- - -"}</p>
                        </td>
                        {
                            defaultTabValue === "Skills"
                                ? <td className="w-250">
                                    <p>{el.nameType ? el.nameType : "- - -"}</p>
                                </td>
                                : null
                        }
                        <td>
                            <div className="float-right pointer d-flex">
                                {/* Tooltip */}
                                <ReactTooltip id="Consult" place="bottom"> Modifier </ReactTooltip>
                                <ReactTooltip id="Delete" place="bottom"> Supprimer </ReactTooltip>
                                <span className="btn-detail blue" onClick={() => {
                                    EditItem(el)
                                }}>
                  < FaEdit data-tip data-for="Consult" className="mr-20"/>
                </span>
                                <span className="btn-detail red" onClick={() => {
                                    setSelectedItem(el.id);
                                    setShow(true);
                                }}>
                  < FaTrashAlt data-tip data-for="Delete"/>
                </span>
                            </div>
                        </td>
                    </tr>
                )
            })
        )
    }

    /* Add Skill | Type Skill | Domain Recruiter */
    function AddItem() {
        setSelectedItem(null);
        FormGroup.resetForm();
        setModalShow(true);
    }

    /* Edit Skill | Type Skill | Domain Recruiter */
    function EditItem(el) {
        setSelectedItem(el.id);
        FormGroup.setFieldValue("name", el.name);
        if (defaultTabValue === "Skills") FormGroup.setFieldValue("type", el.idType);
        setModalShow(true);
    }

    /* Delete Skill | Type Skill | Domain Recruiter */
    function DeleteItem() {
        if (defaultTabValue === "Skills") deleteSkill(selectedItem);
        else if (defaultTabValue === "TypeSkills") deleteTypeSkill(selectedItem);
        else deleteDomainsRecruiter(selectedItem);
    }

    /* Form Group */
    const FormGroup = useFormik({
        enableReinitialize: true,
        initialValues: defaultTabValue === "Skills"
            ? {name: '', type: (allTypeSkills && allTypeSkills.length) ? allTypeSkills[0].id : null}
            : {name: ''},
        validationSchema: validationFormGroup,
        onSubmit: values => {
            if (!selectedItem) {
                if (defaultTabValue === "Skills") addSkill(values);
                else if (defaultTabValue === "TypeSkills") addTypeSkill(values);
                else addDomainsRecruiter(values);
            } else {
                if (defaultTabValue === "Skills") editSkill(values, selectedItem);
                else if (defaultTabValue === "TypeSkills") editTypeSkill(values, selectedItem);
                else editDomainsRecruiter(values, selectedItem);
            }
            setModalShow(false);
        }
    })

    /* Open Modal */
    function openModal() {
        return (
            <Modal show={modalShow} onHide={() => {
                setModalShow(false)
            }} size="md" centered>
                <Form onSubmit={FormGroup.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ajouter {defaultTabValue === "Skills" ? "une compétence" : defaultTabValue === "TypeSkills" ? "un type de compétence" : "un domaine d'activité"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label className="text-Label-modal"> Libellé </Form.Label>
                            <Form.Control className={ErrorClass(FormGroup, 'name')} type="text" name="name" id="name"
                                          placeholder={(defaultTabValue === "Skills" ? "Compétence" : defaultTabValue === "TypeSkills" ? "Type Compétences" : "Domaine d'activité")}
                                          onChange={FormGroup.handleChange}
                                          onBlur={FormGroup.handleBlur}
                                          value={FormGroup.values.name}/>
                            {ErrorFeild(FormGroup, 'name')}
                        </Form.Group>
                        {
                            defaultTabValue === "Skills"
                                ? <Form.Group>
                                    <Form.Label className="text-Label-modal"> Type </Form.Label>
                                    <Form.Select className={ErrorClass(FormGroup, 'type')} name="type" id="type"
                                                 onChange={FormGroup.handleChange}
                                                 onBlur={FormGroup.handleBlur}
                                                 value={FormGroup.values.type}>
                                        {
                                            allTypeSkills && allTypeSkills.length
                                                ? allTypeSkills.map((el, index) => {
                                                    return (
                                                        <option className="color-black" key={"option" + index}
                                                                value={el.id}>{el.name}</option>
                                                    )
                                                })
                                                : null
                                        }
                                    </Form.Select>
                                </Form.Group>
                                : null
                        }
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button type="submit" className="save-button green shadow-unset">Enregistrer</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    /* Tabulation onChange */
    function changeTabulation(tab) {
        setFilterValue("");
        if (tab === "Skills") {
            setDisplayedColumns(["Libellé", "Type", ""]);
            setDisplayedData(allSkills);
        } else {
            setDisplayedColumns(["Libellé", ""]);
            if (tab === "TypeSkills") setDisplayedData(allTypeSkills);
            else setDisplayedData(allDomainsRecruiters);
        }
        setDefaultTabValue(tab);
    }

    /* filter List */
    function filterList(event) {
        const value = event.target.value.toLowerCase();
        setFilterValue(value);
        const AllList = defaultTabValue === "Skills" ? allSkills : defaultTabValue === "TypeSkills" ? allTypeSkills : allDomainsRecruiters
        const list = AllList.filter((el) => value === "" || el.name?.toLowerCase()?.indexOf(value) !== -1)
        setDisplayedData(list);
    }

    return (
        <>
            {/* Toast */}
            <ToastService data={state.toastContent}/>
            <ModalConfirm show={show} setShow={setShow}
                          message={"Vous êtes sûr de vouloir supprimer ce " + (defaultTabValue === "Skills" ? "Compétence" : defaultTabValue === "TypeSkills" ? "type Compétences" : "domaine recruteur") + " ?"}
                          setSelectedItem={setSelectedItem} Action={DeleteItem}/>
            <div id="main-without-header" className="main-without-header">
                <Row>
                    <Col lg={6} md={12} xs={12}>
                        <label className="title-page">Annexe</label>
                    </Col>
                    <Col lg={3} md={12} xs={12}>
                        <Form>
                            <Form.Group className="position-relative">
                                <FaSearch className="position-absolute searsh-Input"/>
                                <Form.Control type="text" placeholder="Rechercher"
                                                onChange={(e) => filterList(e)} value={filterValue}/>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col lg={3} md={12} xs={12}>
                        <Button onClick={AddItem}
                                className={"float-right save-button green shadow-unset"}>
                            Ajouter {defaultTabValue === "Skills" ? "une compétence" : defaultTabValue === "TypeSkills" ? "un type de compétence" : "un domaine d’activité"}
                        </Button>
                    </Col>
                </Row>
                <Tabs activeKey={defaultTabValue} onSelect={(k) => changeTabulation(k)} className="mt-3 mb-3 w-100">
                    <Tab eventKey="Skills" title={defaultTabValue === "Skills" ? "Compétences (" + (displayedData ? displayedData.length : 0) + ")" :"Compétences (" + (allSkills ? allSkills.length : 0) + ")"}>
                        {showAnnexeContent()}
                    </Tab>
                    <Tab eventKey="TypeSkills"
                         title={defaultTabValue === "TypeSkills" ? "Types de compétence (" + (displayedData ? displayedData.length : 0) + ")" : "Types de compétence (" + (allTypeSkills ? allTypeSkills.length : 0) + ")"}>
                        {showAnnexeContent()}
                    </Tab>
                    <Tab eventKey="DomainRecruiter"
                         title={defaultTabValue === "DomainRecruiter" ? "Domaines d’activité (" + (displayedData ? displayedData.length : 0) + ")":"Domaines d’activité (" + (allDomainsRecruiters ? allDomainsRecruiters.length : 0) + ")"}>
                        {showAnnexeContent()}
                    </Tab>
                </Tabs>
            </div>
            {/* Modal */}
            {openModal()}
        </>
    );
};

/* Export Annexe component */
export default AnnexeAdmin;
