import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";
import Header from "../../components/Header/Header";
import InscriptionForm from "../../components/Inscription/InscriptionForm";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import overlay from "../../assets/img/inscription/bg-overlay-section3.png";
import overlay1 from "../../assets/img/inscription/bg-overlay-section6.png";
import "./DevenirInspirnaute.scss";
import { Link } from "react-router-dom";

/* Inspirnaute */
export default function DevenirInpirnaute() {
  const { t } = useTranslation();
  /* */
  const scrollToSection = () => {
    scroller.scrollTo("active", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      {/* Header section */}
      <section className="bg-inspirnaute-sec1 pb-4" id="inscription1">
        <Header />
        <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="mt-15"
        >
          <p className="slogan-first-talent text-transparent text-uppercase text-center roboto-black">
            {t("inscription.section1.talent1")}
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="mt-5"
        >
          <p className="slogan-talent text-uppercase text-center">
            {t("inscription.section1.talent2")}
          </p>
        </ScrollAnimation>
        <div className="description-section text-center">
          {t("inscription.section1.description")}
        </div>
        <div className="description-section text-center">
          {t("inscription.section1.description1")}
        </div>
        <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="padding-button-inscrit"
        >
          <div className="d-flex justify-content-center">
            <Button
              onClick={scrollToSection}
              variant="secondary"
              type="button"
              className="text-center roboto-bold p-button"
            >
              {t("inscription.section1.button")}
            </Button>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="tada" >
          <div className="scroll-downs mt-5 ">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </ScrollAnimation>
        <div className="overlay-sections1"></div>
      </section>
      <section className="bg-inspirnaute-sec2" id="inscription2">
        <div className="overlay-section2"></div>
        <div>
          <p className="title-section2 text-uppercase">
            {t("inscription.section2.title")}
          </p>
        </div>
        <div className="text-center description-section">
          {t("inscription.section2.description1")}
        </div>
        <div className="text-center description-section">
          {t("inscription.section2.description2")}
        </div>
        <div className="text-center description-section">
          {t("inscription.section2.description3")}
        </div>
        <div className="d-flex justify-content-center">
          <h1 className="section2-h1 roboto-black">
            {t("inscription.section2.title1")}
          </h1>
        </div>
        <div className="description2">
          {t("inscription.section2.desc1")}
        </div>
        <div className="description2">
          {t("inscription.section2.desc2")}
        </div>
      </section>
      <section className="bg-inspirnaute-section2" id="inscription3">
        <div className="slogan-first-planetes text-transparent text-uppercase text-center roboto-black" >
          <div>{t("inscription.section3.title1")}</div>
          <div>{t("inscription.section3.title2")}</div>
        </div>
      </section>
      <section className="bg-inspirnaute-sec3" id="inscription4">
        <img alt="overlay" src={overlay} className="overlay-section3"></img>
        <p className="mission-sesction3 mb-5">
          {t("inscription.section3.mission")}
        </p>
      </section>
      <section className="bg-inspirnaute-sec4" id="inscription5">
        <div className="overlay-section4"></div>
        <div className="overlay-section-bg4"></div>
        <div className="description-section5">
          <div>
            {t("inscription.section4.text")}
          </div>
          <div>
            {t("inscription.section4.text1")}
          </div>
          <div>
            {t("inscription.section4.text2")}
          </div>
        </div>
      </section>
      <section className="bg-inspirnaute-sec5 " id="inscription6">
        <div className="overlay-section5 animate-3"></div>
        <div className="title-section5 text-uppercase roboto-condensed-bold" >
          <div>{t("inscription.section5.title")}</div>
          <div>{t("inscription.section5.title1")}</div>
          <div>{t("inscription.section5.title2")}</div>
          <div>{t("inscription.section5.title3")}</div>
        </div>
        <div className="description-section m-left-10">
          <div>{t("inscription.section5.description")}</div>
          <div>{t("inscription.section5.description1")}</div>
          <div>{t("inscription.section5.description2")}</div>
        </div>
      </section>
      <section className="bg-inspirnaute-sec6" id="inscription7">
        {/* <div className="overlay-section6"></div> */}
        <img alt="overlay" src={overlay1} className="overlay-section6 animate-3"></img>
        <div className="description-section6">
          <div >
            {t("inscription.section6.title")}
          </div>
          <div >
            {t("inscription.section6.title1")}
          </div>
          <div >
            {t("inscription.section6.title2")}
          </div>
        </div>

        <div className="description-cosmonaute m-left-15 ">
          {t("inscription.section6.text")}
        </div>
        <div className="description-cosmonaute m-left-15 ">
          {t("inscription.section6.text1")}
        </div>
        <div className="description-cosmonaute m-left-15 ">
          {t("inscription.section6.text2")}
        </div>
        <div className="overlay-section-bg-6"></div>
        <div className="overlay-section-rocks"></div>
      </section>
      <section className="bg-inspirnaute-sec7" id="inscription8">
        <div className="title-partenariat text-transparent">
          {t("inscription.section7.title")}
        </div>
        <div className="description-inscription8">
          {t("inscription.section7.h1")}
        </div>
        <div className="overlay-section8"></div>
      </section>
      {/*  */}
      <section className="bg-inspirnaute-sec8 pb-5" id="inscription9">
        <div className="title-section8 text-transparent">
          <div>{t("inscription.section8.titre")}</div>
          <div>{t("inscription.section8.titre1")}</div>
        </div>
        <InscriptionForm className="pb-5" />
      </section>
      {/* contact Section */}
      <section className="bg-inspirnaute-sec9">
        <div className="etoile-propos"></div>
        <div className="overlay-sections6"></div>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-uppercase fontSize-178 yellow-color float-left text-spacing-7">
                {t("home.univers")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold ml-big text-uppercase fontSize-178 yellow-color text-spacing-3 infini">
                {t("home.infini")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                {t("home.contact")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-center fontSize-40 mb-5">
                {t("home.adress")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 mb-5">
                {t("home.phone")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 ">
                {t("home.mail")}
              </p>
              <p className="roboto-condensed text-center fontSize-20  notice">
                <Link to="/notice">{t("home.mention")}</Link>
              </p>
            </ScrollAnimation>
          </Col>
        </Row>
      </section>
    </div>
  );
}
