import jwtDecode from "jwt-decode";

/* get Current Current User */
export const decodeToken = (token) => {
    return token ? jwtDecode(token) : null;
}

/* get Current Current User */
export const getUser = () => {
    const token = localStorage.getItem("token");
    return token ? jwtDecode(token) : null;
}
/* get Id Current User */
export const getUserId = () => {
    const user = getUser();
    return user?.id;
}
/* get Roles Current User */
export const getUserRole = () => {
    const user = getUser();
    return user?.roles;
}
/* get Roles Current User */
export const getUserEmail = () => {
    const user = getUser();
    return user?.username;
}

/*  User is Admin */
export const isAdmin = () => {
    const Roles = getUserRole();
    return Roles ? Roles[0] === 'ROLE_ADMIN' ? true : false : null;
}

/*  User is Recruiter */
export const isRecruiter = () => {
    const Roles = getUserRole();
    return Roles ? Roles[0] === 'ROLE_RECR' ? true : false : null;
}

/*  User is Cosmonaute */
export const isCosmonaute = () => {
    const Roles = getUserRole();
    return Roles ? Roles[0] === 'ROLE_USER' ? true : false : null;
}