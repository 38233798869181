import React, { useReducer } from 'react';
import { SHOW_TOAST, HIDE_TOAST } from '../constants/Toast';
import { hideToast } from '../actions/Toast';
import {
    // Recruiters
    ALL_RECRUITERS_ADMIN, ONE_RECRUITER_ADMIN,
    // Inspirnautes
    ALL_INSPIRNAUTES_ADMIN, ONE_INSPIRNAUTE_ADMIN,
    // Candidatures
    ALL_CANDIDATURES_RECRUITERS_ADMIN, ALL_CANDIDATURES_CANDIDATES_ADMIN, VALIDATE_CANDIDATURE, DUMP_CANDIDATURE,
    // Skills
    ALL_SKILLS_ADMIN, ADD_SKILL_ADMIN, EDIT_SKILL_ADMIN, DELETE_SKILL_ADMIN, DUMP_SKILL,
    // Type Skills
    ALL_TYPE_SKILLS_ADMIN, ADD_TYPE_SKILL_ADMIN, EDIT_TYPE_SKILL_ADMIN, DELETE_TYPE_SKILL_ADMIN, DUMP_TYPE_SKILL,
    // Domain Recruiters
    ALL_DOMAIN_RECRUITER_ADMIN, ADD_DOMAIN_RECRUITER_ADMIN, EDIT_DOMAIN_RECRUITER_ADMIN, DELETE_DOMAIN_RECRUITER_ADMIN, DUMP_DOMAIN_RECRUITER_ADMIN,
    // MISSIONS
    ALL_MISSIONS_ADMIN, ONE_MISSION_ADMIN, ADD_MISSION_ADMIN, DELETE_MISSION_ADMIN, INTERRUPT_MISSION_ADMIN, PROFILES_MISSION_ADMIN, DUMP_MISSION_ADMIN,
    // Matching Profiles
    MATCH_PROFIL_MISSION_ADMIN, REMOVE_PROFIL_MISSION_ADMIN, DUMP_PROFIL_MISSION_ADMIN,
    // FOLLOW UP
    FOLLOWUP_MISSIONS_ADMINISTRATOR, FOLLOWUP_INTERVIEWS_ADMINISTRATOR
} from '../constants/Administrator';
import { getAllRecruiters, getOneRecruiter, } from '../actions/RecruitersAdministrator';
import { getAllInspirnautes, getOneInspirnaute } from '../actions/InspirnautesAdministrator';
import { getAllRecruitersCandidatures, getAllProfilsCandidatures, validateCandidature, dumpCandidature } from '../actions/CandidaturesAdministrator';
import { getAllSkills, addSkill, editSkill, deleteSkill, dumpSkill } from '../actions/SkillsAdministrator';
import { getAllTypeSkills, addTypeSkill, editTypeSkill, deleteTypeSkill, dumpTypeSkill } from '../actions/TypeSkillsAdministrator';
import { getAllDomainsRecruiter, addDomainsRecruiter, editDomainsRecruiter, deleteDomainsRecruiter, dumpDomainRecruiter } from '../actions/DomainRecruiterAdministrator';
import {
    getAllMissionsAdmin, getOneMission, deleteMission, interruptMission, dumpMission,
    getProfileMatchingList, affectProfileToMission, removeProfileFromMission, dumpProfile
} from '../actions/MissionsAdministrator';
import { getAllMissionsFollowUP, getAllInterviewsFollowUP } from '../actions/FollowUpAdministrator';
import {getCandidateByDay, getMissionByDay, getMissionStats, getUsersStats, getTopRecruiter} from "../actions/DashboardAdmin";
import {MISSION_BY_DAY_STATS, MISSION_STATS, CANDIDATE_BY_DAY_STATS, USERS_STATS, TOP_RECRUITERS} from "../constants/Administrator";
/* Admin Reducer */
const AdminReducer = (state, action) => {
    switch (action.type) {
        /* Recruiters */
        case ALL_RECRUITERS_ADMIN:
            return { ...state, allRecruiters: action.payload, toastContent: action.toastContent };
        case ONE_RECRUITER_ADMIN:
            return { ...state, oneRecruiter: action.payload, toastContent: action.toastContent };
        /* Inspirnautes */
        case ALL_INSPIRNAUTES_ADMIN:
            return { ...state, allInspirnautes: action.payload, toastContent: action.toastContent };
        case ONE_INSPIRNAUTE_ADMIN:
            return { ...state, oneInspirnaute: action.payload, toastContent: action.toastContent };
        /* Candidatures */
        case ALL_CANDIDATURES_RECRUITERS_ADMIN:
            return { ...state, allRecruitersCandidatures: action.payload, toastContent: action.toastContent };
        case ALL_CANDIDATURES_CANDIDATES_ADMIN:
            return { ...state, allProfilsCandidatures: action.payload, toastContent: action.toastContent };
        case VALIDATE_CANDIDATURE:
            return { ...state, candidature: action.payload, toastContent: action.toastContent };
        case DUMP_CANDIDATURE:
            return { ...state, candidature: null, toastContent: null };
        /* SKILLS */
        case ALL_SKILLS_ADMIN:
            return { ...state, allSkills: action.payload, toastContent: action.toastContent };
        case ADD_SKILL_ADMIN:
            return { ...state, skill: action.payload, toastContent: action.toastContent };
        case EDIT_SKILL_ADMIN:
            return { ...state, skill: action.payload, toastContent: action.toastContent };
        case DELETE_SKILL_ADMIN:
            return { ...state, skill: action.payload, toastContent: action.toastContent };
        case DUMP_SKILL:
            return { ...state, skill: null, toastContent: null };
        /* TYPE SKILLS */
        case ALL_TYPE_SKILLS_ADMIN:
            return { ...state, allTypeSkills: action.payload, toastContent: action.toastContent };
        case ADD_TYPE_SKILL_ADMIN:
            return { ...state, typeSkill: action.payload, toastContent: action.toastContent };
        case EDIT_TYPE_SKILL_ADMIN:
            return { ...state, typeSkill: action.payload, toastContent: action.toastContent };
        case DELETE_TYPE_SKILL_ADMIN:
            return { ...state, typeSkill: action.payload, toastContent: action.toastContent };
        case DUMP_TYPE_SKILL:
            return { ...state, typeSkill: null, toastContent: null };
        /* DOMAIN RECRUITERS */
        case ALL_DOMAIN_RECRUITER_ADMIN:
            return { ...state, allDomainsRecruiters: action.payload, toastContent: action.toastContent };
        case ADD_DOMAIN_RECRUITER_ADMIN:
            return { ...state, domainrecruiter: action.payload, toastContent: action.toastContent };
        case EDIT_DOMAIN_RECRUITER_ADMIN:
            return { ...state, domainrecruiter: action.payload, toastContent: action.toastContent };
        case DELETE_DOMAIN_RECRUITER_ADMIN:
            return { ...state, domainrecruiter: action.payload, toastContent: action.toastContent };
        case DUMP_DOMAIN_RECRUITER_ADMIN:
            return { ...state, domainrecruiter: null, toastContent: null };
        /* MISSIONS */
        case ALL_MISSIONS_ADMIN:
            return { ...state, allMissions: action.payload, toastContent: null };
        case ONE_MISSION_ADMIN:
            return { ...state, oneMission: action.payload, toastContent: null };
        case ADD_MISSION_ADMIN:
            return { ...state, mission: action.payload, toastContent: action.toastContent };
        case DELETE_MISSION_ADMIN:
            return { ...state, mission: action.payload, toastContent: action.toastContent };
        case INTERRUPT_MISSION_ADMIN:
            return { ...state, mission: action.payload, toastContent: action.toastContent };
        case DUMP_MISSION_ADMIN:
            return { ...state, mission: null, toastContent: null };
        /* MATCHING PROFILES */
        case PROFILES_MISSION_ADMIN:
            return { ...state, listProfilMatching: action.payload, toastContent: null };
        case MATCH_PROFIL_MISSION_ADMIN:
            return { ...state, profilMission: action.payload, toastContent: action.toastContent };
        case REMOVE_PROFIL_MISSION_ADMIN:
            return { ...state, profilMission: action.payload, toastContent: action.toastContent };
        case DUMP_PROFIL_MISSION_ADMIN:
            return { ...state, profilMission: null, toastContent: null };
        /* FOLLOW UP PROFILES */
        case FOLLOWUP_MISSIONS_ADMINISTRATOR:
            return { ...state, followUpMissions: action.payload, toastContent: null };
        case FOLLOWUP_INTERVIEWS_ADMINISTRATOR:
            return { ...state, followUpInterviews: action.payload, toastContent: null };
        /* Dashboard*/
        case USERS_STATS:
            return { ...state, UsersStats: action.payload, toastContent: null };
        case MISSION_STATS:
            return { ...state, MissionStats: action.payload, toastContent: null };
        case MISSION_BY_DAY_STATS:
            return { ...state, MissionByDay: action.payload, toastContent: null };
        case CANDIDATE_BY_DAY_STATS:
            return { ...state, CondidateByDay: action.payload, toastContent: null };
        case TOP_RECRUITERS:
            return { ...state, TopRecruiter: action.payload, toastContent: null };
        /* Show | Hide Toast */
        case SHOW_TOAST:
            return { ...state, toastContent: action.toastContent };
        case HIDE_TOAST:
            return { ...state, toastContent: null };
        default:
            return state;
    }
};
/* Admin State */
const AdminState = (reducer, actions, defaultValue) => {
    const Context = React.createContext();
    const Provider = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, defaultValue);

        const boundActions = {};
        for (let key in actions) {
            boundActions[key] = actions[key](dispatch);
        }

        return (
            <Context.Provider value={{ state, ...boundActions }}>
                {children}
            </Context.Provider>
        );
    };
    return { Context, Provider };
};
/* Admin Provider Context */
export const { Provider, Context } = AdminState(
    AdminReducer,
    {
        hideToast,
        getAllRecruiters, getOneRecruiter,
        getAllInspirnautes, getOneInspirnaute,
        getAllRecruitersCandidatures, getAllProfilsCandidatures, validateCandidature, dumpCandidature,
        getAllSkills, addSkill, editSkill, deleteSkill, dumpSkill,
        getAllTypeSkills, addTypeSkill, editTypeSkill, deleteTypeSkill, dumpTypeSkill,
        getAllDomainsRecruiter, addDomainsRecruiter, editDomainsRecruiter, deleteDomainsRecruiter, dumpDomainRecruiter,
        getAllMissionsAdmin, getOneMission, deleteMission, interruptMission, getProfileMatchingList, dumpMission,
        affectProfileToMission, removeProfileFromMission, dumpProfile,
        getAllMissionsFollowUP, getAllInterviewsFollowUP,getUsersStats,getMissionStats,getMissionByDay,getCandidateByDay,getTopRecruiter
    },
    {
        toastContent: null,
        allRecruiters: null, oneRecruiter: null,
        allInspirnautes: null, oneInspirnaute: null,
        allRecruitersCandidatures: null, allProfilsCandidatures: null, candidature: null,
        allSkills: null, skill: null,
        allTypeSkills: null, typeSkill: null,
        allDomainsRecruiters: null, domainrecruiter: null,
        allMissions: null, mission: null, oneMission: null, listProfilMatching: null, profilMission: null,
        followUpMissions: null, followUpInterviews: null,UsersStats:null,MissionStats:null,MissionByDay:null,CandidateByDay:null,TopRecruiter:null
    }
);
