import axios from "axios";
import { USER_SIGNOUT } from '../constants/Login'

const signout = (dispatch) => async (history) => {
    await localStorage.removeItem('token');
    await localStorage.removeItem('refresh_token');
    await localStorage.removeItem('currentUser');
    delete axios.defaults.headers['Authorization'];

    dispatch({ type: USER_SIGNOUT });
    await history.push('/');
}


export { signout };

