import React, { useContext, useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import Rating from "@mui/material/Rating";
import { useFormik, FieldArray, Formik } from "formik";
import { Context as CosmonauteContext } from "../../../../../context/reducers/CosmonauteReducer";

export default function SkillsCandidate({ cosmonauteSkills, id }) {
    /* Context *Api */
    const { getListSkills, saveSkillsCandidte, state } = useContext(CosmonauteContext);

    /* States */
    const [initialSkills, setInitialSkills] = useState([]);
    /* eslint-disable */
    useEffect(() => {
        getListSkills();
    }, []);
    /* eslint-enable */

    useEffect(() => {
        if (state.allSkills) {
            const list = state.allSkills;
            let initialValues = [];
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                initialValues.push(
                    {
                        typeSkill: element.name,
                        options: element.skills
                            ? element.skills.map((item) => { return { value: item.id, label: item.name } })
                            : [],
                        selectedSkills: cosmonauteSkills
                            ?
                            cosmonauteSkills.find((item) => item.type === element.name)
                                ? cosmonauteSkills.find((item) => item.type === element.name).skills
                                    .map((el) => {
                                        return {
                                            value: el.id,
                                            label: el.skill,
                                            rating: el.rating,
                                        }
                                    })
                                : []
                            : []

                    }
                )

            }

            setInitialSkills(initialValues);
        }
    }, [state.allSkills, cosmonauteSkills]);

    /* Form Skills */
    const FormSkills = useFormik({
        enableReinitialize: true,
        initialValues: {
            skills: initialSkills ? initialSkills : [],
        },
        onSubmit: (values) => {
            const data = prepareDateSkills()
            saveSkillsCandidte(data, id);
        },
    });

    /* Prepare Format Skills List */
    function prepareDateSkills() {
        let allSelectedSkills = [];
        for (let index = 0; index < FormSkills.values.skills.length; index++) {
            const element = FormSkills.values.skills[index];
            if (element.selectedSkills) {
                element.selectedSkills.forEach(selected => {
                    allSelectedSkills.push({ id: selected.value, rating: selected.rating });
                });
            }
        }
        return allSelectedSkills;
    }

    /* Skills Forms */
    function SkillsByType() {
        return (
            <Formik >
                <Form onSubmit={FormSkills.handleSubmit}>
                    <FieldArray name="skills"
                        render={(arrayHelpers) =>
                            FormSkills &&
                            FormSkills.values.skills.length &&
                            FormSkills.values.skills.map((skill, index) =>
                                showSkillsByType(skill, index, arrayHelpers)
                            )
                        } />
                    <Button type="submit" className="shadow-unset float-right save-button border-dark">
                        Sauvegarder
                    </Button>
                </Form>
            </Formik>
        );
    }

    /* Skills By Types */
    function showSkillsByType(skill, index) {
        return (
            <div key={"TypeSkill" + index}>
                <Form.Group className="mb-2 formGroup">
                    <label className="text-roboto-bold blue">
                        {skill.typeSkill ? skill.typeSkill : "- - -"}
                    </label>
                    <Select isMulti isSearchable placeholder=""
                        name={`skills[${index}].selectedSkills`}
                        options={skill.options}
                        closeMenuOnSelect={true}
                        value={skill.selectedSkills}
                        onChange={(event) => {
                            const value = event.map((el) => { return { value: el.value, label: el.label, rating: (el.rating ? el.rating : 0) } })
                            FormSkills.setFieldValue(`skills[${index}].selectedSkills`, value);
                        }}
                    />
                </Form.Group>
                <FlexboxPage skill={skill} index={index} />
            </div>
        )
    }

    /* Raiting By Element */
    function FlexboxPage({ skill, index }) {
        return (
            <Row className="mt-3 mb-3">
                {skill.selectedSkills.map((el, index1) => {
                    return (
                        <Col className="mb-2" lg={6} md={6} xs={12} key={"skillRate" + index1}>
                            <div className="d-flex dropdown-skills p-1 mb-2">
                                <div className="flex-grow-1 text-skills align-self-center">
                                    {el.label}
                                </div>
                                <div className="align-self-center">
                                    <Rating
                                        name={`skills[${index}].selectedSkills[${index1}].rating`}
                                        value={el.rating}
                                        defaultValue={0}
                                        precision={0.5}
                                        className="rating-style"
                                        onChange={(event, value) => {
                                            FormSkills.setFieldValue(`skills[${index}].selectedSkills[${index1}].rating`, value);
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        );
    }


    return (
        <>
            <div className="border-top mt-5 mb-5"></div>
            <div className="title-profile-cosmonaute mb-5">COMPÉTENCES</div>
            {SkillsByType()}
        </>
    );

}