import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import "../TermsConditions/TemrsConditions";
import "./legalNotice.scss"
import terms from "../../assets/img/inscription/Terms.png";
import etoile from "../../assets/img/inscription/etoile.png";
import { Link, useLocation } from 'react-router-dom';
export default function LegalNotice() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      <section className="terms termsLegale">
        <Header />
        <img src={etoile} className="etoile" alt="etoile"></img>
        <img src={terms} alt="planet" className="terms-planet1"></img>
        <ScrollAnimation animateIn="flipInY" animateOut="flipOutY">
          <div className="text-center fontSize-140 text-transparent text-uppercase roboto-black padding-titre text-spacing-1">
            {t("lega.title")}
          </div>
        </ScrollAnimation>
        <img src={terms} alt="planet" className="terms-planet2"></img>
        <img src={terms} alt="planet" className="terms-planet3"></img>
        <div className="stars"></div>
        <ScrollAnimation
          animateIn="tada"
          initiallyVisible={true}
          className="padding-icon"
        >
          <div className="scroll-downs mt-5 ">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </ScrollAnimation>
      </section>
      <section className="conditions conditionsLegale">
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section1.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section1.description1")}</div>
            <div className="line-text mt-2">
              {t("lega.section1.description2")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section2.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section2.description1")}</div>
            <div className="line-text mt-2">
              {t("lega.section2.description2")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description3")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description4")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description5")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description6")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section3.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section3.description1")}</div>
            <div className="line-text mt-4">
              {t("lega.section3.description2")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section4.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text">{t("lega.section4.description1")}</div>
          </div>
        </div>
      </section>
      <section className="conditions-page1  conditionslegale-page1">
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section5.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section5.description1")}</div>
            <div className="line-text mt-4">
              {t("lega.section5.description2")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section6.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section6.description1")}</div>
            <div className="line-text mt-3">
              {t("lega.section6.description2")}
            </div>
            <div className="line-text mt-3">
              {t("lega.section6.description3")}
            </div>
            <div className="line-text mt-3">
              {t("lega.section6.description4")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section7.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text">{t("lega.section7.description1")}</div>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description2")}
            </div>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description3")}
            </div>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description4")}
            </div>
          </div>
        </div>
      </section>
      <section className="conditions-page2  conditionslegale-page2">
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("lega.section8.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text1")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description1")}
            </div>
          </div>
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text2")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description2")}
            </div>
          </div>
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text3")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description3")}
            </div>
          </div>
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text4")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description4")}
            </div>
          </div>
        </div>
        <div style={{ paddingBottom: "330px" }} className="white-color fontSize-25 roboto-regular">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text5")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description5")}
            </div>
          </div>
        </div>
        <div className="groupe-legal pg-termlegale"></div>
      </section>
      <section className="pg-terms pg-terms-legale">
        <div className="etoile-propos"></div>
        <div className="overlay"></div>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-178 yellow-color float-left text-spacing-7">
                {t("home.univers")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold ml-big text-uppercase fontSize-178 yellow-color text-spacing-3 infini">
                {t("home.infini")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                {t("home.contact")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-center fontSize-40 mb-lg-5 mb-sm-3">
                {t("home.adress")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 mb-lg-5 mb-sm-3">
                {t("home.phone")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 mb-lg-5 mb-sm-3">
                {t("home.mail")}
              </p>
              <p className="roboto-condensed text-center fontSize-25 notice text-underline">
                <Link to="/notice">{t("home.mention")}</Link>
              </p>
            </ScrollAnimation>
          </Col>
        </Row>
      </section>
    </div>
  );
}