import {
    API_HOST,
    API_RECRUITER_DETAILS_PATH, API_DOMAIN_RECRUITER_PATH,
    API_EDIT_PROFIL_RECRUITER, API_EDIT_DETAIL_PROFIL_RECRUITER,
    API_UPLOAD_LOGO_RECRUITER, API_GET_LOGO_RECRUITER
} from "../../Api";
import {
    SHOW_RCRUITER_PROFILE,
    EDIT_RCRUITER_PROFILE, DUMP_RECRUITER,
    RECRUITER_LOGO, GET_RECRUITER_LOGO, DUMP_LOGO_RECRUITER,
    DOMAIN_RECRUITER,
} from '../constants/Recruiter';
import { SHOW_TOAST } from '../constants/Toast';
import axiosServices from "../../services/Axios/axiosServices";
import { getUserId, getUserEmail } from "../../services/Controller/CurrentUserServices";

/* Show Profil Recruiter Action */
const getProfilRecruiter = (dispatch) => (id) => {
    // URL Detail Profil Recruiter
    const URL = API_HOST + API_RECRUITER_DETAILS_PATH + (id ? id : getUserId());
    // Call API DetailRecruiter
    axiosServices.get(URL).then(
        (profil) => {
            dispatch({ type: SHOW_RCRUITER_PROFILE, payload: profil.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération profil" }
            });
        }
    );
}

/* Show Domin list Action */
const getDomainRecruiter = (dispatch) => () => {
    // URL domain list 
    const URL = API_HOST + API_DOMAIN_RECRUITER_PATH;
    // Call domain list 
    axiosServices.get(URL).then(
        (profil) => {
            dispatch({ type: DOMAIN_RECRUITER, payload: profil.data["hydra:member"], toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des domaines" }
            });
        }
    );
}

/* Edit Profil Recruiter Action */
const editProfilRecruiter = (dispatch) => (body) => {
    // URL Detail Profil RecruiterContext
    const URL = API_HOST + API_EDIT_PROFIL_RECRUITER + getUserId();
    // Call API Edit Recruiter
    axiosServices.post(URL, body).then(
        async (response) => {
            const emailCurrentUser = getUserEmail();
            await dispatch({
                type: EDIT_RCRUITER_PROFILE,
                payload: response.data,
                toastContent: {
                    status: (emailCurrentUser !== body.email) ? 'warning' : 'success',
                    body: (emailCurrentUser !== body.email) ? "Email modifié, Merci de reconnecter" : "Profil est modifié avec succès"
                }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de modification profil" }
            });
        });
}

/* Edit Detail Profil Recruiter Action */
const editProfilDetailRecruiter = (dispatch) => (body) => {
    // URL Deatil Profil RecruiterContext
    const URL = API_HOST + API_EDIT_DETAIL_PROFIL_RECRUITER + getUserId();
    // Call API Edit Recruiter
    axiosServices.post(URL, body).then(
        (response) => {
            dispatch({
                type: EDIT_RCRUITER_PROFILE,
                payload: response.data,
                toastContent: { status: 'success', body: "Profil est modifié avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de modification profil" }
            });
        });
}

/* dump Profil Recruiter */
const dumpProfilRecruiter = (dispatch) => () => {
    dispatch({
        type: DUMP_RECRUITER,
        payload: null,
        toastContent: null
    });
}

/* upload Logo Action */
const logoUpload = (dispatch) => (selectedFile) => {
    var formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("userId", getUserId());
    // URL upload logo
    const URL = API_HOST + API_UPLOAD_LOGO_RECRUITER;
    // Call API upload logo
    axiosServices.post(URL, formdata).then(
        (profil) => {
            dispatch({
                type: RECRUITER_LOGO,
                payload: profil.data,
                toastContent: { status: 'success', body: "Logo importé avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'importer logo" }
            });
        }
    );
}

/* get Logo Action */
const getLogoRecruiter = (dispatch) => () => {
    // URL get logo
    const URL = API_HOST + API_GET_LOGO_RECRUITER + getUserId();
    // Call API get logo
    axiosServices.get(URL).then(
        (profil) => {
            dispatch({
                type: GET_RECRUITER_LOGO,
                payload: profil.data,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupérer logo" }
            });
        }
    );
}

/* dump Profil Recruiter */
const dumpLogoRecruiter = (dispatch) => () => {
    dispatch({
        type: DUMP_LOGO_RECRUITER,
        payload: null,
        toastContent: null
    });
}

/* Export Action Profile Recruiter */
export {
    getProfilRecruiter, getDomainRecruiter,
    editProfilRecruiter, editProfilDetailRecruiter, dumpProfilRecruiter,
    logoUpload, getLogoRecruiter, dumpLogoRecruiter
};