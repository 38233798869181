import Axios from "../../services/Axios/axiosServices";
import { UPLOAD_LOGO_CANDIDATE, GET_LOGO_CANDIDATE, DUMP_LOGO_CANDIDATE } from "../constants/Cosmonaute";
import { SHOW_TOAST } from "../constants/Toast";
import { API_HOST, API_UPLOAD_LOGO_CANDIDATE, API_GET_LOGO_CANDIDATE } from "../../Api";
import { getUserId } from "../../services/Controller/CurrentUserServices";

/* upload Logo Action */
export const logoUploadCandidate = (dispatch) => (selectedFile) => {
    var formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("userId", getUserId());
    // URL upload logo
    const URL = API_HOST + API_UPLOAD_LOGO_CANDIDATE;
    // Call API upload logo
    Axios.post(URL, formdata).then(
        (profil) => {
            dispatch({
                type: UPLOAD_LOGO_CANDIDATE,
                payload: profil.data,
                toastContent: { status: 'success', body: "Logo importé avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'importation logo" }
            });
        }
    );
}

/* get Logo Action */
export const getLogoCandidate = (dispatch) => (id) => {
    // URL get logo
    const URL = API_HOST + API_GET_LOGO_CANDIDATE + (id ? id : getUserId());
    // Call API get logo
    Axios.get(URL).then(
        (profil) => {
            dispatch({
                type: GET_LOGO_CANDIDATE,
                payload: profil.data,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération logo" }
            });
        }
    );
}

/* dump Profil Candidate */
export const dumpLogoCandidate = (dispatch) => () => {
    dispatch({
        type: DUMP_LOGO_CANDIDATE,
        payload: null,
        toastContent: null
    });
}