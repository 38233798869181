import { formatDate } from './DateFormatter';

/* get status Mission Recruiter */
export const getStatusMission = (element) => {
  let dateNow = formatDate(new Date());
  if (element.isInterrupted) return { label: "Interrompue", class: "red" };
  else if (dateNow >= formatDate(element.dateStart) && dateNow <= formatDate(element.dateEnd) &&
    element.profils && element.profils.length && element.profils.find((el) => el.status === 'recruited')
  ) {
    return { label: "En Cours", class: "green" };
  }
  else if (dateNow > formatDate(element.dateEnd)) {
    return { label: "Terminée", class: "black" };
  }
  else return { label: "Proposée", class: "blue" };

};

/* get status Mission Cosmo */
export const getStatusMissionCosmo = (element) => {
  let dateNow = formatDate(new Date());
  if (element.isInterrupted) return { label: "Interrompue", class: "red" };
  else if (element.status === "preselected") {
    return { label: "Proposée", class: "blue" };
  }
  else if (element.status === "recruited") {
    if (formatDate(element.dateEnd) < dateNow) {
      return { label: "Terminée", class: "black" };
    }
    else  return { label: "Assignée", class: "green" };
  }
  else if (element.status === "rejected") {
    return { label: "Refusée", class: "red" };
  }
};

/* get statut Candidat || interview */
export const getStatus = (statut) => {
  switch (statut) {
    case "pending":
      return { label: "En attente", class: "black" };
    case "change_date":
      return { label: "Demande changement de la date", class: "yellow" };
    case "preselected":
      return { label: "Pré-sélectionné", class: "violet" };
    case "rejected":
      return { label: "Rejeté", class: "red" };
    case "accepted":
      return { label: "À venir", class: "pink" };
    case "recruited":
      return { label: "Recruté", class: "green" };
    case "passed":
      return { label: "Passé", class: "blue" };
    default:
      return null;
  }
};