import React, { useReducer } from 'react';
import { signin, dumpUser } from '../actions/Login'
import { signout } from '../actions/Logout'
import { FORGET_PASSWORD, RESET_PASSWORD } from "../constants/Password";
import { forgetPassword, resetPassword } from "../actions/Password";
import { ERROR, CURRENT_USER, USER_SIGNOUT, DUMP_CURRENT_USER } from '../constants/Login'
import { hideToast } from "../actions/Toast";

const authReducer = (state, action) => {
    switch (action.type) {
        case DUMP_CURRENT_USER:
            return { ...state, currentUser: null };
        case CURRENT_USER:
            return { ...state, currentUser: action.payload, toastContent: null, error: null };
        case USER_SIGNOUT:
            return { ...state, currentUser: null, toastContent: null };
        case FORGET_PASSWORD:
            return { ...state, password: action.payload, toastContent: action.toastContent };
        case RESET_PASSWORD:
            return { ...state, password: action.payload, toastContent: action.toastContent };
        case ERROR:
            return { ...state, error: action.payload, toastContent: null };
        default:
            return state;
    }
};
const AuthState = (reducer, actions, defaultValue) => {
    const Context = React.createContext();
    const Provider = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, defaultValue);

        const boundActions = {};
        for (let key in actions) {
            boundActions[key] = actions[key](dispatch);
        }
        return (
            <Context.Provider value={{ state, ...boundActions }}>
                {children}
            </Context.Provider>
        );
    };
    return { Context, Provider };
};

export const { Provider, Context } = AuthState(
    authReducer,
    {
        signin,
        signout,
        forgetPassword,
        hideToast,
        resetPassword,
        dumpUser
    },
    {
        inactifUser: null,
        currentUser: null,
        toastContent: null,
        password: null,
        error: null
    }
);
