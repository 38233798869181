import React from "react";
import { useTranslation } from "react-i18next";
import "../Jobs/Jobs.scss";

export default function Jobs() {
  const { t } = useTranslation();

  function tbody() {
    return (
      <>
        <div className="slide">{t("home.jobs.engineers")}</div>
        <div className="slide">{t("home.jobs.developer")}</div>
        <div className="slide">{t("home.jobs.devops")}</div>
        <div className="slide">{t("home.jobs.product-owner")}</div>
        <div className="slide">{t("home.jobs.agile-scrum")}</div>
        <div className="slide">{t("home.jobs.front-end")}</div>
        <div className="slide">{t("home.jobs.fullstack")}</div>
        <div className="slide">{t("home.jobs.back-end")}</div>
        <div className="slide">{t("home.jobs.QA")}</div>
        <div className="slide">{t("home.jobs.engineers")}</div>
        <div className="slide">{t("home.jobs.developer")}</div>
        <div className="slide">{t("home.jobs.devops")}</div>
        <div className="slide">{t("home.jobs.product-owner")}</div>
        <div className="slide">{t("home.jobs.agile-scrum")}</div>
        <div className="slide">{t("home.jobs.front-end")}</div>
        <div className="slide">{t("home.jobs.fullstack")}</div>
        <div className="slide">{t("home.jobs.back-end")}</div>
        <div className="slide">{t("home.jobs.QA")}</div>
        <div className="slide">{t("home.jobs.engineers")}</div>
        <div className="slide">{t("home.jobs.developer")}</div>
        <div className="slide">{t("home.jobs.devops")}</div>
        <div className="slide">{t("home.jobs.product-owner")}</div>
        <div className="slide">{t("home.jobs.agile-scrum")}</div>
        <div className="slide">{t("home.jobs.front-end")}</div>
        <div className="slide">{t("home.jobs.fullstack")}</div>
        <div className="slide">{t("home.jobs.back-end")}</div>
        <div className="slide">{t("home.jobs.QA")}</div>
        <div className="slide">{t("home.jobs.engineers")}</div>
        <div className="slide">{t("home.jobs.developer")}</div>
        <div className="slide">{t("home.jobs.devops")}</div>
        <div className="slide">{t("home.jobs.product-owner")}</div>
        <div className="slide">{t("home.jobs.agile-scrum")}</div>
        <div className="slide">{t("home.jobs.front-end")}</div>
        <div className="slide">{t("home.jobs.fullstack")}</div>
        <div className="slide">{t("home.jobs.back-end")}</div>
        <div className="slide">{t("home.jobs.QA")}</div>
      </>
    );
  }
  return (
    <>
      <div className="slider">
        <div className="slide-track">{tbody()}</div>
      </div>
    </>
  );
}
