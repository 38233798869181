import React from 'react';
import { Modal, Button } from "react-bootstrap";
import './alert.scss';

export default function AlertModal({ show, setShow, content, type, isRefresh, refreshResume }) {
    const handleClose = () => {
        setShow(false);
        if (isRefresh) refreshResume();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} animation={false} centered>
                <Modal.Body style={{ color: "blue" }}>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className={'place-content-center ' + (type === "error" ? "color-white" : "")}
                        variant={type === "error" ? "danger" : "secondary"}
                        onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

