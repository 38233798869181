import React, { useContext, useEffect, useState } from "react";
import "./CandidaturesAdmin.scss";
import { Tabs, Tab, Table, Row, Col } from "react-bootstrap";
import { FaCheck, FaTimes, FaEye } from "react-icons/fa";
import ToastService from "../../../components/Toast/ToastService";
import { Context as AdministratorContext } from "../../../context/reducers/AdminReducer";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import ModalDetail from "../../../components/ModalDetail/ModalDetail";
import { formatDate } from "../../../services/Controller/DateFormatter";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import ReactTooltip from "react-tooltip";
import Paginate from "../../../components/Paginate/Paginate";
import InspirnauteFilter from "../../../components/InspirnauteFilter/InspirnauteFilter";
import RecruterFilter from "../../../components/RecruterFilter/RecruterFilter";
/* Condidatures Page */
function CandidaturesAdmin() {
  /* Context Api */
  const {
    getAllRecruitersCandidatures,
    getAllProfilsCandidatures,
    validateCandidature,
    dumpCandidature,
    hideToast,
    state,
  } = useContext(AdministratorContext);
  /* Condidatures lists */
  const [allRecruitersCandidatures, setAllRecruitersCandidatures] = useState(
    []
  );
  const [allProfilsCandidatures, setAllProfilsCandidatures] = useState([]);
  /* Table Content */
  const [displayedColumns, setDisplayedColumns] = useState([
    "Nom entreprise",
    "Domaine entreprise",
    "Nom recruteur",
    "Poste",
    "Lieu",
    "Téléphone",
    "Email",
    "",
  ]);
  const [currentItems, setCurrentItems] = useState([]);
  /* Tab Active Value */
  const [defaultTabValue, setDefaultTabValue] = useState("Recruiters");
  const [resetForm, setResetForm] = useState(false);
  /* Detail Profil */
  const [modalShow, setModalShow] = useState(false);
  const [DataToShow, setDataToShow] = useState(null);
  /* Modals */
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [titleModal, setTitleModal] = useState("");
  /* filter Input */
  const [displayedData, setDisplayedData] = useState([]);
  const [displayedInspirnautes, setInspirnautesData] = useState([]);
  /* eslint-disable */
  useEffect(() => {
    getAllRecruitersCandidatures();
    getAllProfilsCandidatures();
  }, []);
  /* eslint-enable */
  useEffect(() => {
    if (state.allRecruitersCandidatures)
      setAllRecruitersCandidatures(state.allRecruitersCandidatures);
    setDisplayedData(state.allRecruitersCandidatures);
    if (state.allProfilsCandidatures)
      setAllProfilsCandidatures(state.allProfilsCandidatures);
    setInspirnautesData(state.allProfilsCandidatures);
  }, [state]);

  /* Refresh After Validation */
  useEffect(() => {
    if (state.candidature) {
      if (state.candidature === "recruiter") getAllRecruitersCandidatures();
      else getAllProfilsCandidatures();
      dumpCandidature();
    }
  }, [
    dumpCandidature,
    getAllRecruitersCandidatures,
    getAllProfilsCandidatures,
    state.candidature,
  ]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Content Condidatures List By Type */
  function showCondidaturesContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {defaultTabValue === "Recruiters"
              ? displayedData && displayedData.length
                ? getListRecruitersCandidatures()
                : null
              : displayedInspirnautes && displayedInspirnautes.length
              ? getListInspirnautesCandidatures()
              : null}
          </tbody>
        </Table>
        {defaultTabValue === "Recruiters" ? (
          !displayedData || !displayedData.length ? (
            <NothingToShow type="array" />
          ) : (
            <Paginate
              items={displayedData}
              setCurrentItems={setCurrentItems}
              itemsPerPage={10}
            />
          )
        ) : !displayedInspirnautes || !displayedInspirnautes.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedInspirnautes}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }
  /* list Candidatures Inspirnautes Map */
  function getListInspirnautesCandidatures() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Inspirnaute" + index}>
          <td className="w-150">
            <p>
              {el.name && el.lastname ? el.name + " " + el.lastname : "- - -"}
            </p>
          </td>
          <td className="w-150">
            <p>{el.poste ? el.poste : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.email ? el.email : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.phone ? el.phone : "- - -"}</p>
          </td>
          <td className="w-250">
            <p>
              {el.country ? el.country : "- - -"}{" "}
              {el.address ? el.address : "- - -"}
            </p>
          </td>
          <td className="w-150">
            <p>{el.nbr_annee_exp ? el.nbr_annee_exp : 0}</p>
          </td>
          <td className="w-300">
            <div className="float-right pointer d-flex">
              {/* Tooltip */}
              <ReactTooltip id="Consult" place="bottom">
                {" "}
                Consulter{" "}
              </ReactTooltip>
              <ReactTooltip id="Accept" place="bottom">
                {" "}
                Accepter{" "}
              </ReactTooltip>
              <ReactTooltip id="Refuse" place="bottom">
                {" "}
                Refuser{" "}
              </ReactTooltip>
              {/* Détail Candidature */}
              <span
                className="btn-detail blue"
                onClick={() => {
                  functionshowInspirnauteDetail(el);
                }}
              >
                <FaEye className="mr-20" data-tip data-for="Consult" />
              </span>
              {/* Accept Candidature */}
              <span
                className="btn-detail green"
                onClick={() => {
                  selectItem({
                    action: "accept",
                    id: el.id_user,
                    candidature: "candidate",
                  });
                }}
              >
                <FaCheck className="mr-20" data-tip data-for="Accept" />
              </span>
              {/* Refuse Candidature */}
              <span
                className="btn-detail red"
                onClick={() => {
                  selectItem({
                    action: "refuse",
                    id: el.id_user,
                    candidature: "candidate",
                  });
                }}
              >
                <FaTimes data-tip data-for="Refuse" />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }
  /* list Candidatures Recruiters Map */
  function getListRecruitersCandidatures() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Recruiter" + index}>
          <td className="w-150">
            <p>{el.company_name ? el.company_name : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.domain_recruiter ? el.domain_recruiter[0].name : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.full_name ? el.full_name : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.poste ? el.poste : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.country ? el.country : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.phone_number ? el.phone_number : "- - -"}</p>
          </td>
          <td className="w-150">
            <p>{el.email ? el.email : "- - -"}</p>
          </td>
          <td className="w-350">
            <div className="float-right pointer d-flex">
              {/* Tooltip */}
              <ReactTooltip id="Consult" place="bottom">
                {" "}
                Consulter{" "}
              </ReactTooltip>
              <ReactTooltip id="Accept" place="bottom">
                {" "}
                Accepter{" "}
              </ReactTooltip>
              <ReactTooltip id="Refuse" place="bottom">
                {" "}
                Refuser{" "}
              </ReactTooltip>
              {/* Détail Candidature */}
              <span
                className="btn-detail blue"
                onClick={() => {
                  functionshowRecruiterDetail(el);
                }}
              >
                <FaEye className="mr-20" data-tip data-for="Consult" />
              </span>
              {/* Accept Candidature */}
              <span
                className="btn-detail green"
                onClick={() => {
                  selectItem({
                    action: "accept",
                    id: el.id_user,
                    candidature: "recruiter",
                  });
                }}
              >
                <FaCheck className="mr-20" data-tip data-for="Accept" />
              </span>
              {/* Refuse Candidature */}
              <span
                className="btn-detail red"
                onClick={() => {
                  selectItem({
                    action: "refuse",
                    id: el.id_user,
                    candidature: "recruiter",
                  });
                }}
              >
                <FaTimes data-tip data-for="Refuse" />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }
  /* select item to validate */
  function selectItem(data) {
    setSelectedItem({
      action: data.action,
      id: data.id,
      candidature: data.candidature,
    });
    setTitleModal(
      "Vous êtes sûr de vouloir " +
        (data.action === "accept" ? "accepter" : "refuser") +
        (defaultTabValue === "Recruiters"
          ? " ce recruteur"
          : " cet inspirnaute") +
        " ?"
    );
    setShow(true);
  }
  /* Accept | Refuse item */
  function validationItem() {
    validateCandidature(selectedItem);
  }
  /* Show Recruiter Detail */
  function functionshowRecruiterDetail(item) {
    setDataToShow({
      Title: "Détail Profil Recruteur",
      data: [
        {
          title: "Nom entreprise",
          value: item.company_name ? item.company_name : "- - -",
        },
        {
          title: "Domaine entreprise",
          value: item.domain_recruiter
            ? item.domain_recruiter[0].name
            : "- - -",
        },
        {
          title: "Téléphone",
          value: item.phone_number ? item.phone_number : "- - -",
        },
        { title: "Email", value: item.email ? item.email : "- - -" },
        { title: "Lieu", value: item.country ? item.country : "- - -" },
        { title: "Adresse", value: item.address ? item.address : "- - -" },
        { title: "URL", value: item.link ? item.link : "- - -" },
        {
          title: "Horaire de travail",
          value:
            item.start_at && item.finish_at
              ? "Commence en " +
                item.start_at +
                " et termine en " +
                item.finish_at
              : "- - -",
        },
        {
          title: "Description",
          value: item.description ? item.description : "- - -",
        },
        {
          title: "Nom recruteur",
          value: item.full_name && item.full_name ? item.full_name : "- - -",
        },
        { title: "Email recruteur", value: item.email ? item.email : "- - -" },
      ],
    });
    setModalShow(true);
  }
  /* Show Inspirnaute Detail */
  function functionshowInspirnauteDetail(item) {
    setDataToShow({
      Title: "Détail Profil Inspirnaute",
      data: [
        {
          title: "Nom inspirnaute",
          value:
            item.name && item.lastname
              ? item.name + " " + item.lastname
              : "- - -",
        },
        { title: "Profil", value: item.poste ? item.poste : "- - -" },
        {
          title: "Nb années experiences",
          value: item.nbr_annee_exp ? item.nbr_annee_exp : "- - -",
        },
        { title: "Email", value: item.email ? item.email : "- - -" },
        { title: "Téléphone", value: item.phone ? item.phone : "- - -" },
        { title: "Lieu", value: item.country ? item.country : "- - -" },
        { title: "Adresse", value: item.address ? item.address : "- - -" },
        {
          title: "Disponibilité",
          value:
            item.dispo_start && item.dispo_end
              ? "Commence à " +
                item.dispo_start +
                " et termine à " +
                item.dispo_end
              : "- - -",
        },
        {
          title: "Experiences",
          value:
            item.work_experiences && item.work_experiences.length
              ? item.work_experiences
                  .map(
                    (el) =>
                      el.poste +
                      " chez " +
                      el.entreprise +
                      " : de " +
                      formatDate(new Date(el.from_date)) +
                      " à " +
                      formatDate(new Date(el.to_date))
                  )
                  .join(", ")
              : "- - -",
        },
        {
          title: "Compétences",
          value: item.profil_skills,
        },
        {
          title: "Autres compétences",
          value: item.other_skills ? item.other_skills : "- - -",
        },
        {
          title: "Langues",
          value:
            item.langues && item.langues.length
              ? item.langues.map((el) => el.libelle).join(", ")
              : "- - -",
        },
        {
          title: "Description",
          value: item.description ? item.description : "- - -",
        },
        {
          title: "Tarif",
          value: item.tarif ? item.tarif + " €" : "- - -",
        },
      ],
    });
    setModalShow(true);
  }
  /* Tabulation onChange */
  function changeTabulation(tab) {
    if (tab === "Recruiters") {
      setDisplayedColumns([
        "Nom entreprise",
        "Domain entreprise",
        "Nom recruteur",
        "Poste",
        "Lieu",
        "Téléphone",
        "Email",
        "",
      ]);
    } else {
      setDisplayedColumns([
        "Nom inspirnaute",
        "Profil",
        "Email",
        "Téléphone",
        "Lieu",
        "Années d'expériences",
        "",
      ]);
    }
    setDefaultTabValue(tab);
  }
  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      {/* Confirm Modal */}
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={titleModal}
        setSelectedItem={setSelectedItem}
        Action={validationItem}
      />
      {/* Modal Detail */}
      <ModalDetail
        modalShow={modalShow}
        setModalShow={setModalShow}
        DataToShow={DataToShow}
      />
      <div id="main-without-header" className="main-without-header">
        <Row className="align-md">
          <Col lg={3} md={12} xs={12}>
            <label className="title-page mb-4">Candidatures</label>
          </Col>
        </Row>
        <Col lg={12} md={12} xs={12}>
            {defaultTabValue === "Recruiters" ? (
              <RecruterFilter
                resetForm={resetForm}
                setResetForm={setResetForm}
                defaultTabValue={defaultTabValue}
                allMissions={allRecruitersCandidatures}
                setDisplayedData={setDisplayedData}
              />
            ) : (
              <InspirnauteFilter
                resetForm={resetForm}
                setResetForm={setResetForm}
                defaultTabValue={defaultTabValue}
                allMissions={allProfilsCandidatures}
                setDisplayedData={setInspirnautesData}
              />
            )}
          </Col>
        <Tabs
          activeKey={defaultTabValue}
          onSelect={(k) => changeTabulation(k)}
          defaultActiveKey="Recruiters"
          className="mt-3 mb-3 w-100"
        >
          <Tab
            eventKey="Recruiters"
            title={
              "Recruteurs (" +
              (displayedData
                ? displayedData.length
                : 0) +
              ")"
            }
          >
            {showCondidaturesContent()}
          </Tab>
          <Tab
            eventKey="Condidates"
            title={
              "Candidats (" +
              (displayedInspirnautes ? displayedInspirnautes.length : 0) +
              ")"
            }
          >
            {showCondidaturesContent()}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
/* Export Candidatures component */
export default CandidaturesAdmin;
