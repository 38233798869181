import Axios from "../../services/Axios/axiosServices";
import { ADD_NEW_EXPERIENCE, DELETE_EXPERIENCE, EDIT_EXPERIENCE, SAVE_EXPERIENCE, DUMP_EXPERIENCE } from "../constants/Cosmonaute";
import { SHOW_TOAST } from "../constants/Toast";
import { API_HOST, API_ADD_WORK_EXP_PATH, API_UPDATE_WORk_PATH, API_REMOVE_WORK_EXP_PATH, API_SAVE_WORK_EXPERIENCE } from "../../Api";
import { getUserId } from "../../services/Controller/CurrentUserServices";

/* Add Experience for Candidate */
export const addExperience = (dispatch) => (data) => {
    const formdata = new FormData();
    formdata.append("position", data.position);
    formdata.append("company", data.companyname);
    formdata.append("fromDate", data.startAtCampany.toString());
    formdata.append("toDate", data.finishAtCampany.toString());
    formdata.append("description", data.workdescription);
    const url = API_HOST + API_ADD_WORK_EXP_PATH + getUserId();
    Axios.post(url, formdata).then(
        (res) => {
            dispatch({
                type: ADD_NEW_EXPERIENCE,
                payload: res.data,
                toastContent: {
                    status: "success",
                    body: "Experience est ajoutée avec succès",
                },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                payload: null,
                toastContent: {
                    status: "error",
                    body: "Echec d'ajout experience"
                },
            });
        }
    );
};

/* Edit Experience for Candidate */
export const editExperience = (dispatch) => (data) => {
    const url = API_HOST + API_UPDATE_WORk_PATH + getUserId();
    Axios.put(url, data).then(
        (res) => {
            dispatch({
                type: EDIT_EXPERIENCE,
                payload: res.data,
                toastContent: {
                    status: "success",
                    body: "Experience est modifiée avec succès",
                },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                payload: null,
                toastContent: {
                    status: "error",
                    body: "Echec de modification expérience"
                },
            });
        }
    );
};

/* Delete Experience for Candidate */
export const deleteExperience = (dispatch) => (id) => {
    const url = API_HOST + API_REMOVE_WORK_EXP_PATH + id;
    Axios.delete(url).then(
        (res) => {
            dispatch({
                type: DELETE_EXPERIENCE,
                payload: res.data,
                toastContent: {
                    status: "success",
                    body: "deleted with success",
                },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                payload: null,
                toastContent: {
                    status: "error",
                    body: "Echec de suppression experience"
                },
            });
        }
    );
};

/* save Experience for Candidate */
export const saveExperience = (dispatch) => (data, id) => {
    const url = API_HOST + API_SAVE_WORK_EXPERIENCE + id;
    Axios.post(url, data).then(
        (res) => {
            dispatch({
                type: SAVE_EXPERIENCE,
                payload: res.data,
                toastContent: {
                    status: "success",
                    body: "Succès d'enregistrement des expériences",
                },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                payload: null,
                toastContent: {
                    status: "error",
                    body: "Echec d'enregistrement des expériences"
                },
            });
        }
    );
};

/* dump Experience for Candidate */
export const dumpExperience = (dispatch) => () => {
    dispatch({
        type: DUMP_EXPERIENCE,
        payload: null,
        toastContent: null,
    });
};