import React from "react";
import { Provider as AuthProvider } from "./context/reducers/AuthReducer";
import { Provider as RecruiterProvider } from "./context/reducers/RecruiterReducer";
import { Provider as AdminProvider } from "./context/reducers/AdminReducer";
import { Provider as CosmonauteProvider } from "./context/reducers/CosmonauteReducer";
import { Provider as InscriptionProvider } from "./context/reducers/InscriptionReducer";
import { Provider as DocumentProvider } from "./context/reducers/DocumentReducer";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AllRoutes from "./pages/AllRoutes/AllRoutes";
import Loader from "./components/Loader/Loader";

function App() {
  return (
    <InscriptionProvider>
      <AuthProvider>
        <RecruiterProvider>
          <AdminProvider>
            <CosmonauteProvider>
              <DocumentProvider>
                <Loader />
                <Router>
                  <Switch>
                    <AllRoutes />
                  </Switch>
                </Router>
              </DocumentProvider>
            </CosmonauteProvider>
          </AdminProvider>
        </RecruiterProvider>
      </AuthProvider>
    </InscriptionProvider>
  );
}

export default App;
