import React, { useEffect, useState } from 'react';
import './ScrollToTopArrow.scss';
// import cookies
import { useCookies } from 'react-cookie';

export default function ScrollToTopArrow() {
    /* use cookies */
    const [cookies] = useCookies();
    /* states */
    const [scroll, setScroll] = useState("d-none");
    /* Detect window position change */
    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > 0) setScroll("d-block");
        else setScroll("d-none");
    };

    /* listner on scroll */
    useEffect(() => {
        if (cookies && cookies["showCookies"]) {
            window.addEventListener("scroll", handleScroll);
        }
    }, [cookies]);

    /* Scroll To Top function */
    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <div className={"pointer " + scroll} onClick={scrollToTop}>
                <i className="icon-down-arrow arrow-up-scroll"></i>
            </div>
        </>
    )
}