import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Cosmo from "../../assets/img/astronauteHome.png";
import Cosmo2 from "../../assets/svg/cosmonaut2.svg";
import planet from "../../assets/img/Planet1.png";
import planet2 from "../../assets/svg/Planet2.svg";
import Fusee from "../../assets/img/fusee.png";
import FuseeBlue from "../../assets/img/fuseeBlue.png";
import circle from "../../assets/img/circle-bg-astronaut.png";
import cristals from "../../assets/img/cristals.png";
import { Row, Col, Button, Image } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Jobs from "../Jobs/Jobs.js";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import {Link, useHistory} from "react-router-dom";
import "animate.css/animate.min.css";
import "./Home.scss";
export default function Home() {
  /* use translation */
  const { t } = useTranslation();
  const history = useHistory();
  const routehunter = () => {
    let path = `/recruter`;
    history.push(path);
    window.scroll(0, 0);
  };
  const routeinspirnaute = () => {
    let path = `/inscription`;
    history.push(path);
    window.scroll(0, 0);
  };
  return (
    <>
      <div className="homePage">
        {/* Arrow Scroll Top */}
        <ScrollToTopArrow />
        {/* Header section */}
        <Header />
        {/* First Section */}
        <section id="section1">
          <ScrollAnimation
            animateIn="flipInY"
            animateOut="flipOutY"
            className="margin-top-text"
          >
            <p className="text-transparent text-uppercase text-center roboto-black fontSize-70 text-spacing-2 fontSize-mobile-38 margin-botton-mobile">
              {t("home.slogan1")}
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInY" animateOut="flipOutY">
            <p className="text-uppercase text-center roboto-black fontSize-150 yellow-color fontSize-mobile-38 pt-40">
              {t("home.slogan2")}
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="wobble">
            <p className="it-Description fontSize-25 roboto-light text-center">
              {t("home.description")}
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="wobble">
            <p className="it-Description fontSize-25 roboto-light text-center">
              {t("home.question")}
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="tada">
            <div className="scroll-downs mt-5 ">
              <div className="mousey">
                <div className="scroller"></div>
              </div>
            </div>
          </ScrollAnimation>
        </section>
        {/* Embauche Section */}
        <section id="section2" className="position-relative">
          <img src={cristals} alt="cristals" className="position-absolute cristals" />
          <Row>
            <Col lg={5} md={5} xs={12}>
              <ScrollAnimation className="position-relative" animateIn="bounceInLeft" animateOut="bounceOutRight" >
                <img src={circle} alt="bubble" className="position-absolute bubble animate-6" />
                <img src={Cosmo} alt="Cosmonaute" className="animate-6 w-100" />
                <p className="fontSize-160 roboto-condensed-bold text-transparent text-uppercase p-recruter position-absolute">
                  {t("home.recruter")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={7} md={7} xs={12}>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="text-uppercase roboto-condensed-bold purple-Color fontSize-160 text-spacing-4">
                  {t("home.embauche")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-condensed-bold text-uppercase purple-Color fontSize-70 font-size-description text-spacing-2">
                  {t("home.it")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-condensed-bold text-uppercase purple-Color fontSize-70 font-size-description">
                  {t("home.speciality")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <Button
                  className="roboto-bold btn-recrute"
                  onClick={() => {
                    routehunter();
                  }}
                >
                  {t("home.btnRecrutement")}
                </Button>
              </ScrollAnimation>
            </Col>
          </Row>
        </section>
        {/* Section Jobs */}
        <section id="section3">
          <Jobs />
        </section>
        {/* Find Mission Section */}
        <section id="section4" className="position-relative">
          <div className="missions-title position-relative">
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-uppercase fontSize-140 yellow-color first text-spacing-5">
                {t("home.mission.find")}
              </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-uppercase fontSize-140 yellow-color second text-spacing-5">
                {t("home.mission.mission")}
              </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-uppercase fontSize-140 yellow-color third text-spacing-5">
                {t("home.mission.become")}
              </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-uppercase fontSize-140 yellow-color forth text-spacing-5">
                {t("home.mission.know")}
              </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
              <p className="roboto-condensed-bold text-uppercase fontSize-140 yellow-color fifth">
                {t("home.mission.happy")}
              </p>
              <Button className="text-uppercase roboto-bold btn-mission" onClick={() => { routeinspirnaute() }}>
                {t("home.btnMission")}
              </Button>
            </ScrollAnimation>
          </div>
          <img src={Fusee} alt="fusée" className="position-absolute fusee animate-4" />
        </section>
        {/* Missions Section */}
        <section id="section5" className="position-relative">
          <div className="position-absolute cosmo">
            <img src={Cosmo2} alt="Cosmonaute" className="w-60 animate-6" />
          </div>
          <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
            <p className="roboto-condensed-bold text-uppercase fontSize-178 green-color text-spacing-6 text-center font-size-title">
              {t("home.missions")}
            </p>
            <p className="roboto-condensed-bold text-uppercase fontSize-178 green-color unique">
              {t("home.uniques")}
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
            <p className="roboto-condensed-bold text-uppercase fontSize-35 green-color lh-1 team">
              {t("home.team.dev")}
            </p>
            <p className="roboto-condensed-bold text-uppercase fontSize-35 green-color lh-1 team">
              {t("home.team.chef")}
            </p>
            <p className="roboto-condensed-bold text-uppercase fontSize-35 green-color lh-1 team">
              {t("home.team.dev-coach")}
            </p>
            <p className="roboto-condensed-bold text-uppercase fontSize-35 green-color lh-1 team">
              {t("home.team.expert")}
            </p>
          </ScrollAnimation>
          <img src={planet} alt="planet" className="position-absolute planet animate-6" />
        </section>
        {/* Why IT Section */}
        <section id="section6">
          <Row className="justify-content-md-center">
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-condensed-bold text-uppercase fontSize-140 text-white float-left text-spacing-5">
                  {t("home.why")}
                  <span className="text-transparent"> {t("home.choose")} </span>
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-condensed-bold text-uppercase fontSize-140 text-white float-right space-between">
                  {t("home.italent")}
                </p>
              </ScrollAnimation>
            </Col>
          </Row>
        </section>
        {/* Client Section */}
        <section id="section7">
          <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
            <p className="roboto-condensed-bold text-uppercase text-opacity5-transparent fontSize-260 text-center">
              {t("home.client")}
            </p>
          </ScrollAnimation>
          <Row>
            <Col lg={5} md={4} xs={3}>
              <img src={FuseeBlue} alt="fusée" className="animate-6 w-100" />
            </Col>
            <Col lg={7} md={8} xs={9} className="client">
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
                <p className="roboto-light fontSize-40 text-white first">
                  {t("home.ourTeam")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-bold fontSize-40 text-white second">
                  {t("home.plaisir")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-light text-white lh-1 className='fontSize-24'">
                  {t("home.avantage.avantage1")}
                </p>
                <p className="roboto-light text-white lh-1 className='fontSize-24'">
                  {t("home.avantage.avantage2")}
                </p>
                <p className="roboto-light text-white lh-1 className='fontSize-24'">
                  {t("home.avantage.avantage3")}
                </p>
                <p className="roboto-light text-white lh-1 className='fontSize-24'">
                  {t("home.avantage.avantage4")}
                </p>
                <p className="roboto-light text-white last className='fontSize-24'">
                  {t("home.avantage.avantage5")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" className="button-section" >
                <Button className="roboto-bold btn-recrute" onClick={() => { routehunter() }} >
                  {t("home.btnRecrutement")}
                </Button>
              </ScrollAnimation>
            </Col>
          </Row>
        </section>
        {/* Talents Section */}
        <section id="section8">
          <Row className="align-items-center">
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase text-opacity3-transparent fontSize-260 text-center text-spacing-6 text-size">
                {t("home.talents")}
              </p>
            </ScrollAnimation>
            <Col lg={6} md={6} xs={12}>
              <Image className="w-planet mode-Luminosity" src={planet2} />
            </Col>
            <Col lg={6} md={6} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold fontSize-35 yellow-color">
                  {t("home.blooming")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-55 text-white text-spacing-1">
                  {t("home.transform")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-55 text-white text-spacing-1">
                  {t("home.votreMission")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-55 text-white text-spacing-1">
                  {t("home.passion")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold fontSize-35 green-color">
                  {t("home.skills")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-white fontSize-55 text-uppercase text-spacing-2">
                  {t("home.assistant")}
                </p>
              </ScrollAnimation>
            </Col>
          </Row>
        </section>
        {/* New Mission Section */}
        <section id="section9" className="position-relative">
          <Row className="align-items-center">
            <Col lg={2} md={2} xs={2}></Col>
            <Col lg={10} md={10} xs={10} className="position-absolute display-text" >
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-condensed-bold green-color fontSize-125 text-uppercase first text-spacing-4">
                  {t("home.everyDay")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
                <p className="roboto-condensed-bold green-color fontSize-125 text-uppercase second text-spacing-4">
                  {t("home.new")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
                <p className="roboto-condensed-bold green-color fontSize-125 text-uppercase third">
                  {t("home.newMission")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
                <p className="roboto-condensed green-color fontSize-45  text-uppercase last">
                  {t("home.tech")}
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
                <Button variant="secondary" className="roboto-bold btn-recrute" onClick={() => { routeinspirnaute(); }}>
                  {t("home.btnMission")}
                </Button>
              </ScrollAnimation>
            </Col>
          </Row>
        </section>
        {/* Contact Section */}
        <section id="section10">
          <Row className="line-height-row">
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-178 yellow-color float-left text-spacing-7">
                  {t("home.univers")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold ml-big text-uppercase fontSize-178 yellow-color text-spacing-3 infini">
                  {t("home.infini")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                  {t("home.contact")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <div>
                <p className="roboto-condensed-bold text-center fontSize-40 margin-contact-mobile">
                  {t("home.adress")}
                </p>
                <p className="roboto-condensed-bold text-center fontSize-40 margin-contact-mobile">
                  {t("home.phone")}
                </p>
                <p className="roboto-condensed-bold text-center fontSize-40 margin-contact-mobile">
                  {t("home.mail")}
                </p>
                <p className="roboto-condensed text-center fontSize-25 notice text-underline">
                  <Link to="/notice">{t("home.mention")}</Link>
                </p>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}
