/* RECRUITERS */
export const ALL_RECRUITERS_ADMIN = 'ALL_RECRUITERS_ADMIN';
export const ONE_RECRUITER_ADMIN = 'ONE_RECRUITER_ADMIN';

/* INSPIRNAUTES */
export const ALL_INSPIRNAUTES_ADMIN = 'ALL_INSPIRNAUTES_ADMIN';
export const ONE_INSPIRNAUTE_ADMIN = 'ONE_INSPIRNAUTE_ADMIN';

/* CONDIDATURES */
export const ALL_CANDIDATURES_RECRUITERS_ADMIN = 'ALL_CANDIDATURES_RECRUITERS_ADMIN';
export const ALL_CANDIDATURES_CANDIDATES_ADMIN = 'ALL_CANDIDATURES_CANDIDATES_ADMIN';
export const VALIDATE_CANDIDATURE = 'VALIDATE_CANDIDATURE';
export const DUMP_CANDIDATURE = 'DUMP_CANDIDATURE';

/* SKILLS */
export const ALL_SKILLS_ADMIN = 'ALL_SKILLS_ADMIN';
export const ADD_SKILL_ADMIN = 'ADD_SKILL_ADMIN';
export const EDIT_SKILL_ADMIN = 'EDIT_SKILL_ADMIN';
export const DELETE_SKILL_ADMIN = 'DELETE_SKILL_ADMIN';
export const DUMP_SKILL = 'DUMP_SKILL';

/* TYPE SKILLS */
export const ALL_TYPE_SKILLS_ADMIN = 'ALL_TYPE_SKILLS_ADMIN';
export const ADD_TYPE_SKILL_ADMIN = 'ADD_TYPE_SKILL_ADMIN';
export const EDIT_TYPE_SKILL_ADMIN = 'EDIT_TYPE_SKILL_ADMIN';
export const DELETE_TYPE_SKILL_ADMIN = 'DELETE_TYPE_SKILL_ADMIN';
export const DUMP_TYPE_SKILL = 'DUMP_TYPE_SKILL';

/* DOMAINS RECRUITERS */
export const ALL_DOMAIN_RECRUITER_ADMIN = 'ALL_DOMAIN_RECRUITER_ADMIN';
export const ADD_DOMAIN_RECRUITER_ADMIN = 'ADD_DOMAIN_RECRUITER_ADMIN';
export const EDIT_DOMAIN_RECRUITER_ADMIN = 'EDIT_DOMAIN_RECRUITER_ADMIN';
export const DELETE_DOMAIN_RECRUITER_ADMIN = 'DELETE_DOMAIN_RECRUITER_ADMIN';
export const DUMP_DOMAIN_RECRUITER_ADMIN = 'DUMP_DOMAIN_RECRUITER_ADMIN';

/* MISSIONS RECRUITERS */
export const ALL_MISSIONS_ADMIN = 'ALL_MISSIONS_ADMIN';
export const ONE_MISSION_ADMIN = 'ONE_MISSION_ADMIN';
export const ADD_MISSION_ADMIN = 'ADD_MISSION_ADMIN';
export const DELETE_MISSION_ADMIN = 'DELETE_MISSION_ADMIN';
export const INTERRUPT_MISSION_ADMIN = 'INTERRUPT_MISSION_ADMIN';
export const PROFILES_MISSION_ADMIN = 'PROFILES_MISSION_ADMIN';
export const DUMP_MISSION_ADMIN = 'DUMP_MISSION_ADMIN';

/* MATCHING PROFILES TO PROFILES */
export const MATCH_PROFIL_MISSION_ADMIN = 'MATCH_PROFIL_MISSION_ADMIN';
export const REMOVE_PROFIL_MISSION_ADMIN = 'REMOVE_PROFIL_MISSION_ADMIN';
export const DUMP_PROFIL_MISSION_ADMIN = 'DUMP_PROFIL_MISSION_ADMIN';

/* FOLLOW UP CANDIDATURES */
export const FOLLOWUP_MISSIONS_ADMINISTRATOR = 'FOLLOWUP_MISSIONS_ADMINISTRATOR';
export const FOLLOWUP_INTERVIEWS_ADMINISTRATOR = 'FOLLOWUP_INTERVIEWS_ADMINISTRATOR';

/*Dashboard Admin*/
export const MISSION_STATS = 'MISSION_STATS';
export const USERS_STATS = 'USERS_STATS';
export const CANDIDATE_BY_DAY_STATS = 'CANDIDATE_BY_DAY_STATS';
export const MISSION_BY_DAY_STATS = 'MISSION_BY_DAY_STATS';
export const TOP_RECRUITERS = 'TOP_RECRUITERS';