import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, Image, Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaEnvelope,
  FaFileDownload,
  FaLink,
  FaPhoneAlt,
  FaRegCalendarMinus,
} from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fr } from "date-fns/esm/locale";
import { Context as CosmonauteContext } from "../../../../context/reducers/CosmonauteReducer";
import {
  isAdmin,
  isRecruiter,
} from "../../../../services/Controller/CurrentUserServices";
import { formatDate } from "../../../../services/Controller/DateFormatter";
// import { saveResume } from "../../../../services/Controller/uploadFiles";
import NothingToShow from "../../../../components/NothingToShow/NothingToShow";
import astro from "../../../../assets/img/candidat.png";
import "./Resume.scss";
import { Rating } from "@mui/material";
import AlertModal from "../../../../components/alert/alert.js";
/* Validation File To upload */
const schema = Yup.object().shape({
  file: Yup.mixed().required("File is required"),
});
export default function Resume() {
  /* Context Api */
  const {
    ProfilCosmonaute,
    printResume,
    ResumeUpload,
    GetResume,
    getLogoCandidate,
    state,
  } = useContext(CosmonauteContext);
  /* use Translation */
  const { t } = useTranslation();
  /* use History */
  const history = useHistory();
  /* States */
  const [cosmoDetailProfil, setCosmoDetailProfil] = useState(null);
  const [cosmoExperiences, setCosmoExperiences] = useState(null);
  const [cosmoEducations, setCosmoEducations] = useState(null);
  const [candidateLogo, setCandidateLogo] = useState(null);
  const [cosmoLangues, setCosmoLangues] = useState(null);
  const [skillsProfile, setSkillsProfile] = useState(null);
  const [resume, setresume] = useState(null);
  const [idProfil, setIdProfile] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  /* show alert */
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(null);

  /* eslint-disable */
  useEffect(() => {
    const id = location.pathname.split("/")[3];
    if (id) {
      setIdProfile(id);
      ProfilCosmonaute(id);
      if (!isRecruiter()) {
        getLogoCandidate(id);
        GetResume(id);
      }
    }
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (state.CosmonauteProfil) setCosmoDetailProfil(state.CosmonauteProfil);
    if (state.WorkExperiences) setCosmoExperiences(state.WorkExperiences);
    if (state.educationsCandidate)
      setCosmoEducations(state.educationsCandidate);
    if (state.logoCandidate) setCandidateLogo(state.logoCandidate);
    if (state.resume) setresume(state.resume);
    if (state.Langues) setCosmoLangues(state.Langues);
    if (state.skillsprofil) setSkillsProfile(state.skillsprofil);
    if (state.toastContent) {
      if (
        state.toastContent.type === "Error_CV" ||
        state.toastContent.type === "Success_CV"
      ) {
        setContent({
          type: state.toastContent.type === "Error_CV" ? "error" : null,
          body: state.toastContent ? state.toastContent.body : "",
          refresh: state.toastContent.type === "Success_CV" ? true : false,
        });
        setShow(true);
      }
    }
  }, [state]);

  /* Edit CV Candidate */
  const routeChange = () => {
    if (isAdmin()) {
      history.push(
        t("role.administrator") + "/inspirnautes/" + idProfil + "/edit/resume"
      );
    } else history.push(t("role.user") + "/" + idProfil + "/edit/resume");
  };

  /* Return Back to Profil Candidate */
  const returnBackCosmo = () => {
    history.push(t("role.user") + t("sidebar.profil.path"));
  };

  /* Return back to Admin */
  function returnBackAdmin() {
    history.push(
      t("role.administrator") + t("sidebar.inspirnautes.path") + "/" + idProfil
    );
  }

  /* redirect to link */
  function NewTab(link) {
    if (link) window.open(link, "_blank");
  }

  /* Return back to Recruiter */
  function returnBackRecruiter() {
    if (history.location.state) {
      history.push(
        t("role.recruiter") +
          t("sidebar.missions.path") +
          "/" +
          history.location.state.idMission
      );
    }
  }

  /* Print Resume */
  function print(anonyme) {
    printResume(cosmoDetailProfil, anonyme ? anonyme : null);
  }

  /* save resume */
  // function saveCV() {
  //   saveResume(resume, cosmoDetailProfil).then(
  //     (res) => {
  //       if (res === "FILE_NOT_FOUND") {
  //         setContent({
  //           type: "error",
  //           body: "Fichier non trouvé",
  //           refresh: false
  //         })
  //         setShow(true);
  //       }
  //     });
  // }

  /* refresh resume */
  const refreshResume = () => {
    if (idProfil) {
      GetResume(idProfil);
    }
  };

  /* Modal Upload File */
  function UploadFileModal(modalShow) {
    const fileForm = useFormik({
      initialValues: { file: "", type: "cv" },
      validationSchema: schema,
      onSubmit: (values) => {
        let formData = new FormData();
        formData.append("file", values.file);
        formData.append("type", values.type);
        formData.append("userId", idProfil);
        ResumeUpload(formData);
        setModalShow(false);
      },
    });
    return (
      <>
        <Modal
          size="lg"
          centered
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        >
          <Form onSubmit={fileForm.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Importer votre CV
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Control
                  nama="file"
                  type="file"
                  id="file"
                  onChange={(event) => {
                    fileForm.setFieldValue("file", event.target.files[0]);
                  }}
                  placeholder="Enter your File"
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button className="save-button" type="submit">
                Télécharger
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }

  /* show profile header */
  function ShowResumeHeader() {
    return (
      <>
        <Row className="align-items-center justify-content-center mb-5">
          <Col className="mb-sm-3" lg={4} md={12} xs={12}>
            {isAdmin() ? (
              <div
                className="arrow-back-mission pointer mb-3"
                onClick={returnBackAdmin}
              >
                <i className="icon-back-arrow"></i>
              </div>
            ) : (
              <div className="pointer" onClick={returnBackCosmo}>
                <i className="icon-back-arrow p-3"></i>
                <span className="roboto-light">PROFILE / CV</span>
              </div>
            )}
          </Col>
          <Col
            className="text-align-end display-button-header"
            lg={8}
            md={12}
            xs={12}
          >
            {isAdmin() ? null : (
              <Button
                type="button"
                className="save-button border-white mr-20"
                onClick={() => {
                  setModalShow(true);
                }}
              >
                Importer mon CV
              </Button>
            )}
            <Button
              type="button"
              className="save-button border-white mr-20"
              onClick={() => {
                print(false);
              }}
            >
              Imprimer
            </Button>
            <Button
              type="button"
              className="save-button green"
              onClick={routeChange}
            >
              Modifier le CV
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col lg={2} md={6} xs={12}>
            <Image
              className="w-100 p-18 w-height-image"
              src={candidateLogo ? "http://" + candidateLogo.path : astro}
              roundedCircle
              id="logo-cosmonaute"
            />
          </Col>
          <Col lg={10} md={6} xs={12}>
            <Row className="align-items-center justify-content-center">
              <Col lg={6} md={12} xs={12} className="mt-3">
                <label className="name me-1 mb-1">
                  {cosmoDetailProfil && cosmoDetailProfil.name
                    ? cosmoDetailProfil.name
                    : null}
                </label>
                <label className="name ms-1">
                  {cosmoDetailProfil && cosmoDetailProfil.lastname
                    ? cosmoDetailProfil.lastname
                    : null}
                </label>
                <p className="text-professionl">
                  {cosmoDetailProfil && cosmoDetailProfil.poste
                    ? cosmoDetailProfil.poste
                    : null}
                  ({" "}
                  {cosmoDetailProfil && cosmoDetailProfil.nbrAnneeExp
                    ? cosmoDetailProfil.nbrAnneeExp
                    : null}{" "}
                  ans d'éxperiences )
                </p>
                <p className="place">
                  Région :{" "}
                  {cosmoDetailProfil && cosmoDetailProfil.country
                    ? cosmoDetailProfil.country
                    : null}
                </p>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="text-light mb-3">
                  <FaPhoneAlt className="mr-20" />
                  {cosmoDetailProfil && cosmoDetailProfil.phone
                    ? cosmoDetailProfil.phone
                    : null}
                </div>
                <div className="text-light mb-3">
                  <FaEnvelope className="mr-20" />
                  {cosmoDetailProfil && cosmoDetailProfil.email
                    ? cosmoDetailProfil.email
                    : null}
                </div>
                <div
                  className="text-light mb-3 pointer"
                  onClick={() => {
                    NewTab(cosmoDetailProfil?.link);
                  }}
                >
                  <FaLink className="mr-20" />
                  {cosmoDetailProfil && cosmoDetailProfil.link
                    ? cosmoDetailProfil.link
                    : null}
                </div>
                {resume && resume.path ? (
                  <div className="text-light mb-3">
                    <a
                      className="text-light"
                      download={true}
                      href={"http://" + resume.path}
                      target="blank"
                    >
                      <FaFileDownload className="mr-20" />
                      {isAdmin()
                        ? "CV_" +
                          (cosmoDetailProfil
                            ? cosmoDetailProfil.name +
                              "_" +
                              cosmoDetailProfil.lastname
                            : "")
                        : "Mon CV"}
                    </a>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  /* Show Resume To Recruiter */
  function ShowResumeHeaderToRecruiter() {
    return (
      <>
        <div className="d-flex align-items-center mb-4">
          <div
            className="arrow-back-mission pointer mr-20"
            onClick={returnBackRecruiter}
          >
            <i className="icon-back-arrow"></i>
          </div>
          <div className="text-back">RETOUR</div>
        </div>
        <Row className="align-items-center justify-content-center">
          <Col lg={6} md={6} xs={12}>
            <Row className="align-items-center justify-content-center">
              <Col lg={4} md={12} xs={12}>
                <div className="avatar-profil">
                  {cosmoDetailProfil
                    ? cosmoDetailProfil.name.charAt(0) +
                      cosmoDetailProfil.lastname.charAt(0)
                    : "---"}
                </div>
              </Col>
              <Col lg={8} md={12} xs={12}>
                <div className="text-campany mb-2">
                  {cosmoDetailProfil && cosmoDetailProfil.poste
                    ? cosmoDetailProfil.poste
                    : null}
                </div>
                <div className="text-campany-label mb-3">
                  {cosmoDetailProfil && cosmoDetailProfil.nbrAnneeExp
                    ? cosmoDetailProfil.nbrAnneeExp
                    : 0}{" "}
                  ans d'éxperiences
                </div>
                <div className="text-landing-place">
                  Lieu de débarquement :{" "}
                  {cosmoDetailProfil && cosmoDetailProfil.country
                    ? cosmoDetailProfil.country
                    : null}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Button
              type="button"
              className="save-button border-white mr-20"
              onClick={() => {
                print(true);
              }}
            >
              Télécharger le CV de{" "}
              {cosmoDetailProfil
                ? cosmoDetailProfil.name.charAt(0) +
                  cosmoDetailProfil.lastname.charAt(0)
                : "---"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  /* show profile main */
  function ShowResumeMain() {
    return (
      <Row>
        {/* Experience */}
        {cosmoExperiences && cosmoExperiences.length ? (
          <Row lg={12} md={12} xs={12}>
            {getWorkExperiences()}
          </Row>
        ) : null}
        {/* Education */}
        {cosmoEducations && cosmoEducations.length ? (
          <Row lg={12} md={12} xs={12}>
            {getEducations()}
          </Row>
        ) : null}
        {/* Skills */}
        {skillsProfile && skillsProfile.length ? (
          <Col lg={6} md={12} xs={12}>
            {getAllSkills()}
          </Col>
        ) : null}
        {/* Language */}
        {cosmoLangues && cosmoLangues.length ? (
          <Col lg={4} md={12} xs={12}>
            {getLanguages()}
          </Col>
        ) : null}
      </Row>
    );
  }

  /* EXPÉRIENCE */
  function getWorkExperiences() {
    return (
      <>
        <div className="title-profile mt-5">EXPÉRIENCE PROFESSIONNELLE</div>
        <div className="col-md-6">
          <ul className="timeline">
            {cosmoExperiences
              .slice(0, Math.ceil(cosmoExperiences.length / 2))
              .map((work) => {
                return (
                  <li key={work.id}>
                    <div className="title-resume mb-1">
                      {work.poste ? work.poste : null} /{" "}
                      {work.entreprise ? work.entreprise : null}.
                    </div>
                    <div className="text-detail d-flex mb-2">
                      <FaRegCalendarMinus />
                      <span className="ml-10 mr-20">
                        De:{" "}
                        {work.from_date
                          ? formatDate(work.from_date, fr) + " "
                          : null}
                        - à:{" "}
                        {work.to_date ? formatDate(work.to_date, fr) : null}
                      </span>
                    </div>
                    <p className="description-profile mt-2">
                      {work.description ? work.description : null}
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="timeline">
            {cosmoExperiences
              .slice(Math.ceil(cosmoExperiences.length / 2))
              .map((work) => {
                return (
                  <li key={work.id}>
                    <div className="title-resume mb-1">
                      {work.poste ? work.poste : null} /{" "}
                      {work.entreprise ? work.entreprise : null}.
                    </div>
                    <div className="text-detail d-flex mb-2">
                      <FaRegCalendarMinus />
                      <span className="ml-10 mr-20">
                        De:{" "}
                        {work.from_date
                          ? formatDate(work.from_date, fr) + " "
                          : null}
                        - à:{" "}
                        {work.to_date ? formatDate(work.to_date, fr) : null}
                      </span>
                    </div>
                    <p className="description-profile mt-2">
                      {work.description ? work.description : null}
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    );
  }

  /* LANGUES */
  function getLanguages() {
    return (
      <>
        <div className="title-profile mt-5">LANGUES</div>
        <div className="language">
          {cosmoLangues && cosmoLangues.length
            ? cosmoLangues.map((skill, index) => {
                return (
                  <div className="d-flex" key={"skill" + index}>
                    <label className="text-skill-label mr-20">
                      {skill.name ? skill.name : null}
                    </label>
                    <Rating
                      value={skill.rating ? skill.rating : 0}
                      className="rating-style"
                      disabled
                    />
                  </div>
                );
              })
            : null}
        </div>
      </>
    );
  }

  /* EDUCATION */
  function getEducations() {
    return (
      <>
        <div className="title-profile mt-5">EDUCATION</div>
        <div className="col-md-6">
          {cosmoEducations
            .slice(0, Math.ceil(cosmoEducations.length / 2))
            .map((education, key) => (
              <div key={"education" + key} className="mt-3">
                <div className="title-resume mb-1">
                  {education.degree ? education.degree : null}
                </div>
                <p className="title-resume-company">
                  {education.school ? education.school : null}
                </p>
                <div className="text-detail d-flex mb-2">
                  <FaRegCalendarMinus />
                  <span className="ml-10 mr-20">
                    De:{" "}
                    {education.fromDateEdu
                      ? formatDate(education.fromDateEdu, fr) + " "
                      : null}
                    - à:{" "}
                    {education.toDateEdu
                      ? formatDate(education.toDateEdu, fr)
                      : null}
                  </span>
                </div>
                <p className="description-profile mt-2">
                  {education.descriptionEdu ? education.descriptionEdu : null}
                </p>
              </div>
            ))}
        </div>
        <div className="col-md-6">
          {cosmoEducations
            .slice(Math.ceil(cosmoEducations.length / 2))
            .map((education, key) => (
              <div key={"education" + key} className="mt-3">
                <div className="title-resume mb-1">
                  {education.degree ? education.degree : null}
                </div>
                <p className="title-resume-company">
                  {education.school ? education.school : null}
                </p>
                <div className="text-detail d-flex mb-2">
                  <FaRegCalendarMinus />
                  <span className="ml-10 mr-20">
                    De:{" "}
                    {education.fromDateEdu
                      ? formatDate(education.fromDateEdu, fr) + " "
                      : null}
                    - à:{" "}
                    {education.toDateEdu
                      ? formatDate(education.toDateEdu, fr)
                      : null}
                  </span>
                </div>
                <p className="description-profile mt-2">
                  {education.descriptionEdu ? education.descriptionEdu : null}
                </p>
              </div>
            ))}
        </div>
      </>
    );
  }

  /* COMPÉTENCES */
  function getAllSkills() {
    return (
      <>
        <div className="title-profile mt-5">COMPÉTENCES</div>
        <>
          {skillsProfile.map((typeSkill, key) => {
            return (
              <div key={key}>
                <label className="title-resume mb-2">
                  {typeSkill.type ? typeSkill.type : null}
                </label>
                {typeSkill &&
                  typeSkill.skills.length &&
                  typeSkill.skills.map((skill, index) => {
                    return (
                      <div className="d-flex" key={"skill" + index}>
                        <label className="text-skill-label mr-20">
                          {skill.skill ? skill.skill : null}
                        </label>
                        <Rating
                          value={skill.rating ? skill.rating : 0}
                          className="rating-style"
                          disabled
                        />
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </>
      </>
    );
  }

  return (
    <>
      {/* Alert */}
      <AlertModal
        show={show}
        setShow={setShow}
        content={content ? content.body : ""}
        type={content ? content.type : ""}
        isRefresh={content ? content.refresh : false}
        refreshResume={refreshResume}
      />
      {UploadFileModal(modalShow)}
      <div id="resumePage">
        <div id="header-Content" className="header-Content">
          {isRecruiter() ? ShowResumeHeaderToRecruiter() : ShowResumeHeader()}
        </div>
        <div id="main" className="main">
          {!cosmoExperiences?.length &&
          !cosmoEducations?.length &&
          !skillsProfile?.length &&
          !cosmoLangues?.length ? (
            <NothingToShow />
          ) : (
            <ShowResumeMain />
          )}
        </div>
      </div>
    </>
  );
}
