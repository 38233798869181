import {
  API_HOST,
  API_CANDIDATURES_RECRUITER_ADMINISTRATOR,
  API_CANDIDATURES_PROFIL_ADMINISTRATOR,
  API_VALIDATION_CONDIDATURES_ADMINISTRATOR,
} from "../../Api";
import { SHOW_TOAST } from "../constants/Toast";
import {
  ALL_CANDIDATURES_RECRUITERS_ADMIN,
  ALL_CANDIDATURES_CANDIDATES_ADMIN,
  VALIDATE_CANDIDATURE,
  DUMP_CANDIDATURE,
} from "../constants/Administrator";
import axiosServices from "../../services/Axios/axiosServices";

/* Get list Candidatures recruiters for Admin */
const getAllRecruitersCandidatures = (dispatch) => () => {
  // URL get list Candidatures recruiters for Admin
  const URL = API_HOST + API_CANDIDATURES_RECRUITER_ADMINISTRATOR;
  // Call API list Candidatures recruiters for Admin
  axiosServices.get(URL).then(
    (res) => {
      dispatch({
        type: ALL_CANDIDATURES_RECRUITERS_ADMIN,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération de la liste Candidatures recruteurs",
        },
      });
    }
  );
};

/* Get list Candidatures profils for Admin */
const getAllProfilsCandidatures = (dispatch) => () => {
  // URL get list Candidatures profils for Admin
  const URL = API_HOST + API_CANDIDATURES_PROFIL_ADMINISTRATOR;
  // Call API list Candidatures profils for Admin
  axiosServices.get(URL).then(
    (res) => {
      dispatch({
        type: ALL_CANDIDATURES_CANDIDATES_ADMIN,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération de la liste condidatures candidates",
        },
      });
    }
  );
};

/* Validate Candidature By Admin */
const validateCandidature = (dispatch) => (body) => {
  // URL Validate Candidature By Admin
  const URL =
    API_HOST +
    API_VALIDATION_CONDIDATURES_ADMINISTRATOR +
    body.action +
    "/" +
    body.id;
  // Call API Validate Candidature By Admin
  axiosServices.post(URL).then(
    (res) => {
      dispatch({
        type: VALIDATE_CANDIDATURE,
        payload: body.candidature,
        toastContent: {
          status: "success",
          body:
            "Candidature " +
            (body.action === "accept" ? "acceptée" : "refusée") +
            " avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Une erreur est survenue" },
      });
    }
  );
};

/* Dump Candidature */
const dumpCandidature = (dispatch) => () => {
  dispatch({ type: DUMP_CANDIDATURE });
};

/* Export Action Candidatures for Administrator */
export {
  getAllRecruitersCandidatures,
  getAllProfilsCandidatures,
  validateCandidature,
  dumpCandidature,
};
