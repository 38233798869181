import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Context as MissionContext } from '../../context/reducers/RecruiterReducer'
import { ErrorFeild, ErrorClass } from '../../services/Controller/ValidationForm';
import './ModalMission.scss';
import Rating from "@mui/material/Rating";
import { addDays, format } from "date-fns";
/* Date of ToDay */
const today = new Date();
/* validation FormGroup */
const validationSchema = Yup.object({
    title: Yup.string().required('Champ obligatoire'),
    nbrYearsExperience: Yup.number().min(0).required("Champ obligatoire"),
    place: Yup.string().required('Champ obligatoire'),
    dateEnd: Yup.date().required('Champ obligatoire'),
    dateStart: Yup.date()
        .when("dateEnd", (dateEnd, schema) => dateEnd && schema.max(dateEnd, "La date de fin ne peut pas être antérieure à la date de début"))
        .required('Champ obligatoire'),
    description: Yup.string().required('Champ obligatoire'),
    skills: Yup.array().min(1, "Champ obligatoire").required('Champ obligatoire'),
});

/* Modal Mission : Add | Edit */
export const ModalMission = ({ modalShow, setModalShow, edit, setEdit, addMission, editMission }) => {
    /* Context Api */
    const { getAllSkills, getListRegions, state } = useContext(MissionContext);

    /* State Skills */
    const [allSkills, setAllSkills] = useState([]);
    const [allSkillSup, setAllSkillsSup] = useState([]);
    const [allRegions, setAllRegions] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        getAllSkills()
        getListRegions().then((res) => { setAllRegions(res.data) });
    }, [])
    /*eslint-enable */
    useEffect(() => {
        if (state.allSkills) {
            const list = state.allSkills.map((el) => {
                return { value: el.id, label: el.name, rating: 0, priority: 1 }
            })
            const listSuplementaire = state.allSkills.map((el) => {
                return { value: el.id, label: el.name, rating: 0, priority: 0 }
            })
            setAllSkills(list);
            setAllSkillsSup(listSuplementaire)
        }
    }, [state]);
    /* CK Editor */
    const editorRef = useRef();
    const [editorLoaded, setEditorLoaded] = useState(false);
    const { CKEditor, ClassicEditor } = editorRef.current || {};
    /* Form Mission */
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: edit ? edit.title : "",
            nbrYearsExperience: edit ? edit.nbrYearsExperience : "",
            place: edit ? edit.place : "",
            dateStart: edit ? edit.dateStart : "",
            dateEnd: edit ? edit.dateEnd : "",
            description: edit ? edit.description : "",
            renewable: edit ? edit.renewable : "",
            skills: edit && edit.skills && edit.skills.length
                ? edit.skills.filter(item => item.priority === 1).map((el) => {
                    return { value: el.id, label: el.name, rating: el.rating, priority: el.priority }
                })
                : [],
            skillSup: edit && edit.skills && edit.skills.length
                ? edit.skills.filter(item => item.priority === 0).map(el => {
                    return { value: el.id, label: el.name, rating: el.rating, priority: el.priority }
                })
                : [],
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            var data = {
                dateEnd: values.dateEnd,
                dateStart: values.dateStart,
                description: values.description,
                renewable: values.renewable,
                nbrYearsExperience: values.nbrYearsExperience,
                place: values.place,
                title: values.title,
                skills: values.skills,
                skillSup: values.skillSup
            }
            if (edit) editMission(data, edit.id);
            else addMission(data);
            handleClose();
        }
    })
    /* Close Modal Mission */
    const handleClose = () => {
        setModalShow(false);
        formik.resetForm();
        if (edit) setEdit(null);
    };
    /*eslint-disable */
    useEffect(() => {
        editorRef.current = {
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
        }
        setEditorLoaded(true)
    }, []);
    /*eslint-enable */

    /* Raiting By Element */
    function FlexboxPage() {
        return (
            <Row className="mt-3 mb-3">
                {formik.values.skills.map((el, index1) => {
                    return (
                        <Col className="mb-2" lg={6} md={6} xs={12} key={"skillRate" + index1}>
                            <div className="d-flex dropdown-skills p-1 mb-2">
                                <div className="flex-grow-1 text-skills align-self-center">
                                    {el.label}
                                </div>
                                <div className="align-self-center">
                                    <Rating
                                        name="rating"
                                        value={el.rating}
                                        defaultValue={0}
                                        precision={0.5}
                                        className="rating-style"
                                        onChange={(event, value) => {
                                            formik.setFieldValue(`skills[${index1}].rating`, value);
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        );
    }
    /* Raiting By Element */
    function Flexbox() {
        return (
            <Row className="mt-3 mb-3">
                {formik.values.skillSup.map((el, index2) => {
                    return (
                        <Col className="mb-2" lg={6} md={6} xs={12} key={"skillRate" + index2}>
                            <div className="d-flex dropdown-skills p-1 mb-2">
                                <div className="flex-grow-1 text-skills align-self-center">
                                    {el.label}
                                </div>
                                <div className="align-self-center">
                                    <Rating
                                        name="rating"
                                        value={el.rating}
                                        defaultValue={0}
                                        precision={0.5}
                                        className="rating-style"
                                        onChange={(event, value) => {
                                            formik.setFieldValue(`skillSup[${index2}].rating`, value);
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        );
    }
    return (
        <>
            <Modal show={modalShow} onHide={handleClose} size="lg" centered >
                <Modal.Header closeButton className="border-0 text-black">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {!edit ? 'Ajouter une mission' : 'Modifier une mission'}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body className="border-0 text-black">
                        <Row>
                            <Col>
                                <Form.Group className="formGroup">
                                    <Form.Label className="text-black required">Titre</Form.Label>
                                    <Form.Control className={ErrorClass(formik, 'title')} type="string"
                                        name="title" id="title"
                                        onChange={formik.handleChange}
                                        value={formik.values.title}
                                        onBlur={formik.handleBlur} />
                                    {ErrorFeild(formik, 'title')}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="formGroup">
                                    <Form.Label className="text-black required">Années d'expériences</Form.Label>
                                    <Form.Control className={ErrorClass(formik, 'nbrYearsExperience')} type="number"
                                        name="nbrYearsExperience"
                                        id="nbrYearsExperience"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.nbrYearsExperience} />
                                    {ErrorFeild(formik, 'nbrYearsExperience')}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="formGroup pt-4">
                            <Form.Label className="text-black required">Compétences obligatoires</Form.Label>
                            <Select isMulti isSearchable placeholder=""
                                className={ErrorClass(formik, 'skills')}
                                name="skills" id="skills"
                                options={allSkills}
                                closeMenuOnSelect={true}
                                value={formik.values.skills}
                                onChange={(event) => { formik.setFieldValue("skills", event) }}
                            />
                            {ErrorFeild(formik, 'skills')}
                            {/* add rating to skill */}
                            <FlexboxPage />
                        </Form.Group>
                        <Form.Group className="formGroup">
                            <Form.Label className="text-black">Compétences supplémentaires</Form.Label>
                            <Select isMulti isSearchable placeholder=""
                                className={ErrorClass(formik, 'skills')}
                                name="skillSup" id="skillSup"
                                options={allSkillSup}
                                closeMenuOnSelect={true}
                                value={formik.values.skillSup}
                                onChange={(event) => { formik.setFieldValue("skillSup", event) }}
                            />
                            {ErrorFeild(formik, 'skills')}
                            {/* add rating to skill */}
                            <Flexbox />
                        </Form.Group>
                        <Form.Group className="formGroup">
                            <Form.Label className="text-black required">Région</Form.Label>
                            <Form.Select name="place" id="place"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.place}>
                                <option className="color-black" value={""}> Séléctionnez une région </option>
                                {
                                    allRegions && allRegions.length
                                        ? allRegions.map((el, index) => {
                                            return (
                                                <option className="color-black" key={"option" + index} value={el.nom}>
                                                    {el.nom}
                                                </option>
                                            )
                                        })
                                        : null
                                }
                            </Form.Select>
                            {ErrorFeild(formik, 'place')}
                        </Form.Group>
                        <Row>
                            <Form.Group as={Col} className="formGroup pt-4">
                                <Form.Label className="text-black required">Date début</Form.Label>
                                <Form.Control className={ErrorClass(formik, 'dateStart')} type="date"
                                    name="dateStart"
                                    id="dateStart"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    min={format(addDays(today,1), "yyyy-MM-dd")}
                                    value={formik.values.dateStart.slice(0, 10)} />
                                {ErrorFeild(formik, 'dateStart')}
                            </Form.Group>
                            <Form.Group as={Col} className="formGroup pt-4">
                                <Form.Label className="text-black required">Date fin</Form.Label>
                                <Form.Control className={ErrorClass(formik, 'dateEnd')} type="date"
                                    name="dateEnd"
                                    id="dateEnd"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.dateEnd.slice(0, 10)} />
                                {ErrorFeild(formik, 'dateEnd')}
                            </Form.Group>
                        </Row>
                        <Form.Group className="pt-4">
                            <Form.Label>Période de renouvèlement</Form.Label>
                            <Form.Select name="renewable" id="renewable" className={ErrorClass(formik, 'renewable')}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.renewable}>
                                <option className="color-black text-bold" value=""> Séléctionnez une période </option>
                                <option className="color-black" value="1 mois renouvelable">1 mois renouvelable</option>
                                <option className="color-black" value="3 mois renouvelables">3 mois renouvelables</option>
                                <option className="color-black" value="6 mois renouvelables">6 mois renouvelables</option>
                                <option className="color-black" value="1 an renouvelable"> 1 an renouvelable </option>
                                <option className="color-black" value="2 ans renouvelables"> 2 ans renouvelables</option>
                                <option className="color-black" value="3 ans renouvelables">3 ans renouvelables</option>
                            </Form.Select>
                            {ErrorFeild(formik, 'renewable')}
                        </Form.Group>
                        <Form.Label className="text-black required pt-4">Décrivez la mission et ce que vous recherchez chez le candidat</Form.Label>
                        {editorLoaded
                            ? <CKEditor
                                className={ErrorClass(formik, 'description')}
                                editor={ClassicEditor}
                                data={edit ? edit.description : formik.values.description}
                                onReady={editor => { }}
                                onChange={(event, editor) => { formik.setFieldValue("description", editor.getData()) }}
                            />
                            : <p>loading...</p>}
                        {ErrorFeild(formik, 'description')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="save-button grey shadow-unset" onClick={handleClose}>Annuler</Button>
                        <Button className="save-button green shadow-unset" type="submit">{!edit ? 'Ajouter' : 'Modifier'}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
