import {
    format, addDays,
    differenceInYears, differenceInMonths, differenceInDays, differenceInHours, differenceInMinutes,
    intervalToDuration
} from "date-fns";
import { fr } from 'date-fns/esm/locale';

/*  Format Date As yyyy-MM-dd */
function formatDate(date, locale = null) {
    if (date) {
        if (locale) return format(new Date(date), "dd MMMM yyyy", { locale: locale });
        else return format(new Date(date), "yyyy-MM-dd");
    }
    else return null;
}

/* Format Date As dd/MMM/yyyy */
function formatDy(date) {
    if (date) {
        return format(new Date(date), "dd-MMM-yyyy");
    }
    else return null;
}

/* Format Date As HH:mm */
function formatTime(date) {
    if (date) {
        return format(new Date(date), "HH:mm");
    }
    else return null;
}

/* Format Date As Date Time */
function formatDateTime(date) {
    if (date) {
        return format(new Date(date), "dd MMMM yyyy à HH:mm", { locale: fr });
    }
    else return null;
}

/*  Format Date As yyyy-MM-dd */
function formatDateSafari(date, locale = null) {
    if (date) {
        if (locale) return format(new Date(date), "dd MMMM yyyy", { locale: locale });
        else return ((format(new Date(date), "dd/MM/yyyy")).toString());
    }
    else return null;
}

/* Format Date As Periode */
function getPeriode(date) {
    let result = { type: "", periode: 0 }
    const dateNow = new Date();
    const dateToCalcul = new Date(date);
    const periodeInYears = differenceInYears(dateNow, dateToCalcul);
    if (periodeInYears) result = { type: "ans", periode: periodeInYears };
    else {
        const periodeInMonths = differenceInMonths(dateNow, dateToCalcul);
        if (periodeInMonths) result = { type: "mois", periode: periodeInMonths };
        else {
            const periodeInDays = differenceInDays(dateNow, dateToCalcul);
            if (periodeInDays) result = { type: "jour(s)", periode: periodeInDays };
            else {
                const periodeInHours = differenceInHours(dateNow, dateToCalcul);
                if (periodeInHours) result = { type: "heure(s)", periode: periodeInHours };
                else {
                    const periodeInMinutes = differenceInMinutes(dateNow, dateToCalcul);
                    result = { type: "minute(s)", periode: periodeInMinutes };
                }
            }
        }
    }
    return result;
}

/* Format Dates as Duration */
const getDuration = (start, end) => {
    let result = intervalToDuration({ start: new Date(start), end: new Date(end) })
    return (
        <>
            {result.years ? (result.years + " ans ") : null}
            {result.months ? (result.months + " mois ") : null}
            {result.jours ? (result.jours + " jours") : null}
        </>
    );
}

/* adding a date starting after "nb" number of days from "date" */
const addDay = (date, nb) => {
    format(new Date(date), "yyyy-MM-dd")
    return addDays(date, nb)
}

export { formatDate, formatTime, getPeriode, formatDateTime, getDuration ,formatDy, formatDateSafari, addDay}
