import {
    API_HOST,
    API_ALL_MISSIONS_RECRUITER, API_ONE_MISSION_RECRUITER, API_PROPOSED_MISSION_RECRUITER, API_REMOVE_PROFILE_MESSION_RECRUITER,
    API_ADD_NEW_MISSION, API_EDIT_MISSION, API_DELETE_MISSION_PATH, API_INTERRUPT_MISSION_RECRUITER,
    API_RECRUTE_CANDIDATE_MISSION, API_REJECT_CANDIDATE_MISSION
} from "../../Api";
import {
    ALL_MISSIONS_RECRUITER, ONE_MISSION_RECRUITER, PROPOSED_PROFILES_MISSION_RECRUITER, REMOVE_PROFIL_MISSION_RECRUITER,
    REJECT_CANDIDATE, RECRUTE_CANDIDATE,
    ADD_NEW_MISSION, EDIT_MISSION, DELETE_MISSION, INTERRUPT_MISSION, DUMP_MISSION
} from "../constants/Recruiter"
import { SHOW_TOAST } from "../constants/Toast";
import axiosServices from "../../services/Axios/axiosServices";
import { getUserId } from "../../services/Controller/CurrentUserServices";

/* get All Missions Recruiter */
const getAllMissionsRecruiter = dispatch => () => {
    const url = API_HOST + API_ALL_MISSIONS_RECRUITER + getUserId();
    axiosServices.get(url).then(
        (profil) => {
            dispatch({ type: ALL_MISSIONS_RECRUITER, payload: profil.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste missions" }
            });
        }
    );
}

/* get One Mission Recruiter */
const getOneMissionRecruiter = dispatch => (id) => {
    const url = API_HOST + API_ONE_MISSION_RECRUITER + id;
    axiosServices.get(url).then(
        (profil) => {
            dispatch({ type: ONE_MISSION_RECRUITER, payload: profil.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération du détail mission" }
            });
        }
    );
}

/* get One Mission Recruiter */
const getProposedProfilesMission = dispatch => (id) => {
    const url = API_HOST + API_PROPOSED_MISSION_RECRUITER + id;
    axiosServices.get(url).then(
        (profil) => {
            dispatch({ type: PROPOSED_PROFILES_MISSION_RECRUITER, payload: profil.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste des profiles proposés" }
            });
        }
    );
}

/* ADD NEW MISSION */
const addMission = (dispatch) => async (data) => {
    const dataSkills = [...data.skills,...data.skillSup];
    const body = {
        title: data.title,
        description: data.description,
        nbrYearExperience: data.nbrYearsExperience,
        place: data.place,
        dateStart: data.dateStart.toString(),
        dateEnd: data.dateEnd.toString(),
        renewable: data.renewable,
        skills: dataSkills.map((el) => {return{id: el.value, rating: el.rating, priority: el.priority}})
    }
    const url = API_HOST + API_ADD_NEW_MISSION + getUserId();
    axiosServices.post(url, body).then(
        (response) => {
            dispatch({
                type: ADD_NEW_MISSION,
                payload: response.data,
                toastContent: { status: "success", body: "Mission est ajoutée avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: "error", body: "Echec d'ajout mission" }
            });
        });
}

/* Edit  MISSION */
const editMission = (dispatch) => async (data, id) => {
    const dataSkills = [...data.skills,...data.skillSup];
    const body = {
        title: data.title,
        description: data.description,
        nbrYearExperience: data.nbrYearsExperience,
        place: data.place,
        dateStart: data.dateStart.toString(),
        dateEnd: data.dateEnd.toString(),
        renewable: data.renewable,
        skills: dataSkills.map((el) => { return {id : el.value, rating : el.rating, priority : el.priority}})
    }
    const url = API_HOST + API_EDIT_MISSION + id;
    try {
        const response = await axiosServices.post(url, body)
        dispatch({
            type: EDIT_MISSION,
            payload: response.data,
            toastContent: { status: 'success', body: "Mission est modifié avec succès" }
        });
    } catch (error) {
        dispatch({
            type: SHOW_TOAST,
            toastContent: { status: 'error', body: "Echec de modification mission" }
        });
    }
}

/* DELETE  MISSION */
const deleteMission = (dispatch) => async (id) => {
    const url = API_HOST + API_DELETE_MISSION_PATH + id
    try {
        const response = await axiosServices.delete(url)
        dispatch({
            type: DELETE_MISSION,
            payload: response,
            toastContent: { status: 'success', body: "Mission supprimée avec succès" }
        });
    }
    catch (error) {
        dispatch({
            type: SHOW_TOAST,
            toastContent: { status: 'error', body: "Echec de suppression mission" }
        });
    }
}

/* INTERRUPT  MISSION */
const interruptMission = (dispatch) => (id) => {
    // Url Interrupt Mission by id
    const url = API_HOST + API_INTERRUPT_MISSION_RECRUITER + id;
    // Call Api Interrupt Mission
    axiosServices.post(url).then(
        (response) => {
            dispatch({
                type: INTERRUPT_MISSION,
                payload: response.data,
                toastContent: { status: 'success', body: "Mission est interrompue avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'interromption mission" }
            });
        });
}

/* REMOVE PROFIL FROM MISSION */
const removeProfileFromMission = (dispatch) => (id) => {
    // Url Remove profile from Mission
    const url = API_HOST + API_REMOVE_PROFILE_MESSION_RECRUITER + id;
    // Call Api Removing profil from mission
    axiosServices.delete(url).then(
        (response) => {
            dispatch({
                type: REMOVE_PROFIL_MISSION_RECRUITER,
                payload: response.data,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de retrait profile de cette mission" }
            });
        });
}

/* REJECT CANDIDATE */
const rejectCandidate = (dispatch) => (id) => {
    // Url Rejecting profile
    const url = API_HOST + API_REJECT_CANDIDATE_MISSION + id;
    // Call Api Rejecting profiL
    axiosServices.put(url).then(
        (response) => {
            dispatch({
                type: REJECT_CANDIDATE,
                payload: response.data,
                toastContent: { status: 'success', body: "Candidat est rejeté avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de rejet candidat" }
            });
        });
}

/* RECRUTE CANDIDATE */
const recruteCandidate = (dispatch) => (id) => {
    // Url Recruting profile
    const url = API_HOST + API_RECRUTE_CANDIDATE_MISSION + id;
    // Call Api Recruting profiL
    axiosServices.put(url).then(
        (response) => {
            dispatch({
                type: RECRUTE_CANDIDATE,
                payload: response.data,
                toastContent: { status: 'success', body: "Candidat est recruté avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de recrutement candidat" }
            });
        });
}

/* DUMP  MISSION */
const dumpMission = (dispatch) => () => {
    dispatch({ type: DUMP_MISSION });
}

export {
    addMission, editMission, deleteMission, interruptMission, dumpMission,
    getAllMissionsRecruiter, getOneMissionRecruiter, getProposedProfilesMission, removeProfileFromMission,
    rejectCandidate, recruteCandidate
}
