import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './Paginate.scss'


export default function Paginate({ items, setCurrentItems, itemsPerPage }) {
    /* States Declaration */
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    /*  */
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [items, setCurrentItems, itemOffset, itemsPerPage]);

    /* Invoke when user click to request another page. */
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            {
                items.length > itemsPerPage
                    ? <div className='paginate'>
                        <ReactPaginate
                            containerClassName="pagination justify-content-center"
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={10}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            activeClassName="active"
                        />
                    </div>
                    : null
            }
        </>
    );
};

