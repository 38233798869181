import Axios from "../../services/Axios/axiosServices";
import {GET_ALL_INTERVIEWS, DECLINE_INTERVIEW_COSMONAUTE, DUMP_INTERVIEW,ACCEPT_INTERVIEW_COSMONAUTE,CHANGE_DATE_COSMONAUTE} from "../constants/Cosmonaute";
import {
    API_ALL_INTERVIEW_COSMONAUTE,
    API_HOST,
    API_DECLINE_INTERVIEW,
    API_ACCEPT_INTERVIEW_COSMONAUTE,
    API_CHANGE_DATE_COSMONAOTE
} from "../../Api";
import {getUserId} from "../../services/Controller/CurrentUserServices";
import {SHOW_TOAST} from "../constants/Toast";

const GET_ALL_INTERVIEW = (dispatch) => async () => {
    const URL = API_HOST + API_ALL_INTERVIEW_COSMONAUTE + getUserId();
    try {
        const response = await Axios.get(URL);
        dispatch({type: GET_ALL_INTERVIEWS, payload: response.data});
    } catch (e) {
        dispatch({
            type: SHOW_TOAST,
            toastContent: {status: 'error', body: "Echec de modification mission"}
        });
    }
}

/* Decline Interview */
const declineInterview = (dispatch) => (data, id) => {
    const url = API_HOST + API_DECLINE_INTERVIEW + id
    Axios.put(url, data).then(
        (res) => {
            dispatch({
                type: DECLINE_INTERVIEW_COSMONAUTE,
                payload: res.data,
                toastContent: {status: 'success', body: "Entretien est rejeté avec succès"}
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: {status: 'error', body: "Echec de modification"}
            });
        })
}

/* Accept Interview */
const acceptInterviewCosmonaute = (dispatch) => (data, id) => {
    const url = API_HOST + API_ACCEPT_INTERVIEW_COSMONAUTE + id
    Axios.put(url, data).then((res) => {
        dispatch({
            type: ACCEPT_INTERVIEW_COSMONAUTE,
            payload: res.data,
            toastContent: {status: 'success', body: "date entretien enregistrer avec succes"}
        });
    }, (err) => {
        dispatch({
            type: SHOW_TOAST,
            toastContent: {status: 'error', body: "Echec de modification"}
        });
    })
}

/* Request Change Date Interview */
const changeDateCosmonaute=(dispatch)=>(data,id)=>{
    const url = API_HOST + API_CHANGE_DATE_COSMONAOTE + id
    Axios.put(url, data).then((res) => {
        dispatch({
            type: CHANGE_DATE_COSMONAUTE,
            payload: res.data,
            toastContent: {status: 'success', body: "date entretien changer avec succes"}
        });
    }, (err) => {
        dispatch({
            type: SHOW_TOAST,
            toastContent: {status: 'error', body: "Echec de modification"}
        });
    })
}
/* Dump Interview */
const dumpInterview = (dispatch) => () => {
    dispatch({
        type: DUMP_INTERVIEW,
        payload: null,
        toastContent: null
    });
}


export {
    GET_ALL_INTERVIEW,
    declineInterview,
    dumpInterview,
    acceptInterviewCosmonaute,
    changeDateCosmonaute
}