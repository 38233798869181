import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import {
  ErrorClass,
  ErrorFeild,
} from "../../services/Controller/ValidationForm";
import Select, { components } from "react-select";

const validationdeclineForm = Yup.object({
  place: Yup.string().required("lieu obligatoire "),
  skills: Yup.array().required("compétence est obligatoire"),
});

export const MissionFavorite = ({
  modalFavorite,
  setModalFavorite,
  postMissionFavorite,
  detail,
  missionFavoriteData,
}) => {
  const skillsOptions = detail?.skills
    ? detail?.skills.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const lenghtMission = missionFavoriteData ? missionFavoriteData.length : 0;
  function lengthArray() {
    let lenghtMissionArray = 0;
    if (lenghtMission === 0) {
      lenghtMissionArray = 0;
    } else if (lenghtMission === 1) {
      lenghtMissionArray = 0;
    } else {
      lenghtMissionArray = lenghtMission - 1;
    }
    return lenghtMissionArray;
  }

  const skillsValue =
    missionFavoriteData && missionFavoriteData[lengthArray()]
      ? missionFavoriteData[lengthArray()]?.skills.map((item) => {
          return { value: item?.id, label: item?.label };
        })
      : [];
  /* Form Group Decline Interview */
  const contactForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      place:
        missionFavoriteData && missionFavoriteData[lengthArray()]
          ? missionFavoriteData[lengthArray()].place
          : "",
      missionType:
        missionFavoriteData && missionFavoriteData[lengthArray()]
          ? missionFavoriteData[lengthArray()].missionType
          : "",
      skills: skillsValue ? skillsValue : [],
    },
    validationSchema: validationdeclineForm,
    onSubmit: (values) => {
      let allSelectedSkills = [];
      values.skills.forEach((selected) => {
        allSelectedSkills.push({ id: selected.value });
      });
      const data = {
        skills: allSelectedSkills,
        place: values.place,
        missionType: values.missionType,
      };
      postMissionFavorite(data);
      contactForm.resetForm();
      setModalFavorite(false);
    },
  });

  /* Footer Decline */
  function ConfirmForm() {
    return (
      <>
        <Button className="save-button blue shadow-unset mr-64" type="submit">
          Confirmer
        </Button>
        <Button
          className="save-button red shadow-unset"
          type="button"
          onClick={() => {
            setModalFavorite(false);
            contactForm.resetForm();
          }}
        >
          Annuler
        </Button>
      </>
    );
  }
  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 3 ? props.children : null}
      </components.Menu>
    );
  };
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 3;
  return (
    <>
      <Modal
        show={modalFavorite}
        size="xl"
        centered
        onHide={() => {
          setModalFavorite(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-title"
          >
            Mission préférée
          </Modal.Title>
        </Modal.Header>
        {/* Modal Content */}
        {/* {FormContact()} */}
        <Form onSubmit={contactForm.handleSubmit}>
          <Modal.Body>
            {/* Card of Interview information */}
            <div className="text_test mb-2">
              <span>Nom et prénom : </span>
              <span>{detail?.personalInfos[0].name + " "}</span>
              <span>{detail?.personalInfos[0].lastname}</span>
            </div>
            <div className="text_test mb-4">
              <span>Email : </span>
              <span>{detail?.personalInfos[0].email}</span>
            </div>
            {/* Form Decline */}
            <div className="d-flex justify-content-between">
              <div className="text_test">Mission : </div>
              <Form.Check
                type="radio"
                label="Sur site"
                id="mission_site"
                name="missionType"
                value="Sur site"
                className="text-Label-modal form-label"
                checked={"Sur site" === contactForm.values.missionType}
                onChange={contactForm.handleChange}
              />

              <Form.Check
                type="radio"
                label="Télétravail"
                id="remote"
                name="missionType"
                value="Télétravail"
                className="text-Label-modal form-label"
                onChange={contactForm.handleChange}
                checked={"Télétravail" === contactForm.values.missionType}
              />
              <Form.Check
                type="radio"
                label="Mixte"
                value="Mixte"
                id="mixte"
                name="missionType"
                className="text-Label-modal form-label"
                onChange={contactForm.handleChange}
                checked={"Mixte" === contactForm.values.missionType}
              />
            </div>
            <Form.Group className="mt-2 mb-2">
              <Form.Label className="text-Label-modal required">
                Lieu de la mission
              </Form.Label>
              <Form.Control
                className={ErrorClass(contactForm, "place")}
                as="input"
                name="place"
                id="place"
                placeholder="Saisir le lieu..."
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.place}
              />
              {ErrorFeild(contactForm, "object")}
            </Form.Group>
            <Form.Group className="mb-2 formGroup">
              <Form.Label className="text-black">
                Compétences préférées
              </Form.Label>
              <Select
                isMulti
                isSearchable
                placeholder=""
                className={ErrorClass(contactForm, "skills mb-5")}
                name="skills"
                id="skills"
                options={skillsOptions}
                closeMenuOnSelect={false}
                value={contactForm.values.skills}
                onChange={(event) => {
                  contactForm.setFieldValue("skills", event);
                }}
                onBlur={contactForm.handleBlur}
                isValidNewOption={isValidNewOption}
                components={{ Menu }}
              />
              {ErrorFeild(contactForm, "skills")}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ConfirmForm />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
