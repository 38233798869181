import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import "./TermsConditions.scss";
import terms from "../../assets/img/inscription/Terms.png";
import etoile from "../../assets/img/inscription/etoile.png";
import astornaute from "../../assets/img/inscription/overlay-propos.png";
import { Link } from 'react-router-dom';
export default function TermsConditions() {
  const { t } = useTranslation();

  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      {/*  */}
      {/* Section 1 */}
      <section className="terms termsLegale">
        <Header />
        <img src={etoile} className="etoile" alt="etoile"></img>
        <img src={terms} alt="planet" className="terms-planet1"></img>
        <ScrollAnimation animateIn="flipInY" animateOut="flipOutY">
          <div className="text-center fontSize-120 text-transparent text-uppercase roboto-black padding-titre text-spacing-1">
            {t("notices.notices-title")}
          </div>
        </ScrollAnimation>
        <img src={terms} alt="planet" className="terms-planet2"></img>
        <img src={terms} alt="planet" className="terms-planet3"></img>
        <div className="stars"></div>
        <ScrollAnimation animateIn="tada" initiallyVisible={true} className="padding-icon" >
          <div className="scroll-downs mt-5">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </ScrollAnimation>
      </section>
      {/* Section 2 */}
      <section className="conditions conditions-auto-page">
        {/*  Definition */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content1.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content1.description1")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content1.description2")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content1.description3")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content1.description4")} </div>
          </div>
        </div>
        {/*  Acceptation préalable, pure et simple par tout Utilisateur du Site */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content2.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content2.description1")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content2.description2")} </div>
          </div>
        </div>
        {/* Accès au Site */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content3.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content3.description1")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content3.description2")} </div>
          </div>
        </div>
      </section>
      {/* Section 3 */}
      <section className="conditions-page1 conditions-auto-page">
        {/* Droits sur le Site et ses contenus */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content4.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content4.description1")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content4.description2")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content4.description3")} </div>
          </div>
        </div>
        {/* Liens hypertexte */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content5.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content5.description1")} </div>
          </div>
        </div>
        {/* Portée des informations délivrées sur le Site */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content6.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content6.description1")} </div>
          </div>
        </div>
        {/* Responsabilité */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content8.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content8.description1")} </div>
          </div>
        </div>
        <img src={astornaute} alt="astornaute" className="astornaute"></img>
        <div className="groupe"></div>
      </section>
      {/* Section 4 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Données personnelles content */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content7.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices.notices-content7.description1")} </div>
            <div className="line-text my-3"> {t("notices.notices-content7.description2")} </div>
            <div className="line-text my-3"> {t("notices.notices-content7.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description5")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description6")} </div>
            <div className="line-text my-3"> {t("notices.notices-content7.description7")} </div>
            <div className="line-text my-3"> {t("notices.notices-content7.description8")} </div>
            <div className="line-text my-3"> {t("notices.notices-content7.description9")} </div>
            <div className="line-text my-3"> {t("notices.notices-content7.description10")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description11")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description12")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description13")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description14")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description15")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description16")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description17")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description18")} </div>
            <div className="line-text my-2 mx-5"> {t("notices.notices-content7.description19")} </div>
            <div className="line-text my-3"> {t("notices.notices-content7.description20")} </div>
          </div>
        </div>
        {/* Résolution des litiges */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices.notices-content9.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text mt-2"> {t("notices.notices-content9.description1")} </div>
            <div className="line-text mt-2"> {t("notices.notices-content9.description2")} </div>
          </div>
        </div>
      </section>
      {/* Conditions générales de service - Client */}
      {/* Section 1 */}
      <section className="conditions-page3 conditions-auto-page">
        <div className="text-center fontSize-90 text-transparent text-uppercase roboto-black padding-titre text-spacing-1 pt-5">
          {t("notices-client.notices-client-title")}
        </div>
        {/* Definition */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-40">
          {t("notices-client.notices-client-content1.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description3")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description4")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description5")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description6")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description7")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description8")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description9")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description10")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description11")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description12")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description13")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description14")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content1.description15")} </div>
          </div>
        </div>
      </section>
      {/* Section 2 */}
      <section className="conditions-page4 conditions-auto-page">
        {/* Objet */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-5">
          {t("notices-client.notices-client-content2.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content2.description1")} </div>
          </div>
        </div>
      </section>
      {/* Section 3 */}
      <section className="conditions conditions-auto-page">
        {/* Acceptation des Conditions générales de Service */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content3.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content3.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content3.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content3.description3")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content3.description4")} </div>
          </div>
        </div>
      </section>
      {/* Section 4 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Service */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content4.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content4.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content4.description2")} </div>
          </div>
        </div>
      </section>
      {/* Section 5 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Création du Compte */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content5.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            {/* Modalités */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content5.subTitle1.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content5.subTitle1.description1")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle1.description2")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle1.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle1.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle1.description5")} </div>
            {/* Identifiant et mot de passe */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content5.subTitle2.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content5.subTitle2.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content5.subTitle2.description2")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle2.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle2.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle2.description5")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content5.subTitle2.description6")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content5.subTitle2.description7")} </div>
          </div>
        </div>
      </section>
      {/* Section 6 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Demande du Client */}
        <div className="margin-section green-color fontSize-50 roboto-condensed pt-5">
          {t("notices-client.notices-client-content6.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content6.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content6.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content6.description3")} </div>
          </div>
        </div>
      </section>
      {/* Section 7 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Mission */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content7.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            {/* Contenu de la Mission */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content7.subTitle1.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content7.subTitle1.description1")} </div>
            {/* Propositions de Mission */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content7.subTitle2.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content7.subTitle2.description1")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content7.subTitle2.description2")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content7.subTitle2.description3")} </div>
            {/* Commande de la Mission */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content7.subTitle3.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content7.subTitle3.description1")} </div>
          </div>
        </div>
      </section>
      {/* Section 8 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Paiement du Prix de la Mission */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content8.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            {/* Moment du paiement */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content8.subTitle1.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content8.subTitle1.description1")} </div>
            {/* Modalités du paiement */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content8.subTitle2.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content8.subTitle2.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content8.subTitle2.description2")} </div>
            {/* Incident de paiement */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content8.subTitle3.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content8.subTitle2.description1")} </div>
          </div>
        </div>
      </section>
      {/* Section 9 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Déroulement de la Mission */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content9.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            {/* Délai d’exécution de la Mission */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle1.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle1.description1")} </div>
            {/* Prorogation de la Mission */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle2.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle2.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle2.description2")} </div>
            {/* Annulation de la Mission */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle3.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle3.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle3.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content9.subTitle3.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content9.subTitle3.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content9.subTitle3.description5")} </div>
          </div>
        </div>
      </section>
      {/* Section 10 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Promotion */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content10.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content10.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content10.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content10.description2")} </div>
          </div>
        </div>
      </section>
      {/* Section 11 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Obligations diverses du Client */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content11.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content11.title")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description1")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description2")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description5")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description6")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description7")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description8")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description9")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description10")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description11")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content11.description12")} </div>
          </div>
        </div>
      </section>
      {/* Section 12 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Réclamations du Client */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content12.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content12.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content12.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content12.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content12.description3")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content12.description4")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content12.description5")} </div>
          </div>
        </div>
      </section>
      {/* Section 13 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Accès au Site */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content13.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.description3")} </div>
            {/* Protection des équipements */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle1.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle1.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle1.description2")} </div>
            {/* Suspension du Site */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle2.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle2.description1")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description2")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description5")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description6")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description7")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description8")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description9")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content13.subTitle2.description10")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle2.description11")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle2.description12")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle2.description13")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content13.subTitle2.description14")} </div>
          </div>
        </div>
      </section>
      {/* Section 14 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Propriété intellectuelle */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content14.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            {/* INSPIRE Talents */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content14.subTitle1.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content14.subTitle1.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content14.subTitle1.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content14.subTitle1.description3")} </div>
            {/* Le Client et l’Inspirnaute */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content14.subTitle2.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content14.subTitle2.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content14.subTitle2.description2")} </div>
          </div>
        </div>
      </section>
      {/* Section 15 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Confidentialité */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content15.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content15.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content15.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content15.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content15.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content15.description5")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content15.description6")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content15.description7")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content15.description8")} </div>
          </div>
        </div>
      </section>
      {/* Section 16 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Données personnelles */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content16.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description3")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description4")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description5")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description6")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description7")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description8")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description9")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description10")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description11")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description12")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description13")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description14")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description15")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description16")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description17")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description18")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description19")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description20")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content16.description21")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description22")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content16.description23")} </div>
          </div>
        </div>
      </section>
      {/* Section 17 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Responsabilité d’INSPIRE Talents */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content17.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            {/* Le Service */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content17.subTitle1.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content17.subTitle1.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content17.subTitle1.description2")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content17.subTitle1.description3")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content17.subTitle1.description4")} </div>
            {/* En tout état de cause */}
            <div className="line-text my-3"> {t("notices-client.notices-client-content17.subTitle2.title")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content17.subTitle2.description1")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content17.subTitle2.description2")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content17.subTitle2.description3")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content17.subTitle2.description4")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content17.subTitle2.description5")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content17.subTitle2.description6")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content17.subTitle2.description7")} </div>
            <div className="line-text my-2 mx-5"> {t("notices-client.notices-client-content17.subTitle2.description8")} </div>
          </div>
        </div>
      </section>
      {/* Section 18 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Résiliation */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content18.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content18.description1")} </div>
            <div className="line-text my-3"> {t("notices-client.notices-client-content18.description2")} </div>
          </div>
        </div>
      </section>
      {/* Section 19 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Nullité d’une clause */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content19.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content19.description1")} </div>
          </div>
        </div>
      </section>
      {/* Section 20 */}
      <section className="conditions-page2 conditions-auto-page">
        {/* Résolution des litiges */}
        <div className="margin-section green-color fontSize-50 roboto-condensed">
          {t("notices-client.notices-client-content20.title")}
        </div>
        <div className="white-color fontSize-25 roboto-regular">
          <div className="margin-section">
            <div className="line-text my-3"> {t("notices-client.notices-client-content20.description1")} </div>
          </div>
        </div>
        {/* Final ing */}
        <div className="groupe-legal pg-termlegale">
        </div>
      </section>
      {/* Section 21 */}
      <section className="pg-terms pg-terms-legale">
        <div className="etoile-propos"></div>
        <div className="overlay"></div>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
              <p className="roboto-condensed-bold text-uppercase fontSize-178 yellow-color float-left text-spacing-7">
                {t("home.univers")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
              <p className="roboto-condensed-bold ml-big text-uppercase fontSize-178 yellow-color text-spacing-3 infini">
                {t("home.infini")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
              <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                {t("home.contact")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft" >
              <p className="roboto-condensed-bold text-center fontSize-40 mb-lg-5 mb-sm-3">
                {t("home.adress")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 mb-lg-5 mb-sm-3">
                {t("home.phone")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 mb-lg-5 mb-sm-3">
                {t("home.mail")}
              </p>
              <p className="roboto-condensed text-center fontSize-25 notice text-underline">
                <Link to="/notice">{t("home.mention")}</Link>
              </p>
            </ScrollAnimation>
          </Col>
        </Row>
      </section>
    </div>
  );
}
