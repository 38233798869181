import React from "react";
import { Route } from "react-router-dom";
import ProfilRecruiter from "../Recruiter/Profil/ProfilRecruiter";
import MissionRecruiter from "../Recruiter/Mission/MissionRecruiter";
import DetailMissionRecruiter from "../Recruiter/DetailMission/DetailMissionRecruiter";
import InterviewRecruiter from "../Recruiter/Interview/InterviewRecruiter";
import DashboardRecruiter from "../Recruiter/Dashboard/DashboardRecruiter";
import DashboardCosmomaute from "../Cosmonaute/Dashbord/DashboardCosmomaute";
import ProfilCosmomaute from "../Cosmonaute/Profil/ProfilCosmomaute";
import MissionCosmomaute from "../Cosmonaute/Mission/MissionCosmomaute";
import InerviewsCosmonaute from "../Cosmonaute/interviews/InerviewsCosmonaute";
import Resume from "../Cosmonaute/Profil/Resume/Resume";
import EditResume from "../Cosmonaute/Profil/Edit/EditResume";
import DashboardAdmin from "../Administrator/Dashboard/DashboardAdmin";
import InspirnautesAdmin from "../Administrator/Inspirnautes/InspirnautesAdmin";
import MissionsAdmin from "../Administrator/Missions/MissionsAdmin";
import DetailMission from "../Administrator/DetailMission/DetailMissionAdmin";
import RecruiterAdmin from "../Administrator/Recruiters/RecruiterAdmin";
import CandidaturesAdmin from "../Administrator/Candidatures/CandidaturesAdmin";
import AnnexeAdmin from "../Administrator/Annexe/AnnexeAdmin";
import FollowUpAdmin from "../Administrator/FollowUp/FollowUpAdmin";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "../../components/SideBar/SideBar";
import DevenirInpirnaute from "../DevenirInspirnaute/DevenirInspirnaute";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Apropos from "../Apropos/Apropos";
import Contact from "../Contact/Contact";
import Recrute from "../CommentRecrute/Recrute";
import RecruterInscription from "../RecruterInscritption/RecruterInscription";
import ResetPassword from "../ResetPassword/ResetPassword";
import ForgetPassword from "../ForgetPassword/ForgetPassword";
import TermsConditions from "../TermsConditions/TemrsConditions";
import DetailMissionUser from "../Cosmonaute/DetailMission/DetailMissionUser";
import DocumentUser from "../Cosmonaute/Document/DocumentUser";
import DetailsContart from "../Cosmonaute/DetailDocument/DetailsContrat";
import DocumentRecruiter from "../Recruiter/DocumentRecruiter/DocumentRecruiter";
import DocumentAdmin from "../Administrator/Documents/DocumentAdmin";
import LegalNotice from "../LegalNotice/LegalNotice";
import { RouteView } from "../../services/Controller/AuthGuard";
import CookiesPage from "../../components/cookies/cookies";
import "./AllRoutes.scss";

function AllRoutes() {
  const routeGuard = RouteView();
  /* Main Function */
  return (
    <>
      <div className="App">
        {routeGuard === "homePage" ? (
          <>
            <div>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/forgetpassword">
                <ForgetPassword />
              </Route>
              <Route exact path="/resetpassword/:token">
                <ResetPassword />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/inscription">
                <DevenirInpirnaute />
              </Route>
              <Route exact path="/propos">
                <Apropos />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/recrute">
                <Recrute />
              </Route>
              <Route exact path="/recruter">
                <RecruterInscription />
              </Route>
              <Route exact path="/terms">
                <TermsConditions />
              </Route>
              <Route exact path="/notice">
                <LegalNotice />
              </Route>
            </div>
            {/* Cookies */}
            <CookiesPage />
          </>
        ) : (
          <div className="d-flex">
            <SideBar />
            <div id="content-Layout" className="content-Layout">
              <NavBar headerClass={routeGuard} />
              {/* RECRUITER */}
              <Route path="/recruiter/dashboard">
                <DashboardRecruiter />
              </Route>
              <Route exact path="/recruiter/profil">
                <ProfilRecruiter />
              </Route>
              <Route exact path="/recruiter/missions">
                <MissionRecruiter />
              </Route>
              <Route exact path="/recruiter/missions/:id">
                <DetailMissionRecruiter />
              </Route>
              <Route exact path="/recruiter/inspirnautes/:id">
                <Resume />
              </Route>
              <Route path="/recruiter/interviews">
                <InterviewRecruiter />
              </Route>
              <Route path="/recruiter/documents">
                <DocumentRecruiter />
              </Route>
              <Route path="/recruiter/mission/:id">
                <DetailsContart />
              </Route>

              {/* CANDIDAT */}
              <Route path="/user/dashboard">
                <DashboardCosmomaute />
              </Route>
              <Route path="/user/profil">
                <ProfilCosmomaute />
              </Route>
              <Route exact path="/user/missions">
                <MissionCosmomaute />
              </Route>
              <Route exact path="/user/missions/:id">
                <DetailMissionUser />
              </Route>
              <Route path="/user/interviews">
                <InerviewsCosmonaute />
              </Route>
              <Route exact path="/user/resume/:id">
                <Resume />
              </Route>
              <Route path="/user/:id/edit/resume">
                <EditResume />
              </Route>
              <Route path="/user/documents">
                <DocumentUser />
              </Route>
              <Route path="/user/mission/:id">
                <DetailsContart />
              </Route>

              {/* ADMINISTRATOR */}
              <Route path="/admin/dashboard">
                <DashboardAdmin />
              </Route>
              <Route exact path="/admin/recruiters">
                <RecruiterAdmin />
              </Route>
              <Route exact path="/admin/recruiters/:id">
                <ProfilRecruiter />
              </Route>
              <Route exact path="/admin/inspirnautes">
                <InspirnautesAdmin />
              </Route>
              <Route exact path="/admin/inspirnautes/:id">
                <ProfilCosmomaute />
              </Route>
              <Route exact path="/admin/inspirnautes/:id/resume">
                <Resume />
              </Route>
              <Route exact path="/admin/inspirnautes/:id/edit/resume">
                <EditResume />
              </Route>
              <Route exact path="/admin/missions">
                <MissionsAdmin />
              </Route>
              <Route exact path="/admin/missions/:id">
                <DetailMission />
              </Route>
              <Route path="/admin/candidatures">
                <CandidaturesAdmin />
              </Route>
              <Route path="/admin/annexe">
                <AnnexeAdmin />
              </Route>
              <Route path="/admin/follow-candidats">
                <FollowUpAdmin />
              </Route>
              <Route path="/admin/documents">
                <DocumentAdmin />
              </Route>
              <Route path="/admin/candidat/:id">
                <DocumentUser />
              </Route>
              <Route path="/admin/recruter/:id">
                <DocumentRecruiter />
              </Route>
              <Route path="/admin/user/:id/mission/:id">
                <DetailsContart />
              </Route>
              <Route path="/admin/recruted/:id/mission/:id">
                <DetailsContart />
              </Route>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

/* Export AllRoutes component */
export default AllRoutes;
