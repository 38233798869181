import { SHOW_TOAST, HIDE_TOAST } from '../constants/Toast';

/* Show Toast Action */
const showToast = (dispatch) => () => {
        dispatch({
            type: SHOW_TOAST,
            toastContent: { status: 'error', body: "Echec" }
        });
}


/* Hide Toast Action */
const hideToast = (dispatch) => () => {
    dispatch({
        type: HIDE_TOAST,
        toastContent: null
    });
}

/* Export Action Toast */
export { showToast, hideToast};