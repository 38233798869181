import axios from 'axios';
import { API_HOST, API_REFRESH_TOKEN } from "../../Api";
import { getToken, getRefreshToken, setToken, setRefreshToken } from "../Controller/AuthService";

/* Add a request interceptor */
axios.interceptors.request.use(
    function (config) {
        //get token
        const token = getToken();
        // Do something before request is sent
        if (token && config.url.indexOf("printCandidateResume") === -1) {
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        }
        else return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

/* Add a response interceptor */
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalConfig = error.config;
        originalConfig._retry = false;
        if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                let refreshToken = getRefreshToken();
                if (refreshToken) {
                    const url = API_HOST + API_REFRESH_TOKEN;
                    const data = { refresh_token: refreshToken };
                    axios.create().post(url, data).then(
                        (res) => {
                            const token = res.data.token;
                            setToken(token);
                            setRefreshToken(res.data.refresh_token);
                            originalConfig.headers.Authorization = "Bearer " + token;
                            axios(originalConfig);
                            window.location.reload();
                        },
                        (err) => {
                            localStorage.clear();
                            window.location = "/login";
                            return Promise.reject(error);
                        }
                    )
                }
            }
        }
        return Promise.reject(error);
    }
)

/* export axios requests */
const axiosService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch
};
export default axiosService;