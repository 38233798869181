import React, { useContext, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { FaPen, FaUserAstronaut } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, ProgressBar, Image, Form, Card, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Context as CosmonauteContext } from "../../../context/reducers/CosmonauteReducer";
import ModalUploadLogo from "../../../components/ModalUploadLogo/ModalUploadLogo";
import AlertModal from "../../../components/alert/alert.js";
import { ErrorFeild, ErrorClass } from "../../../services/Controller/ValidationForm";
import { getUserId, isAdmin } from "../../../services/Controller/CurrentUserServices";
import astro from "../../../assets/img/candidat.png";
import { phonePattern, numberPattern } from "../../../services/Controller/PattenService";
import { onFocusPhoneNumber, onBlurPhoneNumber } from "../../../services/Controller/PhoneService";
import "react-vertical-timeline-component/style.min.css";
import "./ProfilCosmomaute.scss";

/* Validation Form Group Detail Profile */
const validationCosmonauteProfilDetail = Yup.object({
  dayrate: Yup.string().matches(numberPattern, "Évaluation invalide"),
  phonenumber: Yup.string()
    .matches(phonePattern, "Numéro de téléphone doit commencer par +33 et ne doit contenir que des chiffres")
    .min(11, "Numéro de téléphone doit contenir au moins 11 caractères")
    .max(15, "Numéro de téléphone doit contenir au plus 15 chiffres")
});

/* Form Group Detail Profile Creation */
export default function ProfilCosmomaute() {
  /* use Translation */
  const { t } = useTranslation();

  /* use History */
  const history = useHistory();
  const mission = localStorage.getItem("m");
  const missionId = localStorage.getItem("id");

  /* Context API */
  const {
    ProfilCosmonaute,
    UpdateCosmonauteDecription,
    logoUploadCandidate,
    getLogoCandidate,
    dumpLogoCandidate,
    hideToast,
    pourcentageCondidat,
    state,
  } = useContext(CosmonauteContext);

  /* States */
  const [CosmonauteProfil, setCosmonauteProfil] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [idProfil, setIdProfile] = useState(null);
  const [candidateLogo, setCandidateLogo] = useState(null);
  /* show alert */
  const [show, setShow] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    if (history.location.state === "completeProfile") setShow(true);
    const id = location.pathname.split("/")[3];
    if (id) {
      setIdProfile(id);
      ProfilCosmonaute(id);
      getLogoCandidate(id);
      pourcentageCondidat(id);
    }
    else {
      setIdProfile(null);
      ProfilCosmonaute();
      getLogoCandidate();
      pourcentageCondidat();
    }
  }, []);
  /* eslint-enable */

  /*use effect change State */
  useEffect(() => {
    if (state.CosmonauteProfil) setCosmonauteProfil(state.CosmonauteProfil);
    if (state.logoCandidate) setCandidateLogo(state.logoCandidate);
  }, [state]);

  /* refresh Logo Candidate */
  useEffect(() => {
    if (state.logo) {
      getLogoCandidate();
      dumpLogoCandidate();
    }
  }, [dumpLogoCandidate, getLogoCandidate, state.logo]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);

  /* profil main*/
  const profilDetailForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      aboutcosmounote: CosmonauteProfil && CosmonauteProfil.description? CosmonauteProfil.description : "",
      phonenumber: CosmonauteProfil && CosmonauteProfil.phone? CosmonauteProfil.phone : "",
      dayrate: CosmonauteProfil && CosmonauteProfil.tarif ? CosmonauteProfil.tarif : "",
      startAtCampany: CosmonauteProfil && CosmonauteProfil.dispoStart ? CosmonauteProfil.dispoStart : "",
      finishAtCampany: CosmonauteProfil && CosmonauteProfil.dispoEnd ? CosmonauteProfil.dispoEnd : "",
      videoCosmonaute: CosmonauteProfil && CosmonauteProfil.urlVideo ? CosmonauteProfil.urlVideo : "",
    },
    validationSchema: validationCosmonauteProfilDetail,
    onSubmit: (values) => {
      let dataToSend = {
        description: values.aboutcosmounote,
        phonenumber: values.phonenumber,
        dayrate: values.dayrate,
        url: values.videoCosmonaute,
      };
      UpdateCosmonauteDecription(dataToSend, idProfil ? idProfil : null);
    },
  });
  /* Go To Resume */
  const routeChange = () => {
    if (isAdmin()) {
      history.push(t("role.administrator") + t("sidebar.inspirnautes.path") + "/" + idProfil + "/resume");
    }
    else history.push(t("role.user") + t("sidebar.resume.path") + "/" + getUserId());
  };

  /* Edit Resume */
  const routeEdit = () => {
    if (isAdmin()) {
      history.push(t("role.administrator") + t("sidebar.inspirnautes.path") + "/" + idProfil + "/edit/resume");
    }
    else history.push(t("role.user") + "/" + getUserId() + "/edit/resume");
  };

  /* Return back to Admin inspirnautes list */
  function returnBackAdmin() {
    if (mission === "k") {
      history.push(t("role.administrator") + t("sidebar.inspirnautes.path"));
    } else
      history.push(t("role.administrator") + t("sidebar.missions.path") + "/" + missionId);
  }

  /* Header profile */
  function ProfileHeader() {
    return (
      <>
        {isAdmin() ? (
          <div className="arrow-back-mission pointer mb-3" onClick={returnBackAdmin} >
            <i className="icon-back-arrow"></i>
          </div>
        ) : null}
        <Row className="mt-4">
          <Col className="pr-form mb-sm-3" lg={6} md={12} xs={12}>
            <Card className="card-cosmonaute">
              <Card.Body>
                <Row className="align-items-center justify-content-center">
                  <Col id="profilePic" lg={5} md={6} xs={12}>
                    <Image className="p-18 w-height-image"
                      src={candidateLogo ? "http://" + candidateLogo.path : astro} roundedCircle 
                    />
                  </Col>
                  <Col lg={7} md={7} xs={12}>
                    <label className="name">
                      {CosmonauteProfil && CosmonauteProfil.name && CosmonauteProfil.lastname
                        ? CosmonauteProfil.name + " " + CosmonauteProfil.lastname
                        : "- - -"}
                    </label>
                    <label className="position-cosmonaute ml-5">
                      {CosmonauteProfil && CosmonauteProfil.poste ? CosmonauteProfil.poste : "- - -"}
                      <span className="position-cosmonaute Roboto-medium">
                        ( {CosmonauteProfil && CosmonauteProfil.nbrAnneeExp ? CosmonauteProfil.nbrAnneeExp : "- - -"} ans d'expérience)
                      </span>
                    </label>
                    {CosmonauteProfil && CosmonauteProfil.place ?
                    <p className="landing-place">
                      Région : {CosmonauteProfil && CosmonauteProfil.place ? CosmonauteProfil.place : "- - -"}
                    </p>: null}
                    <div className="text-camera">
                      <i className="icon-camera pointer mr-10" onClick={() => { setModalShow(true); }} ></i>
                      Changer votre photo de profil
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="pr-form mb-sm-3 margin-top-block" lg={3} md={12} xs={12} >
            <Card onClick={() => { routeChange(); }} className="p-3 card-dashboard mb-3">
              <Card.Body className="d-flex">
                <div className="text-resume">
                  <FaUserAstronaut className="mr-10 mt-1" />
                  <span className="mt-2">Voir le CV</span>
                </div>
              </Card.Body>
            </Card>

            <Card onClick={() => { routeEdit(); }} className="card-dashboard p-3 mt-3">
              <Card.Body className="d-flex">
                <div className="text-resume">
                  <FaPen className="mr-10 mt-1" />
                  <span className="mt-2">Modifier le CV</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-sm-3 margin-top-block" lg={3} md={12} xs={12}>
            <Card className="card-cosmonaute">
              <Card.Body>
                <div className="text-pourcentage">
                  Compléter le profil et le <div> mettre à jour</div>
                </div>
                <p className="pourcentage">
                  {state?.pourcentage ? state?.pourcentage.percentage : 0}%
                </p>
                <ProgressBar now={state.pourcentage ? state.pourcentage.percentage : 0} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  //function ProfileMain
  function ProfileMain() {
    return (
      <Form onSubmit={profilDetailForm.handleSubmit}>
        <Row>
          <Col className="pr-form" lg={6} md={12} xs={12}>
            {/* About the candidat */}
            <label className="title-profile">Qui suis-je ?</label>
            <Form.Group>
              <Form.Control
                className={ErrorClass(profilDetailForm, "aboutcosmounote")}
                as="textarea"
                rows={6}
                name="aboutcosmounote"
                id="aboutcosmounote"
                placeholder="Mentionnez ici votre expérience, vos compétences, vos aspirations ainsi que vos passions pour but d’aider vos employeurs à avoir une idée sur votre carrière, vos valeurs, etc."
                onChange={profilDetailForm.handleChange}
                onBlur={profilDetailForm.handleBlur}
                value={profilDetailForm.values.aboutcosmounote}
              />
              {ErrorFeild(profilDetailForm, "aboutcosmounote")}
              <div className="IT_info">
                <p className="info-campany">
                  Informations uniquement visibles pour vous et l'équipe Inspire.
                </p>
              </div>
            </Form.Group>
            {/* phone of the candidat */}
            <label className="title-profile">Numéro de téléphone :</label>
            <Form.Group>
              <Form.Control
                className={ErrorClass(profilDetailForm, "phonenumber")}
                type="text" name="phonenumber" id="phonenumber"
                onChange={profilDetailForm.handleChange}
                value={profilDetailForm.values.phonenumber}
                onBlur={() => { onBlurPhoneNumber(profilDetailForm, "phonenumber") }}
                onFocus={() => { onFocusPhoneNumber(profilDetailForm, "phonenumber") }}
              />
              {ErrorFeild(profilDetailForm, "phonenumber")}
            </Form.Group>
            {/* day rate */}
            <label className="title-profile">Taux journalier :</label>
            <Form.Group>
              <Form.Control
                className={ErrorClass(profilDetailForm, "dayrate")}
                type="text" name="dayrate" id="dayrate"
                onChange={profilDetailForm.handleChange}
                value={profilDetailForm.values.dayrate}
                onBlur={profilDetailForm.handleBlur}
              />
              {ErrorFeild(profilDetailForm, "dayrate")}
            </Form.Group>
            <div className="mt-5">
              <Button className="save-button blue2" type="submit">
                Sauvegarder
              </Button>
            </div>
          </Col>
          <Col className="pl-form" lg={6} md={12} xs={12}>
            <label className="title-video">Vidéo à propos du Cosmonaute</label>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Text>URL</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="videoCosmonaute"
                  id="videoCosmonaute"
                  onChange={profilDetailForm.handleChange}
                  className={ErrorClass(profilDetailForm, "videoCosmonaute")}
                  value={profilDetailForm.values.videoCosmonaute}
                  onBlur={profilDetailForm.handleBlur}
                  placeholder="Saisir votre lien Youtube"
                />
              </InputGroup>
              {ErrorFeild(profilDetailForm, "videoCosmonaute")}
            </Form.Group>
            <div className="IT_info mt-2">
              <div className="video-campany">
                <span className="text-inspirnaute">
                  Cher Inspirnaute, pour vous démarquer, vous pouvez, si vous le
                  souhaitez, enregistrer une vidéo
                </span>
                youtube
                <span className="text-inspirnaute">
                  pour expliquer en 30 secondes vos antécédents, qui vous êtes,
                  ce que vous aimez
                </span>
                et ce que vous attendez de votre prochaine mission.
                <span className="text-inspirnaute">
                  En général, les vidéos génèrent l'enthousiasme de nos clients
                  et augmentent votre visibilité. Donc préparez votre plus beau
                  sourire, un bon éclairage et c'est à vous de jouer ! (Ne vous
                  inquiétez pas, vous pouvez répéter la vidéo autant que vous
                  voulez).
                </span>
              </div>
            </div>
            {profilDetailForm.values.videoCosmonaute &&
              profilDetailForm.values.videoCosmonaute !== ""
              ? <ReactPlayer className="w-100 C_video" url={profilDetailForm.values.videoCosmonaute} />
              : <div className="nothingToShow">
                <span> Pas de résultats à afficher </span>
              </div>
            }
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <>
      {/* Modal Upload Cosmonaute */}
      <ModalUploadLogo
        modalShow={modalShow}
        setModalShow={setModalShow}
        logoUpload={logoUploadCandidate}
      />
      <div id="header-Content" className="header-Content">
        {ProfileHeader()}
      </div>
      <div id="main" className="main">
        {ProfileMain()}
      </div>
      {/* show alert */}
      <AlertModal
        show={show}
        setShow={setShow}
        content="Bienvenue dans l’espace candidat d’Inspire Talents ! Veuillez compléter ton profil pour qu’on puisse te contacter dans les plus brefs délais !"
      />
    </>
  );
}
