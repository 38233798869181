import {
    API_HOST,
    API_MISSION_STATS,
    API_CANDIDATE_BY_DAY_STATS,
    API_MISSION_BY_DAY_STATS,
    API_USERS_STATS,
    API_TOP_RECRUITERS

} from "../../Api";
import {
    MISSION_BY_DAY_STATS,
    MISSION_STATS,
    CANDIDATE_BY_DAY_STATS,
    USERS_STATS,
    TOP_RECRUITERS
} from "../constants/Administrator";
import axiosServices from "../../services/Axios/axiosServices";
import {SHOW_TOAST} from "../constants/Toast";


/* Get Users Stats  */
export const getUsersStats = (dispatch) => () => {
    // URL get list Candidatures recruiters for Admin
    const URL = API_HOST + API_USERS_STATS;
    // Call API list Candidatures recruiters for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({
                type: USERS_STATS,
                payload: res.data ,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des données" }
            });
        }
    );
}

/* Get Users Stats  */
export const getMissionStats = (dispatch) => () => {
    // URL get list Candidatures recruiters for Admin
    const URL = API_HOST + API_MISSION_STATS;
    // Call API list Candidatures recruiters for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({
                type: MISSION_STATS,
                payload: res.data ,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des données" }
            });
        }
    );
}

/* Get Users Stats  */
export const getMissionByDay = (dispatch) => () => {
    // URL get list Candidatures recruiters for Admin
    const URL = API_HOST + API_MISSION_BY_DAY_STATS;
    // Call API list Candidatures recruiters for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({
                type: MISSION_BY_DAY_STATS,
                payload: res.data ,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des données" }
            });
        }
    );
}

/* Get Users Stats  */
export const getCandidateByDay = (dispatch) => () => {
    // URL get list Candidatures recruiters for Admin
    const URL = API_HOST + API_CANDIDATE_BY_DAY_STATS;
    // Call API list Candidatures recruiters for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({
                type: CANDIDATE_BY_DAY_STATS,
                payload: res.data ,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des données" }
            });
        }
    );
}

/* Get Top Recruiter  */
export const getTopRecruiter = (dispatch) => () => {
    // URL get list Candidatures recruiters for Admin
    const URL = API_HOST + API_TOP_RECRUITERS;
    // Call API list Candidatures recruiters for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({
                type: TOP_RECRUITERS,
                payload: res.data ,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des données" }
            });
        }
    );
}
